import { Component, OnInit, ElementRef, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress' ;
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../assets/js/common';
declare var $: any;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { FrontDesk_Helper } from '../FrontDesk_Helper';

@Component({
  selector: 'app-advance-create',
  templateUrl: './advance-create.component.html',
  styleUrls: ['./advance-create.component.scss']
})
export class AdvanceCreateComponent implements OnInit {
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('focusMRNo') focusMRNo: ElementRef;
  @ViewChild('focusMobileNo') focusMobileNo: ElementRef;
  @ViewChild('focusFName') focusFName: ElementRef;
  public ClientFilterList = [];
  public clntFirstName: string;
  public clntMiddleName: string;
  public clntLastName: string;
  public getClientRegId: string;
  public clntContNumber: string;
  public clntGender: string;
  public clntAge: string;
  public clntAddress1: string;
  public clntAddress2: string;
  public clntLocation: string;
  public clntCity: string;
  public clntState: string;
  public clntCountry: string;
  public clntZipcode: string;
  public advance:string="";
  public hospitalId;
  public advanceFlag = false;
  public paytypelist = [];
  public payType: string;
  public payDesc: string;
  public transactionId;
  public cardNumber;
  public cardHolderName;
  public bankName;
  public hospitalLogo;
  public hospitalName;
  public hospitalLocation;
  public hospitalCity;
  public hospitalState;
  public hospitalCountry;
  public hospitalPhNo;
  public hospitalAddr;
  public printTemplate;
  public patientId;
  public transactionFlag: boolean = true;
  public cardFlag: boolean = true;
  public chequeFlag: boolean = true;
  public cardreqflag:boolean=false;
  public transreqflag:boolean=false;
  public chequereqflag:boolean=false;
  public insreqflag:boolean=false;
  public insurFlag: boolean;
  public appointmentListArray: any = [];
  public clientRegID;
  public appID;
  public clientName;
  public insurerId;
  public advanceArray = [];
  public insurerList = [];
  public totalAdvance:any = 0;
  public printlogostyle;
  public bill_head: any;
  public salutation_desc: any;
  public createpage_flag: boolean = false;
  public filter: string;
  public firstNameFlag: boolean;
  public clntGenShort: any;
  public currentDate: any;
  public currentTime: any;
  public timevalue: any;
  public numToWords: string;
  public mobile_no: any;
  public docName: any;
  public admitted_date: any;
  public admitted_time: any;
  public profile_image: string;
  public em_contact: any;
  public print_size: any;
  public fromFlag: boolean;
  public toFlag: boolean;
  public uhidFlag: boolean;
  public mobileFlag: boolean;
  public mr_no_flag: boolean;
  public date;
  public firstName: string;
  public mobileNo:string;
  public lastName: string;
  public editbutton: boolean;
  public newbutton: boolean;
  public deletebutton: boolean;
  public printbutton: boolean;
  public viewbutton: boolean;
  public barcode_flag: boolean = false;
  public barcode_url;
  public logUserName;
  public banner_style: any;
  public billId;
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff'; 

  constructor(public gservice: CommonDataService, public http: HttpClient, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public messageservice: MenuViewService, public dialog: MatDialog, public dialogRef: MatDialogRef<AdvanceCreateComponent>,public cdr: ChangeDetectorRef) {
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = false;
    this.mobileFlag = true;
    this.mr_no_flag = false;
  }

  ngOnInit(): void {
    this.getPaymentType();
    this.profile_image = "../../assets/img/default.jpg";
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.hospitalName = Helper_Class.getInfo().hospitals[0].hptl_name;
    this.hospitalLocation = Helper_Class.getInfo().hospitals[0].location_desc;
    this.hospitalCity = Helper_Class.getInfo().hospitals[0].city_desc;
    this.hospitalState = Helper_Class.getInfo().hospitals[0].state_desc;
    this.hospitalCountry = Helper_Class.getInfo().hospitals[0].country_desc;
    this.hospitalPhNo = Helper_Class.getInfo().hospitals[0].telephone;
    this.hospitalAddr = Helper_Class.getInfo().hospitals[0].address2 != undefined ? Helper_Class.getInfo().hospitals[0].address1 + "," + Helper_Class.getInfo().hospitals[0].address2 : Helper_Class.getInfo().hospitals[0].address1;
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle=Helper_Class.getInfo().bill_print_logo_style;
    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.printTemplate =  Helper_Class.getInfo().bill_print_template;
    this.print_size = Helper_Class.getInfo().bill_print_paper;
    this.hospitalId = Helper_Class.getInfo().hptl_clinic_id;
    this.getCurrentDate();
    if (FrontDesk_Helper.getmodules() != null && FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "19") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1" ) {
            this.editbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1"  ) {
            this.newbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbutton = true; 
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1" ) {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (Helper_Class.getInfo().hptl_name == 'SGH') {
      this.banner_style = "width: 100%; height: 210px !important;";
    } else {
      this.banner_style = "width: 100%; height: 195px !important;";
    }

    if (Helper_Class.getInfo().first_name != undefined) {
      this.logUserName = Helper_Class.getInfo().first_name;
      if (Helper_Class.getInfo().last_name != undefined) {
        this.logUserName = Helper_Class.getInfo().first_name+" "+Helper_Class.getInfo().last_name;
      }
    }

    this.filter = "Patient_ID";
    this.cdr.detectChanges();
    this.focusMRNoInput();
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      {
        country: ipaddress.country_code.toString()
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.date = obj.current_date;
            this.currentTime = obj.current_time;            
            this.timevalue = Time_Formate(this.currentTime);          
          }
        },error => {});
  }

  getPaymentType() { //Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paytypelist = obj.payments;
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.paytypelist[i].pay_desc == "Insurance")
              this.paytypelist.splice(i, 1);
          }
          this.payType = this.paytypelist[0].pay_id;
          this.payDesc = this.paytypelist[0].pay_desc;
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changePayType() { 
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if(this.payType == "2"){
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag=true;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if (this.payType == "3"){
      this.cardreqflag=false;
      this.insurFlag = false;
      this.cardreqflag=false;
      this.transreqflag= false;
      this.chequereqflag = false;
      this.insreqflag = true;

    } else if(this.payType == "4"){
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "5") {
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "6"){
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "7") {
      this.chequeFlag = false;
      this.cardreqflag=false;
      this.transreqflag= false;
      this.chequereqflag = true;
      this.insreqflag = false;
    } 
    var res = this.paytypelist.filter(o => o.pay_id == this.payType);
    this.payDesc = res[0].pay_desc;
  }

  clearData() {
    this.createpage_flag = false;
    this.profile_image = "";   
    this.patientId = "";
    this.firstName = "";
    this.lastName = "";
    this.mobileNo = "";
    this.salutation_desc = "";
    this.clntMiddleName = "";
    this.clntLastName = "";
    this.clntAge = "";
    this.clntGenShort = "";
    this.clntAddress1 = "";
    this.clntLocation = "";
    this.clntCity = "";
    this.clntState = "";
    this.clntCountry = "";
    this.clntZipcode = "";
    this.mobile_no = "";
    this.docName = "";
    this.admitted_date = ""; 
    this.admitted_time = "";
    this.em_contact = "";
    this.appointmentListArray = [];
    this.clientName = "";
  }

  focusMRNoInput() {
    this.patientId = "";
    document.getElementById("focusMRNo").focus();
    this.focusMRNo.nativeElement.focus();
  }

  focusMobileNoInput() {
    this.mobileNo = "";
    document.getElementById("focusMobileNo").focus();
    this.focusMobileNo.nativeElement.focus();
  }

  focusFirst_nameInput() {
    this.firstName = "";
    document.getElementById("focusFName").focus();
    this.focusFName.nativeElement.focus();
  }

  changeFilter() {  
    this.appointmentListArray = [];
    this.clearData();
    if (this.filter == "Date") {
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.searchPatient();
    } else if (this.filter == "Name") {
      this.firstNameFlag = false;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = true;
      this.cdr.detectChanges();
      this.focusFirst_nameInput();
    } else if (this.filter == "Patient_ID") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMRNoInput();
    } else if (this.filter == "Mobile_No") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMobileNoInput();
    } 
  }

  OnDateChangedto(e){   
    this.date = e;
    this.clearData();
    this.searchPatient();  
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }

  getPatientListByMRNo(patientId) {
    if(patientId != undefined && patientId.length > 8){
      this.searchPatient();
    }
  }

  getPatientListByMobile_no(mobileNo) {
    if(mobileNo != undefined && mobileNo.length > 9) {
      this.searchPatient();
    }
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  searchPatient() {
    this.appointmentListArray = []; 
    var fieldvalue;
    if (this.filter == "Patient_ID") {
      fieldvalue = {   
        hptl_clinic_id: this.hospitalId,
        mr_no: this.patientId
      }
    } else if (this.filter == "Name") {
      fieldvalue = {      
        hptl_clinic_id: this.hospitalId,
        first_name: this.firstName,
        last_name: this.lastName
      }
    } else if (this.filter == "Date") {
      fieldvalue = { 
        hptl_clinic_id: this.hospitalId,
        date: this.date
      }
    } else if (this.filter == "Mobile_No") {
      fieldvalue = {   
        hptl_clinic_id: this.hospitalId,
        mobile: this.mobileNo
      }
    }
    console.log(fieldvalue);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/inpa', fieldvalue,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.appointment != null) {
            var Docidname, middleName = "";
            for (var i = 0; i < obj.appointment.length; i++) {
              obj.appointment[i].middle_name
              if (obj.appointment[i].middle_name != undefined) {
                Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                middleName = encrypt_decript.Decript(obj.appointment[i].middle_name);
              } else {
                Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
              }
              var salutation;
              if (obj.appointment[i].salutation_desc != null) {
                salutation = obj.appointment[i].salutation_desc+".";
              }
              var sessiondata = null;
              if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
              }

              var address1 = '',address2 = '';
              if (obj.appointment[i].address2 != null && obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                address1 = encrypt_decript.Decript(obj.appointment[i].address1)+ ",";
                if(obj.appointment[i].address2 != " " && obj.appointment[i].address2 != "null") {
                  if (encrypt_decript.Decript(obj.appointment[i].address2) == "") {
                    address2 == "";
                  } else {
                    address2 = encrypt_decript.Decript(obj.appointment[i].address2)+",";
                  }
                }
              } else if (obj.appointment[i].address1 != undefined) {
                address1 = encrypt_decript.Decript(obj.appointment[i].address1)+",";
              } else if (obj.appointment[i].address2 != undefined) {
                address2 = encrypt_decript.Decript(obj.appointment[i].address2)+",";
              }

              var location = '';
              if (obj.appointment[i].location != undefined && obj.appointment[i].location != null) {
                location = obj.appointment[i].location+",";
              }

              var city = '';
              if (obj.appointment[i].district != undefined && obj.appointment[i].district != null) {
                city = obj.appointment[i].district+"-";
              }

              var zipcode;
              if (obj.appointment[i].zipcode != undefined && obj.appointment[i].zipcode != null) {
                zipcode = encrypt_decript.Decript(obj.appointment[i].zipcode)+",";
              }     
              
              var state = '';
              if (obj.appointment[i].state != undefined && obj.appointment[i].state != null) {
                state = obj.appointment[i].state+"-";
              }

              var country = '';
              if (obj.appointment[i].country != undefined && obj.appointment[i].country != null) {
                country = obj.appointment[i].country+".";
              }


              var gender;
              if (obj.appointment[i].gender != undefined && obj.appointment[i].gender != undefined) {
                gender = encrypt_decript.Decript(obj.appointment[i].gender)
              }

              var gender_short;
              if ( obj.appointment[i].gender != null &&  obj.appointment[i].gender != undefined) {
                gender_short = encrypt_decript.Decript(obj.appointment[i].gender);
                gender_short = gender_short[0];
                if (gender_short != undefined && gender_short[0] == "T") {
                  gender_short = "Tgen";
                }
              }

              var docName;
              if (obj.appointment[i].dr_middle_name != undefined && obj.appointment[i].dr_middle_name != null) {
                docName = "Dr."+obj.appointment[i].dr_first_name + " " + obj.appointment[i].dr_middle_name + " " + obj.appointment[i].dr_last_name;
              } else if (obj.appointment[i].dr_first_name != undefined && obj.appointment[i].dr_last_name != undefined) {
                docName = "Dr."+obj.appointment[i].dr_first_name + " " + obj.appointment[i].dr_last_name;
              } 

              var hospital;
              if (obj.hosp_address2 != undefined) {
                hospital = obj.hosp_address1 + "," + obj.hosp_address2;

              } else {
                hospital = obj.hosp_address1;
              }

              if (obj.appointment[i].profile_image != ""){
                var profile_image = ipaddress.Ip_with_img_address + obj.appointment[i].profile_image;
              }

              var barcode_url;
              if (obj.appointment[i].barcode != undefined && obj.appointment[i].barcode != null) {
                this.barcode_flag = true;
                barcode_url = ipaddress.Ip_with_img_address + obj.appointment[i].barcode;
              }

              var admitted_date = obj.appointment[i].admitted_date != undefined ? obj.appointment[i].admitted_date : "" ;      
              var admitted_time = obj.appointment[i].admitted_time != undefined ? Time_Formate(obj.appointment[i].admitted_time): "";
              var em_contact = obj.appointment[i].emerg_contact != undefined ? encrypt_decript.Decript(obj.appointment[i].emerg_contact) : "";
              var mobile_no = obj.appointment[i].mobile != undefined ? encrypt_decript.Decript( obj.appointment[i].mobile) : "";
              var inpatientId = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
              this.hospitalName = obj.hosp_name != undefined ? obj.hosp_name : "";
              this.appointmentListArray.push({
                doc_app_id: obj.appointment[i].doc_app_id,
                doc_reg_id: obj.appointment[i].doc_reg_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,
                docid_name: Docidname,
                salutation: salutation,
                firtName: encrypt_decript.Decript(obj.appointment[i].first_name),
                lastName: encrypt_decript.Decript(obj.appointment[i].last_name),
                middleName: middleName,
                profile_image: profile_image,                
                address1: address1,
                address2: address2,
                clntLocation: location  ,
                clntCity: city,
                clntState: state,
                clntCountry: country,
                age: obj.appointment[i].age,
                gender: gender,
                gen_short: gender_short,
                patient_id: obj.appointment[i].patient_id,
                clntZipcode: zipcode,
                mobile_no: mobile_no,
                em_contact: em_contact,
                dr_name: docName,
                admitted_date: admitted_date,
                admitted_time: admitted_time,
                barcode_url: barcode_url
              });
            }

            if(this.appointmentListArray != undefined && this.appointmentListArray.length === 1) {
              for(var i = 0; i < this.appointmentListArray.length; i++) {
                this.clientName = this.appointmentListArray[i].docid_name;
                this.changeAppointment(this.clientName);
              }        
            }
          }
        },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )   
  }

  save() {
    this.getCurrentDate();
    this.billId = '';
    if (this.advanceArray.length == 0) {
      this.toastr.error(Message_data.plsEnterAdvAmt);
    } else {
      var senddata = {
        hptl_clinic_id: this.hospitalId,
        client_reg_id: this.clientRegID,
        app_id: this.appID,
        bills : this.advanceArray,
        country: "IN",
      }
      this.advanceFlag = false;
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/sadv/', senddata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj);
            if (obj != undefined && obj.key == "1") {
              this.billId = obj.advance_id;
              this.toastr.success(Message_data.advPaySavedSuccess);
              this.cdr.detectChanges();
              this.print();
              this.dialogRef.close();
            } else {
              this.toastr.error(Message_data.unableToAdvPaySave);
              this.advanceFlag = true;
            }
          });
    }
  }

  print() {
    var margin_top;
    let printContents, popupWin;
    if (this.printTemplate != undefined && this.printTemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  close() {
    this.dialogRef.close();
  }

  changeAppointment(docid_name) { 
    this.profile_image = "";   
    this.salutation_desc = "";
    this.clntMiddleName = "";
    this.clntLastName = "";
    this.clntAge = "";
    this.clntGenShort = "";
    this.clntAddress1 = "";
    this.clntLocation = "";
    this.clntCity = "";
    this.clntState = "";
    this.clntCountry = "";
    this.clntZipcode = "";
    this.mobile_no = "";
    this.docName = "";
    this.admitted_date = ""; 
    this.admitted_time = "";
    this.em_contact = "";
    this.barcode_url = "";

    var doc_app_id = docid_name.split("-");
    if (this.appointmentListArray != undefined && this.appointmentListArray.length != 0) {
      for (var i = 0; i < this.appointmentListArray.length; i++) {
        if (this.appointmentListArray[i].doc_app_id == parseFloat(doc_app_id[0])) {        
          this.createpage_flag = true;
          this.clientRegID = this.appointmentListArray[i].client_reg_id;
          this.advanceFlag = true;
          this.appID = this.appointmentListArray[i].doc_app_id;
          this.salutation_desc = this.appointmentListArray[i].salutation;
          this.clntFirstName = this.appointmentListArray[i].firtName;
          this.clntLastName = this.appointmentListArray[i].lastName;
          this.clntMiddleName = this.appointmentListArray[i].middleName != "" ? this.appointmentListArray[i].middleName : "";
          this.clntLocation = this.appointmentListArray[i].clntLocation;
          this.clntCity = this.appointmentListArray[i].clntCity;
          this.clntState = this.appointmentListArray[i].clntState;
          this.clntZipcode = this.appointmentListArray[i].clntZipcode
          this.clntCountry = this.appointmentListArray[i].clntCountry;
          this.patientId = this.appointmentListArray[i].patient_id;
          this.clntAge = this.appointmentListArray[i].age;
          this.clntGender = this.appointmentListArray[i].gender;
          this.clntGenShort = this.appointmentListArray[i].gen_short;
          this.clntAddress1 = this.appointmentListArray[i].address1;
          this.clntAddress2 = this.appointmentListArray[i].address2;
          this.mobile_no = this.appointmentListArray[i].mobile_no;
          this.docName = this.appointmentListArray[i].dr_name;
          this.admitted_date = this.appointmentListArray[i].admitted_date;
          this.admitted_time = this.appointmentListArray[i].admitted_time;
          this.profile_image = this.appointmentListArray[i].profile_image;
          this.em_contact = this.appointmentListArray[i].em_contact;
          this.barcode_url = this.appointmentListArray[i].barcode_url;
        }
      }
    }
  }

  addAdvance() {  
    if (this.advance == "" || this.advance == undefined) {
      this.toastr.error(Message_data.plsEnterAdvAmt);
    } else {
      this.advanceArray.push({
        amount : parseFloat(this.advance),
        pay_type : this.payType ,
        card_no : this.cardNumber ,
        card_holder_name : this.cardHolderName,
        transaction_no :this.transactionId ,
        bank_name : this.bankName,
        pay_desc : this.payDesc
      });
      this.totalAdvance = (parseFloat(this.totalAdvance) + parseFloat(this.advance));
      this.numToWords =  this.totalAdvance == 0 ? "" : convertNumberToWords(this.totalAdvance,ipaddress.country_code);
      this.payType= undefined;
      this.cardNumber = undefined;
      this.cardHolderName = undefined;
      this.transactionId = undefined;
      this.cardNumber = undefined;
      this.bankName = undefined;
      this.advance = undefined;
    }
  }
}
