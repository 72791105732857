import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common.js';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { Doc_Helper } from '../Doc_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress' ;import { Nurse_Helper } from '../../../app/Nurse_module/Nurse_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Chart } from 'chart.js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerApi } from 'src/app/server-api';

@Component({
  selector: 'app-doctor-app-list',
  templateUrl: './doctor-app-list.component.html',
  styleUrls: ['./doctor-app-list.component.css']
})
export class DoctorAppListComponent implements OnInit {
  public IsSpecilization;
  public medicineflag:boolean=false;
  public casesheet_list:any;
  public spo2color;
  public case_list_url;
  public gynecologyFlag: boolean = false;
  public pediatricsFlag: boolean = false;
  public cardioFlag: boolean = false;
  public nephrologyFlag: boolean = false;
  public othersFlag: boolean = false;
  public dentaFlag: boolean = false;
  public diabetesFlag: boolean = false;
  public generalFlag: boolean = false;
  public ENTFlag: boolean = false;
  public gastroFlag: boolean = false;
  public type;
  public submenuFlag: boolean = false;
  public submenuFlag1: boolean = false;

  public nodApp: boolean;
  public homeCare: string | null;
  public CurrentDatetime;
  public appointmentDate;
  public calendardate;
  public flagval: boolean;
  public datePick: string[] = ["docapp"];
  public splName;
  public splArray: any = [];
  public statusColor;
  public appt;
  public subRelationID

  public hospitalInfo: any = [];
  public hospitalId;

  public getAppDate;
  public specializations: any = [];
  public age_txt: string | null;
  public appointment_list: any = [];

  public first_name: string | null;
  public url = ipaddress.getIp + "usercontroller/providerdetails";
  public last_name: string | null;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;

  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public spl_id: string | null;
  public case_sheet: string | null;
  public doctor_profile_img: string | null;
  public doc_reg;
  public userinfo: any;
  public user_id: any;
  public obj: any;
  public data: any;
  public urlt = ipaddress.getIp + 'appointment/getdocapp';
  public spl_url;
  public textalign;
  public height;
  public width;

  public hptl_clinic_id;
  public doc_app_id;
  public loading_flag: boolean = true;
  public nodata_flag: boolean = false;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public checklistid: any = [];
  public currentDate;
  public appin_open_count: number = 0;
  public doctor_name;
  public isActive; boolean = false;

  public patient_list: any;
  public appt_List: any = [];
  public sleepGrphData: any = [];
  public sleepGraphValues: any = [];
  public nutritionGrphData: any = [];
  private cancelarray: any = [];
  
  public docCaseSummaryFlag: boolean = true;
  private appid: string;
  private mobile: string;
  private client_id: string;
  private relation_id: string;
  private sub_rel_id: string;
  public pres_flag: boolean;
  public hide_cancel: boolean=false;
  public casesheetAccessFlag:boolean=true;
  public cancelFlag:boolean=true;
  public confirmFlag:boolean=true;
  public casesheetFlag: boolean = true;
  public createFlag: boolean = true;
  public viewPresFlag: boolean=true;
  public assessFlag: boolean=true;
  
  doctorFullName: string;
  doctorList: any = [];
  userInfo: any;
  ipaddress: any;
  billingList: any[];
  doctorName: any;
  docRegId: any;
  public tempcolorstyle:boolean;
  public bpdata:any=[];
  public bpcolor:boolean;
  intervalId: NodeJS.Timeout;
  doctorListflag: boolean = false;
  user_type: any;
  doctorid: any;  private appconfirmlist:any=[];
  is_dmo:string;
  view_no_rx_flag: boolean;
  showAssessment: boolean = true;

  constructor(public dialog: MatDialog, private http: Http, private router: Router, private toastr: ToastrService,
    public doctormessageservice: MenuViewService, public masterCSdata: MasterCSHelperService,public serviceAPI: ServerApi, ) {
    Doc_Helper.setClient_Info(null);
    Doc_Helper.setAppFlow("case_sheet");
    Doc_Helper.setHome_care("0");
    Doc_Helper.setDiagAppflow("medical");
    Helper_Class.setInpatientFlow(undefined);
    Nurse_Helper.setINPatient(undefined);
    Helper_nephro_casesheet.nephro_get_ret = undefined;
    Helper_Class.setInpatientFlow(undefined);
    Helper_Class.setAsg(undefined);
  }

  ngOnInit(): void {
    Helper_Class.setassflow(null);
    this.changeProviderType();
    // this.getDoctors();
    this.isActive = false;
    this.loading_flag = true;
    this.nodata_flag = false;
    this.createFlag = false;

    this.dtOptions = {
      //pagingType: 'full_numbers',
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 6, 8] },
        { "width": "100px", "targets": 8 },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no etc"
      },
      dom: '<"row mb-3"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1  d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "4"){
          if(Helper_Class.getmodulelist()[i].create == "1"){
            this.createFlag=false;
          } 
        }
      }
    }
   
    this.userinfo = Helper_Class.getInfo();
    this.is_dmo = this.userinfo.is_dmo;
    this.user_id = this.userinfo.user_id;
    var hospital_details = Helper_Class.getHospital();
    this.hospitalId = hospital_details[0].hptl_clinic_id;

    Helper_Class.setapp_flow("0");
    for (var i = 0; i < this.userinfo.hospitals.length; i++) {
      this.hospitalInfo[i] = this.userinfo.hospitals[i].hptl_clinic_id;
    }
    this.hospitalId = this.hospitalInfo[0];
    if(this.userinfo.specializations != undefined){
      for (var j = 0; j < this.userinfo.specializations.length; j++) {
        this.specializations[j] = this.userinfo.specializations[j].spl_name;
      }
    }

    var moduleidlist = Doc_Helper.getModuleList();
    if (moduleidlist != undefined) {
      for (var i = 0; i < moduleidlist.length; i++) {
        if (moduleidlist[i].module_id == "85") {
          this.casesheetAccessFlag = true;
          Doc_Helper.setCasesheet_flag("1");
        } 
        if (moduleidlist[i].module_id == "67") {
          this.docCaseSummaryFlag = false;
          Doc_Helper.setCasesumm_flag("1");
        }
      }
    }

    if(Helper_Class.getAppCreate() =="1") {
      this.createFlag = true;
    } else {
      this.createFlag = false;
    }
    // this.intervalId =  setInterval(() => {
       this.getCurrentDate();
    // }, 60000);

  }

  changeProviderType() {
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.user_id = Helper_Class.getInfo().user_id;
      this.user_type = Helper_Class.getInfo().user_type;
      this.hospitalId = Helper_Class.getInfo().hptl_clinic_id;
      this.getDoctors();
      this.doctorListflag = true;
      this.loading_flag = false;
      this.pres_flag = true;
    }
  }
  
  changedoc() {
    this.user_id = this.doctorid;
    this.get_doc_apps(this.user_id);
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  close_appointment() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/clsapp/',
      JSON.stringify({
        app_id: this.appid,
        mobile: this.mobile,
        type: "doctor"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null) {
            if (obj.key == "1") {
              this.toastr.success("Successfully closed appointment");
              this.get_doc_apps(this.user_id);
            } else {
              this.toastr.error("Unable to close appointment, try again")
            }
          }
        }
      );
  }

  spiltdate(date) {
    var get_tmp = date.split("-");
    var date_appt = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    return date_appt;
  }

  OnDateMatePicker(e) {
    if (e == this.CurrentDatetime) {
      Doc_Helper.setAppDate("");
      this.appointmentDate = e;
      Doc_Helper.setAppDate(e);
      this.get_doc_apps(this.user_id);
      this.view_no_rx_flag = true;

    } else {
      this.get_doc_apps(this.user_id);
      this.view_no_rx_flag = false;
    }

    Doc_Helper.setAppointmentDate(e);
  }

  view_prescription() {
    this.doctormessageservice.sendMessage("viewpres");
  }

  isReadonly() { return true; }

  get_doc_apps(user_id) {
    if(this.userinfo != undefined){
      if(this.userinfo.role != undefined){
        if(this.userinfo.role == "Duty Medical Officer (DMO)"){
          this.type= "dmo",
          this.is_dmo="1"
        }

      } else{
        this.type= "doctor"
        this.is_dmo="0"
      }
    }
    this.homeCare = "0";
   
    this.appointment_list = [];
    var sen_data = JSON.stringify({
      home_care: this.homeCare,
      date: this.appointmentDate,
      doc_prov_id: user_id,
      country: "IN",
      type: this.type,
      is_dmo:this.is_dmo,
      hptl_clinic_id:this.hospitalId   
    });
    console.log("chgeck sen_data   --"+JSON.stringify(sen_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'appointment/getdocapp', sen_data, { headers: headers }).subscribe(
      response => {
        var data = response.json();
        console.log("chgeck data   --"+JSON.stringify(data))
        Helper_Class.setpatient_information(data);
        if (data != null || data != undefined || data != "") {
          if (data.appointments != null && data.appointments.length != 0) {
            console.log("chgeck data   --"+JSON.stringify(data.appointments))
            this.appt = data.appointments;
            this.nodApp = false;
            for (var i = 0; i < this.appt.length; i++) {
              var get_age;
              if (this.appt[i].age != null && this.appt[i].age != undefined && this.appt[i].age != " ") {
                get_age = this.appt[i].age;
              } else {
                get_age = "";
              }
              var Patient_name;
              if (this.appt[i].middle_name != undefined && this.appt[i].middle_name != null) // middle name 
              {
                Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].middle_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
              } else {
                Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
              }

              if (this.appt[i].mobile != null) {  
                var contactno = encrypt_decript.Decript(this.appt[i].mobile);
              }
              var profile_image = ipaddress.Ip_with_img_address + this.appt[i].profile_image;
              var lastvisit;
              if (this.appt[i].date != null) {
                var last_visit = this.appt[i].date;
                var gettmp = last_visit.split("-");
                lastvisit = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
              }
              var status_txt;
              if (this.appt[i].status == "0") {
                status_txt = "Completed";

              } else if (this.appt[i].status == "1") {
                if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                  if (this.appt[i].f_time != "1") {
                    var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                    var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                    if (get_time > cur_time) {
                      status_txt = "Not visited";
                    } else {
                      status_txt = "Open";
                    }
                  } else {
                    var get_time = new Date(this.appt[i].date);
                    var cur_time = new Date(this.CurrentDatetime);
                    if (get_time >= cur_time) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else {
                  if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                    status_txt = "Open";

                  } else {
                    status_txt = "Not visited";
                  }
                }

              } else if (this.appt[i].status == "2") {
                if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                  var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                  var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                  status_txt = "Confirmed";
                } else {
                  if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                    status_txt = "Open";
                  } else {
                    status_txt = "Not visited";
                  }
                }
              }

              if (this.appt[i].sub_rel_id != null) {
                this.subRelationID = this.appt[i].sub_rel_id;

              } else {
                this.subRelationID = "";
              }
              var dateofbirth;
              var session;
              var token_time_label;
              var location_desc;
              var city;
              var state;
              var country, country_id;
              var zipcode;
              var height;
              var height_measure;
              var weight;
              var weight_measure;
              if (this.appt[i].dob != null) {
                dateofbirth = this.spiltdate(this.appt[i].dob);
              }
              if (this.appt[i].symptoms != null) {
                var symptom = this.appt[i].symptoms
              }

              if (this.appt[i].session != null) {
                session = encrypt_decript.Decript(this.appt[i].session);
              }

              var token_flag: boolean;
              if (this.appt[i].token != null) {
                token_time_label = this.appt[i].token;
                token_flag = true;
              } else {
                token_flag = false;
                var Temptimesplit = this.appt[i].f_time;
                if (Temptimesplit == "1") {
                  token_time_label = "";
                } else {
                  var offTime = Temptimesplit.split(':');
                  offTime[0] = offTime[0] % 12;
                  var output = offTime.join(':');

                  var get_timeq = output.split(":");
                  if (get_timeq[0] == "0") {
                    output = "12" + ":" + get_timeq[1];
                  }
                  token_time_label = Time_Formate_Session(output, encrypt_decript.Decript(this.appt[i].session));
                }
              }

              if (status_txt == 'Open') {
                this.appin_open_count = this.appin_open_count + 1;
              }

              if (this.appt[i].location != undefined) {
                location_desc = this.appt[i].location
              }

              if (this.appt[i].city != undefined) {
                city = this.appt[i].city
              }

              if (this.appt[i].state != undefined) {
                state = this.appt[i].state
              }

              if (this.appt[i].country != undefined) {
                country = this.appt[i].country
              }
              if (this.appt[i].zipcode != undefined) {
                zipcode = this.appt[i].zipcode
              }
              if (this.appt[i].height != undefined) {
                height = this.appt[i].height
              } else {
                height = "";
              }
              if (this.appt[i].height_measure != undefined) {
                height_measure = this.appt[i].height_measure
              }
              if (this.appt[i].weight != undefined) {
                weight = this.appt[i].weight
              } else {
                weight = "";
              }
              var pat_id,visitpurpose,inpatientnuber,outpatientnumber,disease;
              if (this.appt[i].weight_measure != undefined) {
                weight_measure = this.appt[i].weight_measure
              }
              if (this.appt[i].pat_id != undefined) {
                pat_id = this.appt[i].pat_id
              }
              if (this.appt[i].visit_purpose != undefined) {
                visitpurpose = this.appt[i].visit_purpose
              }
              if (this.appt[i].inpatientnuber != undefined) {
                inpatientnuber = this.appt[i].inpatientnuber
              }
              if (this.appt[i].outpatientnumber != undefined) {
                outpatientnumber = this.appt[i].outpatientnumber
              }
              if (this.appt[i].disease != undefined) {
                disease = this.appt[i].disease
              }
              
              var ascomplaints,past_illness;
              if (this.appt[i].ascomplaints != undefined) {
                ascomplaints= this.appt[i].ascomplaints
              }
              
              if (this.appt[i].past_illness != undefined) {
                past_illness= this.appt[i].past_illness
              }

              // if (this.appt[i].complaints != undefined) {
              //   Helper_Class.setAssessComplaint(this.appt[i].complaints);
              // }

              // if (this.appt[i].provisional_diagnosis != undefined) {
              //   Helper_Class.setAssessProvDiag(this.appt[i].provisional_diagnosis);
              // }
              var doctor_name;
              if (this.appt[i].doc_middle_name != undefined) {
                doctor_name = this.appt[i].doc_first_name + " " + this.appt[i].doc_middle_name + " " + this.appt[i].doc_last_name;

              } else {
                doctor_name = this.appt[i].doc_first_name + " " + this.appt[i].doc_last_name;
              }
              //blood_group
              var blood_group
              if (this.appt[i].blood_group != undefined) {
                blood_group=this.appt[i].blood_group;
              }
              var tempval;
              if(this.appt[i].temparature != undefined) {
                var tval = this.appt[i].temparature.split(" ");
                tempval =tval[0];
              }
              
              var package_based = this.appt[i].package_based == "false" ? "No" : "Yes";
              console.log("this.appt[i].billing_id con --"+JSON.stringify(this.appt[i].billing_id)+this.type)
              if(this.appt[i].billing_id != undefined && this.type != "dmo") {
                this.appointment_list.push({
                  app_data: this.appt[i],
                  patient_name: Patient_name,
                  patient_id: this.appt[i].pat_id,
                  age: get_age,
                  status: status_txt,
                  session: session,
                  contact: contactno,
                  lastvisit: lastvisit,
                  profile_image: profile_image,
                  spl_id: this.appt[i].specialization_id,
                  hptl_clinic_id: this.appt[i].hptl_clinic_id,
                  doc_app_id: this.appt[i].doc_app_id,
                  gender_data: encrypt_decript.Decript(this.appt[i].gender),
                  medicare_name: this.appt[i].medicare_name,
                  client_reg_id: this.appt[i].client_reg_id,
                  relation_id: this.appt[i].relation_id,
                  sub_rel: this.subRelationID,
                  spl: this.appt[i].specialization,
                  relationship_name: this.appt[i].relationship_name,
                  dob_data: dateofbirth,
                  tot_app_count: token_time_label,
                  token_flag: token_flag,
                  symptoms: this.appt[i].symptoms,
                  refer_by: this.appt[i].referred_by,
                  address1: this.appt[i].address1,
                  address2: this.appt[i].address2,
                  location: location_desc,
                  city: city,
                  state: state,
                  country: country,
                  country_id: this.appt[i].country_id,
                  state_id: this.appt[i].state_id,
                  city_id: this.appt[i].city_id,
                  zipcode: zipcode,
                  clientaddress1: this.appt[i].rel_address1,
                  clientaddress2: this.appt[i].rel_address2,
                  clientlocation: this.appt[i].location_desc,
                  clientcity: this.appt[i].city_desc,
                  clientstate: this.appt[i].state_desc,
                  clientcountry: this.appt[i].country_desc,
                  clientcountry_id: this.appt[i].rel_country,
                  clientstate_id: this.appt[i].rel_state,
                  clientcity_id: this.appt[i].rel_city,
                  clientzipcode: this.appt[i].rel_zipcode,
                  height: height,
                  weight: weight,
                  height_measure: height_measure,
                  weight_measure: weight_measure,                 
                  bmi: this.appt[i].bmi,
                  bmr: this.appt[i].bmr,
                  temparature: tempval,
                  pulse: this.appt[i].pulse,
                  bp: this.appt[i].bp,
                  
                  spo2:this.appt[i].spo2,
                  cvs:this.appt[i].cvs,
                  resp_rate:this.appt[i].resp_rate,
                  // rs:this.appt[i].rs,
                  cns:this.appt[i].cns,
                  abd:this.appt[i].abd,
                  pr:this.appt[i].pr,
                  cbg:this.appt[i].cbg,
                  rs:this.appt[i].rs_txt,
                  rr:this.appt[i].rr_txt,
                  // rs:this.appt[i].rs,
                  dr_first_name: this.appt[i].doc_first_name,
                  dr_middle_name: this.appt[i].doc_middle_name,
                  dr_last_name: this.appt[i].doc_last_name,
                  app_date: this.appt[i].date,
                  package: package_based,
                  salutation: this.appt[i].salutation,
                  pat_id:pat_id,
                  life_style_id : this.appt[i].life_style_id,
                  doctor_name:doctor_name,
                  visitpurpose:visitpurpose,
                  inpatientnuber:inpatientnuber,
                  outpatientnumber:outpatientnumber,
                  disease:disease,
                  complaints:this.appt[i].ascomplaints,
                  present_illness:this.appt[i].present_illness,
                  medical_history:this.appt[i].medical_history,
                  family_history:this.appt[i].family_history,
                  physical_exam:this.appt[i].physical_exam,
                  ascomplaints:this.appt[i].ascomplaints,
                  past_illness:this.appt[i].past_illness,
                  barcode:this.appt[i].barcode,
                  blood_group:this.appt[i].blood_group,
                  allergy:this.appt[i].allergy,
                  drug_details:this.appt[i].drug_details,
                  env_details:this.appt[i].env_details,
                  food_details:this.appt[i].food_details,
                  inscomplaints:this.appt[i].complaints,
                  insdiagnosis:this.appt[i].provisional_diagnosis,
                  doc_reg_id:this.appt[i].doc_reg_id,

                  //visitpurpose,inpatientnuber,outpatientnumber
                });
                console.log("applimnbjhh --"+JSON.stringify(this.appointment_list))
              } else{
                if(this.type == "dmo"){
                  this.appointment_list.push({
                    clntfirst_name:this.appt[i].first_name,
                    clntmiddle_name:this.appt[i].middle_name,
                    clntlast_name:this.appt[i].last_name,
                    app_data: this.appt[i],
                    patient_name: Patient_name,
                    patient_id: this.appt[i].pat_id,
                    age: get_age,
                    status: status_txt,
                    session: session,
                    contact: contactno,
                    lastvisit: lastvisit,
                    profile_image: profile_image,
                    spl_id: this.appt[i].specialization_id,
                    hptl_clinic_id: this.appt[i].hptl_clinic_id,
                    doc_app_id: this.appt[i].doc_app_id,
                    gender_data: encrypt_decript.Decript(this.appt[i].gender),
                    medicare_name: this.appt[i].medicare_name,
                    client_reg_id: this.appt[i].client_reg_id,
                    relation_id: this.appt[i].relation_id,
                    sub_rel: this.subRelationID,
                    spl: this.appt[i].specialization,
                    relationship_name: this.appt[i].relationship_name,
                    dob_data: dateofbirth,
                    tot_app_count: token_time_label,
                    token_flag: token_flag,
                    symptoms: this.appt[i].symptoms,
                    refer_by: this.appt[i].referred_by,
                    address1: this.appt[i].address1,
                    address2: this.appt[i].address2,
                    location: location_desc,
                    city: city,
                    state: state,
                    country: country,
                    country_id: this.appt[i].country_id,
                    state_id: this.appt[i].state_id,
                    city_id: this.appt[i].city_id,
                    zipcode: zipcode,
                    clientaddress1: this.appt[i].rel_address1,
                    clientaddress2: this.appt[i].rel_address2,
                    clientlocation: this.appt[i].location_desc,
                    clientcity: this.appt[i].city_desc,
                    clientstate: this.appt[i].state_desc,
                    clientcountry: this.appt[i].country_desc,
                    clientcountry_id: this.appt[i].rel_country,
                    clientlocation_id: this.appt[i].rel_location,
                    clientstate_id: this.appt[i].rel_state,
                    clientcity_id: this.appt[i].rel_city,
                    clientzipcode: this.appt[i].rel_zipcode,
                    height: height,
                    weight: weight,
                    height_measure: height_measure,
                    weight_measure: weight_measure,                 
                    bmi: this.appt[i].bmi,
                    bmr: this.appt[i].bmr,
                    temparature: tempval,
                    pulse: this.appt[i].pulse,
                    bp: this.appt[i].bp,
                    
                    spo2:this.appt[i].spo2,
                    cvs:this.appt[i].cvs,
                    resp_rate:this.appt[i].resp_rate,
                    // rs:this.appt[i].rs,
                    cns:this.appt[i].cns,
                    abd:this.appt[i].abd,
                    pr:this.appt[i].pr,
                    cbg:this.appt[i].cbg,
                    rs:this.appt[i].rs_txt,
                    rr:this.appt[i].rr_txt,
                    // rs:this.appt[i].rs,
                    dr_first_name: this.appt[i].doc_first_name,
                    dr_middle_name: this.appt[i].doc_middle_name,
                    dr_last_name: this.appt[i].doc_last_name,
                    app_date: this.appt[i].date,
                    package: package_based,
                    salutation: this.appt[i].salutation,
                    pat_id:pat_id,
                    life_style_id : this.appt[i].life_style_id,
                    doctor_name:doctor_name,
                    visitpurpose:visitpurpose,
                    inpatientnuber:inpatientnuber,
                    outpatientnumber:outpatientnumber,
                    disease:disease,
                    complaints:this.appt[i].ascomplaints,
                    present_illness:this.appt[i].present_illness,
                    medical_history:this.appt[i].medical_history,
                    family_history:this.appt[i].family_history,
                    physical_exam:this.appt[i].physical_exam,
                    ascomplaints:this.appt[i].ascomplaints,
                    past_illness:this.appt[i].past_illness,
                    first_name:this.appt[i].first_name,
                    barcode:this.appt[i].barcode,
                    blood_group:this.appt[i].blood_group,
                    allergy:this.appt[i].allergy,
                    drug_details:this.appt[i].drug_details,
                    env_details:this.appt[i].env_details,
                    food_details:this.appt[i].food_details,
                    
                    insdiagnosis:this.appt[i].provisional_diagnosis,
                    doc_reg_id:this.appt[i].doc_reg_id,
                    //visitpurpose,inpatientnuber,outpatientnumber
                  });
                }
                console.log("else con --"+JSON.stringify(this.appointment_list))

              }
              Helper_Class.setPatientAppointment(this.appointment_list);
              console.log("check appointment_list"+JSON.stringify(this.appointment_list))
            }
            this.activeRowIndex = 0;
            if(this.appointment_list.length != 0){
              this.Doctor_View(this.appointment_list[0], this.activeRowIndex)
              this.loading_flag = false;
              this.nodata_flag = false;

            } else {
              this.nodata_flag = true;
              this.casesheetFlag=true;
              this.createFlag=true;
            }

          } else {
            this.appointment_list = [];
            this.nodApp = true;
            this.loading_flag = false;
            this.nodata_flag = true;
          }
        }
      })
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != undefined) {
      this.CurrentDatetime = obj.current_date;

      if (Doc_Helper.getAppDate() != undefined && Doc_Helper.getAppDate() != null && Doc_Helper.getAppDate() != "") {
        this.appointmentDate = Doc_Helper.getAppDate();
      } else {
        this.appointmentDate = this.CurrentDatetime;
      }
      this.get_doc_apps(this.user_id);

      this.view_no_rx_flag = true;
      Doc_Helper.setAppointmentDate(this.appointmentDate)
    }
  }

  activeRowIndex: number = -1;
  
  Doctor_View(list, i) {
    this.appconfirmlist=[];
    this.appid = list.doc_app_id;
    this.activeRowIndex = i;
    this.isActive = !this.isActive;
    this.appt_List = [];
    this.sleepGrphData = [];

    if (list.status == "Completed") {
      this.cancelFlag = true;
      this.confirmFlag = true;
      if(this.casesheetAccessFlag == false) {
        this.casesheetFlag = false;
      }
      this.viewPresFlag = false;
      this.assessFlag = true;
      this.medicineflag=false;
      this.pres_flag = true;

      this.showAssessment = false;
      this.view_no_rx_flag = false;

    } else if (list.status == "Confirmed") {
      this.cancelFlag = false;
      this.confirmFlag = true;
      if(this.casesheetAccessFlag == false) {
        this.casesheetFlag = false;
      } else {
        this.casesheetFlag = true;
      }
      this.viewPresFlag = false;
      this.assessFlag = false;
      this.pres_flag = false;
      this.medicineflag=true
      this.showAssessment = true;
      this.view_no_rx_flag = true;

    } else if (list.status == "Not visited") {
      this.cancelFlag = true;
      this.confirmFlag = true;
      this.casesheetFlag = false;
      this.viewPresFlag = true;
      this.assessFlag = true;
      this.medicineflag=true
      this.createFlag=false;
      this.pres_flag = true;
      this.showAssessment = false;
      this.view_no_rx_flag = false;

    } else if (list.status == "Open" && this.type !="dmo") {
      this.cancelFlag = true;
      this.confirmFlag = true;
      this.casesheetFlag = true;
      this.viewPresFlag = true;
      this.assessFlag = true;
      this.medicineflag=true;
      this.pres_flag = false;
      this.showAssessment = true;
      this.view_no_rx_flag = false;

    } else if (list.status == "Open" && this.type =="dmo") {
      this.cancelFlag = false;
      this.confirmFlag = true;
      this.casesheetFlag = false;
      this.viewPresFlag = false;
      this.assessFlag = false;
      this.medicineflag=true
      this.pres_flag = false;
      this.showAssessment = true;
    }

    this.appconfirmlist.push({
      app_id: list.doc_app_id,
      session: list.session,
      app_date: list.app_date,
      app_time: list.time,
      first_name: list.dr_first_name,
      middle_name: list.dr_middle_name,
      last_name: list.dr_last_name,
      mobile: list.contact,
      token: list.token,
      pat_id:list.pat_id
    });
   
    if (list.complaints != undefined) {
      Helper_Class.setAssessComplaint(list.complaints);
    }
    
    if (list.insdiagnosis != undefined) {
      Helper_Class.setAssessProvDiag(list.insdiagnosis);
    }
    if(this.userinfo.role != undefined){
      if(this.userinfo.role == "Duty Medical Officer (DMO)"){
        this.type= "Duty Medical Officer (DMO)"
      }

    } else{
      this.type= "doctor"
    }
    this.patient_list = {
      Age_data: list.age,
      Gender_data: list.gender_data,
      app_id: list.doc_app_id,
      client_name: list.patient_name,
      image: list.profile_image,profile_image:list.profile_image,
      sub_id: list.sub_rel,
      Client_id: list.client_reg_id,
      rel_id: list.relation_id,
      Case_Clnt_dob: list.dob_data,
      relationship_name: list.relationship_name,
      hptl_clinic_id: list.hptl_clinic_id,
      spl_id: list.spl_id,
      spl: list.spl,
      mobile: list.contact,
      symptoms: list.symptoms,
      Appoint_Date: list.lastvisit,
      medicare_name: list.medicare_name,
      refer_by: list.refer_txt,
      address1: list.address1,
      address2: list.address2,
      location: list.location,
      city: list.city,
      state: list.state,
      country: list.country,
      zipcode: list.zipcode,
      hptl_name: list.hosp_name,
      height: list.height,
      weight: list.weight,
      height_measure: list.height_measure,
      weight_measure: list.weight_measure,
      bmi: list.bmi,
      bmr: list.bmr,
      temparature: list.temparature,
      pulse: list.pulse,
      rr:list.rr,
      bp: list.bp,      
      spo2:list.spo2,
      cns:list.cns,
      abd:list.abd,
      cvs:list.cvs,     
      rs:list.rs,    
      resp_rate:list.resp_rate,
      pr:list.pr,
      cbg:list.cbg,
      patient_id: list.patient_id,
      AppFlow: "Doc_app_list",
      country_id: list.country_id,
      state_id: list.state_id,
      city_id: list.city_id, pat_id:list.pat_id,visitpurpose:list.visitpurpose,
      inpatientnuber:list.inpatientnuber,
      outpatientnumber:list.doc_app_id,
      doctor_name:list.doctor_name,
      disease:list.disease,  life_style_id : list.life_style_id,
      clientaddress1: list.clientaddress1,
      clientaddress2: list.clientaddress2,
      clientlocation: list.clientlocation,
      clientcity: list.clientcity,
      clientstate: list.clientstate,
      clientcountry: list.clientcountry,
      clientcountry_id: list.clientcountry_id,
      clientstate_id: list.clientstate_id,
      clientcity_id: list.clientcity_id,
      clientzipcode: list.clientzipcode,
      complaints:list.symptoms,
      present_illness:list.present_illness,
      medical_history:list.medical_history,
      family_history:list.family_history,
      physical_exam:list.physical_exam,
      salutation:list.salutation,    
      ascomplaints:list.ascomplaints,
      past_illness:list.past_illness,
      clntfirst_name:list.clntfirst_name,
      clntmiddle_name:list.clntmiddle_name,
      clntlast_name:list.clntlast_name,
      clientlocation_id: list.clientlocation_id,
      barcode:list.barcode,
      blood_group:list.blood_group,
      allergy:list.allergy,
      drug_details:list.drug_details,
      env_details:list.env_details,
      
      food_details:list.food_details,
      doc_reg_id:list.doc_reg_id,
      role:this.type,
    };

    this.checklistid.push(this.patient_list);
    Doc_Helper.setDietDiease(null)
    Doc_Helper.setDietDiease(list.disease)
    Helper_Class.Set_client_view_app_data(list.app_data);
    Doc_Helper.setClient_Info(this.patient_list);
    var data={
      height: list.height,
      weight: list.weight,
      height_measure: list.height_measure,
      weight_measure: list.weight_measure,
      bmi: list.bmi,
      bmr: list.bmr,
      temparature: list.temparature,
      pulse: list.pulse,
      rr:list.rr,
      bp: list.bp,      
      spo2:list.spo2,
      cns:list.cns,
      abd:list.abd,
      cvs:list.cvs,     
      rs:list.rs,    
      resp_rate:list.resp_rate,
      pr:list.pr,
      cbg:list.cbg,
    }
    Doc_Helper.setVitals(null);
    Doc_Helper.setVitals(data);
    this.appid = list.doc_app_id;
    this.mobile = list.contact;
    var sendata = JSON.stringify({
      app_id: list.doc_app_id,
    });
    if (list.spl_id == "2") {
      this.case_list_url=ipaddress.getIp + "record/clist";

    } else if (list.spl_id == "3") {
      this.case_list_url=ipaddress.getIp + "record/cordlist";

    } else if (list.spl_id == "7") {
      this.case_list_url=ipaddress.getIp + "record/gelist";

    } else if (list.spl_id == "8") {
      this.case_list_url=ipaddress.getIp + "record/ncaselist";

    } else if (list.spl_id == "10") {
      this.case_list_url=ipaddress.getIp + "record/pcaselist";

    } else if (list.spl_id == "11") {
      this.case_list_url=ipaddress.getIp + "record/gcaselist";

    } else if (list.spl_id == "12") {
      this.case_list_url=ipaddress.getIp + "record/gencaselist";

    } else if (list.spl_id == "13") {
      this.case_list_url=ipaddress.getIp + "record/dcaselist";

    } else if (list.spl_id == "29") {
      this.case_list_url=ipaddress.getIp + "record/gencaselist";

    } else if (list.spl_id == "27") {
      this.case_list_url=ipaddress.getIp + "record/entlist";

    }else{
      this.case_list_url = ipaddress.getIp + "record/otherlist";
    }
  
    this.getcasesheetlist(list.client_reg_id,list.relation_id,list.sub_rel)
    this.getASGDET(this.patient_list.Client_id, this.patient_list.rel_id, this.patient_list.sub_id);
    Doc_Helper.setSymptoms(this.patient_list.symptoms);
    Doc_Helper.setClient_Info(this.patient_list);
    this.spl_id = this.patient_list.spl_id;
    this.hptl_clinic_id = this.patient_list.hptl_clinic_id;
    this.doc_app_id = this.doc_app_id;
    Helper_Class.setspl_id(this.spl_id);
    this.IsSpecilization = this.patient_list.spl;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/gld', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("gld ------"+JSON.stringify(obj))
          var tempaturecol = parseInt(obj.temparature);
          var spo2color= parseInt(obj.spo2);
          if(tempaturecol > 100){
            this.tempcolorstyle=true;
           
          }else{
            this.tempcolorstyle=false;
          }
          if(spo2color > 75){
            this.spo2color=false;
           
          }else{
            this.spo2color=true;
          }
          
          this.bpdata.push({
            blood_pressure:obj.blood_pressure 
          })
          
          if(obj.blood_pressure != undefined){
            const [systolic, diastolic] = obj.blood_pressure.split('/').map(Number);
            if ((systolic > 160 || diastolic > 100) || (systolic < 110 || diastolic < 60)) {
             this.bpcolor=true;

            } else {
              this.bpcolor=false;
            }
          }
          
          var hrate,pr;
          if(obj.heart_rate != undefined) {
            hrate = obj.heart_rate
          } else {
            hrate = list.pulse;
          }
          if(obj.pr != undefined) {
            pr = obj.pr
          } else {
            pr = list.pr;
          }
          var resprate;
          if(obj.respiratory_rate != undefined){
            resprate = obj.respiratory_rate;
          } else {
            resprate = list.resp_rate;
          }
          var rr;
          if(obj.rr != undefined){
            rr = obj.rr;
          } else {
            rr = list.rr;
          }
          var tempval;
          if(obj.temparature != undefined) {
            var tval = obj.temparature.split(" ");
            tempval =tval[0];
          }
          this.appt_List.push({
            heart_rate: pr,
            pr:pr,
            blood_pressure: obj.blood_pressure,
            respiratory_rate: rr,
            postprandial: obj.postprandial,
            temparature: tempval,
            spo2: obj.spo2,
            cbg:obj.cbg
          });

          this.sleepGrphData.push({
            awake: obj.awake,
            deep: obj.deep,
            light: obj.light,
            rem: obj.rem
          })

          this.renderChart(obj)
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  moveToCasesheet() {
    var oflag: boolean = false;

    if (this.IsSpecilization == "Gynecology") {
      this.gynecologyFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Pediatrics") {
      this.pediatricsFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Cardiology") {
      this.cardioFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Nephrology") {
      this.nephrologyFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Dental") {
      this.dentaFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Diabetes") {
      this.diabetesFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Gastroenterology") {
      this.gastroFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "General" || this.IsSpecilization == "General surgery") {
      this.generalFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "ENT") {
      this.ENTFlag = true;
      oflag = true;

    } else {
      if (oflag == false) {
        this.othersFlag = true;
      }
    }
    console.log("this.gynecologyFlag-- "+JSON.stringify(this.gynecologyFlag)+this.nephrologyFlag)

    if (this.gynecologyFlag == true) {
      this.Get_gynaretrieval_data();
    }
    if (this.gynecologyFlag == false) {
      this.getcasesheetDetails();
    }
  }

  getASGDET(Client_id, rel_id, sub_id) {
    var sen_pass;
    if (sub_id != null && sub_id != undefined && sub_id != "") {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        sub_rel_id: sub_id,
        country: ipaddress.country_code
      });
    } else {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        country: ipaddress.country_code
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/asgdet', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.setAsg(obj);
        });

  }

  getcasesheetDetails() {
    Helper_Class.setRet(null);
    Helper_Class.setpediaRet(null);

    if (Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != undefined &&
      Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id.length != 0) {
      var send_data = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: "IN",
      });

    } else {
      send_data = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: "IN",
      });
    }

    if (this.pediatricsFlag == true) {
      this.spl_url = 'pedia/chist';

    } else if (this.cardioFlag == true) {
      this.spl_url = 'cardio/chist';

    } else if (this.nephrologyFlag == true) {
      this.spl_url = 'neph/chist';

    } else if (this.dentaFlag == true) {
      this.spl_url = 'dental/perdet/';

    } else if (this.diabetesFlag == true) {
      this.spl_url = 'diab/chist';

    } else if (this.generalFlag == true) {
      this.spl_url = 'gen/chist';

    } else if (this.ENTFlag == true) {
      this.spl_url = 'ent/chist';

    } else if (this.gastroFlag == true) {
      this.spl_url = 'gast/chist';

    } else {
      this.spl_url = 'other/chist';
    }
    console.log("checkk -this.nephrologyFlagthis.nephrologyFlag.spl_url-- "+JSON.stringify(this.nephrologyFlag))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.spl_url, send_data,
      { headers: headers })
      .subscribe(
        response => { 
          var obj = response.json();
          console.log("checkk -key.spl_url-- "+JSON.stringify(obj))
          if (obj["key"] != 0) {
            if (obj != null || obj != "" || obj != undefined) {
              console.log("checkk -this.spl_url-- "+JSON.stringify(obj))
              Helper_Class.setRet(obj);
              if (this.pediatricsFlag == true) {
                Helper_Class.setpediaRet(obj);
              }
              if (this.nephrologyFlag == true) {
                Helper_nephro_casesheet.nephro_get_ret = undefined;
                Helper_nephro_casesheet.personal_hist_array = undefined;
                Helper_nephro_casesheet.bmi_hist_array = undefined;
                Helper_nephro_casesheet.vitals_sit_array = undefined;
                Helper_nephro_casesheet.nephro_get_ret = obj;
                console.log("***"+JSON.stringify(Helper_nephro_casesheet.nephro_get_ret))
              }

              if (this.dentaFlag == true) {
                Helper_Class.Set_den_ret1(obj);
                this.Dental_Retrieval_2();
              }
              if (this.gastroFlag == true) {
                if (obj.spl_name != undefined) {
                  Helper_Class.setOtherSpl(obj.spl_name);
                }
              }
              if (this.generalFlag == true) {
                if (obj.spl_name != undefined) {
                  Helper_Class.setOtherSpl(obj.spl_name);
                }
              }
              if (this.dentaFlag != true && this.nephrologyFlag != true) {
                if(this.casesheet_list  != 0 ){
                  this.doctormessageservice.sendMessage("casesheet_list");
                }else{
                  this.doctormessageservice.sendMessage("casesheet")
                }
               
              } else {
                this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
              }
            }

          } else {
            if (this.nephrologyFlag == true) {
              Helper_nephro_casesheet.nephro_get_ret = undefined;
              Helper_nephro_casesheet.personal_hist_array = undefined;
              Helper_nephro_casesheet.bmi_hist_array = undefined;
              Helper_nephro_casesheet.vitals_sit_array = undefined;
              Helper_nephro_casesheet.nephro_get_ret = obj;
              console.log("***"+JSON.stringify(Helper_nephro_casesheet.nephro_get_ret))
            }
            if (this.diabetesFlag == true) {
              Helper_Class.setRet(null);
              Helper_Class.setRet(obj);
              console.log("***"+JSON.stringify(Helper_Class.getRet()))
            }
            if (Doc_Helper.getCasesheet_flag() == "1") {
              this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
            } else {
              this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
            }
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        })
  }

  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined)
    Helper_Class.setRet(undefined)

    if (Doc_Helper.getClient_Info().sub_id.length != 0 && Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != undefined) {
      var sub_id_data = Doc_Helper.getClient_Info().sub_id;
      var sendata = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: sub_id_data,
        country: ipaddress.country_code
      });
    } else {
      sendata = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      })
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'goi/chist', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("chist obj"+JSON.stringify(obj))
          if (obj["key"] != 0) {
            if (obj != null && obj != undefined && obj != "") {
              Helper_Class.setgynaRet(obj);
              Helper_Class.setRet(obj);
              if(this.casesheet_list  != 0 ){
                this.doctormessageservice.sendMessage("casesheet_list");
              }else{
                this.doctormessageservice.sendMessage("casesheet")
              }
            }

          } else {
            this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getCaseSheetFields(hptl_id, spl_id, doc_app_id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/gpfhsp/',
      JSON.stringify({
        hosp_id: hptl_id,
        spl_id: spl_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.setHopitalfieldsRet(null);
          Helper_Class.setHopitalfieldsRet(obj.pages);
          console.log("pagesssssssss-"+JSON.stringify(Helper_Class.getHopitalfieldsRet()))
          this.get_case_sheet_data(this.doc_app_id)
        },//response
      )
  }

  get_case_sheet_data(doc_app_id) {
    Doc_Helper.setDischarge_Summary(undefined);
    var sen_pass
    if (Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id) {
      sen_pass = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code
      });

    } else {
      sen_pass = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'gen/cbook', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("c book res"+JSON.stringify(obj))
          if (obj["key"] != 0) {
            if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
              Doc_Helper.setDischarge_Summary("S");
              Helper_Class.setcasesumret(obj);
            } else {
              Helper_Class.setcasesumret(undefined);
            }

          } else {
            Helper_Class.setcasesumret(undefined);
          }
          if (this.gynecologyFlag == true) {
            const dialogRef = this.dialog.open(GynaObestricPopSelectionComponent, {
              width: '280px',
              height: '182px'
            });

          } else {
            if(this.casesheet_list  != 0 ){
              this.doctormessageservice.sendMessage("casesheet_list");
            }else{
              this.doctormessageservice.sendMessage("casesheet")
            }
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  Dental_Retrieval_2() {
    if (Doc_Helper.getClient_Info().sub_id.length != 0) {
      var sen_pass = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code,
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });

    } else {
      var sen_pass = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code,
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'dental/patdet/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.Set_den_ret2(obj);
          this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
        }
      )
  }

  getSpeciality() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'usercontroller/splbydoc/',
      JSON.stringify({
        doc_reg_id: this.user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.specialization != null) {
            for (var i = 0; i < obj.specialization.length; i++) {
              this.splArray.push({
                spl_id: obj.specialization[i].spl_id,
                spl_name: obj.specialization[i].spl_name,
              })
            }
            this.splName = this.splArray[0].spl_name;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  checkUncheckAll() {
    this.checklist = this.appointment_list;
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.length == this.appin_open_count) {
        // uncheck all
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          var token = "", time = "";
          if (this.checklist[i].token_flag == true) {
            token = this.checklist[i].tot_app_count
          } else {
            time = this.checklist[i].tot_app_count;
          }
          
          this.checklistid.push({
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }
      } else {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Confirmed') {
            var token = "", time = "";
            if (this.checklist[i].token_flag == true) {
              token = this.checklist[i].tot_app_count
            } else {
              time = this.checklist[i].tot_app_count;
            }
            this.checklistid.push({
              app_id: this.checklist[i].doc_app_id,
              session: this.checklist[i].session,
              app_date: this.checklist[i].app_date,
              app_time: time,
              first_name: this.checklist[i].dr_first_name,
              middle_name: this.checklist[i].dr_middle_name,
              last_name: this.checklist[i].dr_last_name,
              mobile: this.checklist[i].contact,
              token: token,
            });
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Confirmed') {
          var token = "", time = "";
          if (this.checklist[i].token_flag == true) {
            token = this.checklist[i].tot_app_count
          } else {
            time = this.checklist[i].tot_app_count;
          }
          this.checklistid.push({
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }
      }

    }
  }

  isAllSelected(list) {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.indexOf(list) !== -1) {
        var index = this.checklistid.indexOf(list);
        this.checklistid.splice(index, 1);
      } else {
        var token = "", time = "";
        if (list.token_flag == true) {
          token = list.tot_app_count
        } else {
          time = list.tot_app_count;
        }
        this.checklistid.push({
          app_id: list.doc_app_id,
          session: list.session,
          app_date: list.app_date,
          app_time: time,
          first_name: list.dr_first_name,
          middle_name: list.dr_middle_name,
          last_name: list.dr_last_name,
          mobile: list.contact,
          token: token,
        });
      }
    } else {
      var token = "", time = "";
      if (list.token_flag == true) {
        token = list.tot_app_count
      } else {
        time = list.tot_app_count;
      }
      this.checklistid.push({
        app_id: list.doc_app_id,
        session: list.session,
        app_date: list.app_date,
        app_time: time,
        first_name: list.dr_first_name,
        middle_name: list.dr_middle_name,
        last_name: list.dr_last_name,
        mobile: list.contact,
        token: token,
      });
    }
    this.appid = list.doc_app_id;
    this.mobile = list.contact;
    this.client_id = list.client_reg_id;
    this.relation_id = list.relation_id;
    this.sub_rel_id = list.sub_rel_id;
  }

  getCheckedItemList() {
    if (this.checklist.length == this.checklistid.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checklistid.length != 0) {
      this.toastr.success('Thank you...', ``);

    } else {
      this.checklistid = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.appointment_list;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  hideloader() {
    document.getElementById('loading')
      .style.display = 'none';
  }

  Appointment_confirm() {
    if (this.appconfirmlist.length !=0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: "doctor",
          doc_apps: this.appconfirmlist,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                this.get_doc_apps(this.user_id);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }

  }

  cancel() {
    if (this.appid != undefined) {
      this.cancelarray.push(this.appid);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          doc_cancel: this.cancelarray,
          home_care: "0",
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.ngOnInit();
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }

  create_appointment() {
    this.doctormessageservice.sendMessage("docappcreate");
  }

  write_prescription() {
    this.doctormessageservice.sendMessage("docpresnocase");
    Doc_Helper.setAppFlow('apptList')
  }

  renderChart(values) {
    this.nutritionGrphData = [];

    var myChart = new Chart("piechart_gen", {
      type: 'doughnut',
      data: {
        labels: ['Awake', 'Deep', 'Light', 'Rem'],
        datasets: [{
          label: '# of Votes',
          data: Object.values(this.sleepGrphData[0]),
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
          ]
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'right',
          }
        }
      }
    });

    var myChart = new Chart("appt_activity_chrt", {
      type: 'doughnut',
      data: {
        labels: ['Actual', 'Expected'],
        datasets: [{
          label: '# of Votes',
          data: [values.kcal_burned, 1200],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ]
        }]
      },
      options: {
        cutoutPercentage: 80,
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'right',
          }
        }
      }
    });

    this.nutritionGrphData.push({
      carbs: values.carbs,
      fat: values.fat,
      proteins: values.proteins,
      energy: values.energy
    })

    var myChart = new Chart("appt_nutrition_chrt", {
      type: 'pie',
      data: {
        labels: ['Carbs', 'Fat', 'Proteins', 'Energy'],
        datasets: [{
          label: '# of Votes',
          data: Object.values(this.nutritionGrphData[0]),
          backgroundColor: [
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',

          ]
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'right',
          }
        }
      }
    });
  }

  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.user_id,
      type: this.user_type,
      hptl_clinic_id: this.hptl_clinic_id,
    };
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/docbyhosp', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (
                obj.doctors[i].middle_name != '' &&
                obj.doctors[i].middle_name != undefined
              ) {
                doctorName =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;
              } else {
                doctorName =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }

            this.doctorid = this.doctorList[0].Doc_ID;
            this.user_id = this.userinfo.user_id;
            for (var i = 0; i < this.doctorList.length; i++) {
              if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {

              }
            }
          }
        },
        (error) => {

        }
      );
  }
 
  getcasesheetlist(Client_id,rel_id,sub_id){
    var send_data = {};
    if(sub_id != null && sub_id != undefined && sub_id != ""
    && sub_id.length != 0) {
      send_data = JSON.stringify(
        {
          doc_reg_id: this.user_id,
          client_reg_id:Client_id,
          relation_id: rel_id,
          sub_rel_id: sub_id,
          country: ipaddress.country_code
        }
      )

    } else {
      send_data = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Client_id,
        relation_id: rel_id,
        country: ipaddress.country_code
      });
    }
    this.casesheet_list=[];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.case_list_url, send_data, { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        this.casesheet_list = dataval.case_history.length;
      },
      error => {});
  }
  
  assessment(){
    var data = Doc_Helper.getClient_Info();
    Doc_Helper.setAppFlow("Assessment");
    Helper_Class.setassesment(data)
    this.doctormessageservice.sendMessage("docassessment");
  }
}
