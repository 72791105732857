import { Component, OnInit, Input,ViewChild ,ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $:any;
@Component({
  selector: 'app-bill-refund-create',
  templateUrl: './bill-refund-create.component.html',
  styleUrls: ['./bill-refund-create.component.scss']
})
export class BillRefundCreateComponent implements OnInit {
  @ViewChild('printbillbanner') printbillbanner: ElementRef;
  @ViewChild('printbillnoheader') printbillnoheader: ElementRef;
  @ViewChild('printadvancebanner') printadvancebanner: ElementRef;
  @ViewChild('printadvancenoheader') printadvancenoheader: ElementRef;
  public filterType: string;
  public fromDate: string;
  public toDate: string;
  public dateFlag: boolean;
  public mr_no_flag: boolean;
  public clientFlag: boolean;
  public billFlag: boolean;
  public saveFlag: boolean;
  public clientFilterList = [];
  public filteredClientList = [];
  public clientList = [];
  public clntName: string;
  public middleName: string;
  public lastName: string;
  public clientId: string;
  public relationId: string;
  public subRelId: string;
  public billingId: string;
  public fName: string;
  public mName: string;
  public lName: string;
  public billAmount: any;
  public refundType: string;
  public refundAmount: any;
  public advanceAmount: any;
  public hptlClinicId: string; 
  public billList = [];
  public billDetails = [];
  public refundList = [];
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospId;
  public userId;
  public billRemarks;
  public billtype;
  private diagcenterid;
  private fin_year;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public bill: boolean;
  public advance: boolean;
  public advance_flag: boolean;
  public advanceId;
  public advanceList = [];
  public advanceRefundAmount;
  public advanceRemarks;
  public client_reg_id;
  public advance_no_flag: boolean = false;
  public bill_flag: boolean;
  public userInfo: any;
  public hospitals: any;
  public hospital_logo: any;
  public hospital_name: any;
  public hospitalAddress: any;
  public Hospital_location: string;
  public telephone: any;
  public patient_name: string;
  public mrno: any;
  public billing_print_array = [];
  public sal;
  public print_item_name: string;
  public patientId: any;
  public patient_id: any;
  public salutation_desc: any;
  public clntAge: any;
  public clntGenShort: any;
  public clntAddress1: string;
  public clntAddress2: string;
  public clntLocation: any;
  public clntCity: any;
  public clntState: any;
  public clntCountry: any;
  public clntZipcode: any;
  public mobile_no: any;
  public docName: string;
  public bill_id: any;
  public created_date: any;
  public created_time: any;
  public createpage_flag: boolean = false;
  public hospitalLogo;
  public printlogostyle;
  public bill_head;
  public timevalue: string;
  public title: string = "";
  public numToWords: string;
  public printTemplate: any;
  public profile_image: string;
  public em_contact: any;
  public print_size: any;
  public finalRefundList = [];
  public barcode_flag: boolean = false;
  public barcode_url;
  public logUserName;
  public banner_style: any;
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.profile_image = "../../assets/img/default.jpg";
    this.userInfo = Helper_Class.getInfo();
    console.log("USERINFO",this.userInfo);
    this.fin_year = this.userInfo.fin_year;
    this.hospitals = this.userInfo.hospitals[0];
    this.hospital_logo = ipaddress.Ip_with_img_address+this.hospitals.logo;
    this.hospital_name = this.hospitals.hptl_name;
    var hospital;
        if (this.hospitals.address2 != undefined) {
          hospital = this.hospitals.address1 + "," + this.hospitals.address2;
        } else {
          hospital = this.hospitals.address1;
        }
    this.hospitalAddress = hospital;
    this.Hospital_location = this.hospitals.location_desc + ", " + this.hospitals.city_desc + " - " + this.hospitals.state_desc + " " + this.hospitals.country_desc + "  " + this.hospitals.zipcode;
    this.telephone = this.hospitals.telephone;
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle=Helper_Class.getInfo().bill_print_logo_style;
    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.printTemplate =  Helper_Class.getInfo().bill_print_template;
    this.print_size = Helper_Class.getInfo().bill_print_paper;
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.diagcenterid = Helper_Class.getInfo().diag_center_id;
    this.userId = Helper_Class.getInfo().user_id;
    this.filterType = "Client";
    this.billtype="Hospital";
    this.bill = true;
    this.filterTypeChange();
    this.getCurrentDate();
    this.Moduleidlist = Helper_Class.getmodulelist();

    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "92") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }                    
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (this.userInfo.hptl_name == 'SGH') {
      this.banner_style = "width: 100%; height: 210px !important;";
    } else {
      this.banner_style = "width: 100%; height: 195px !important;";
    }

    if (this.userInfo.first_name != undefined) {
      this.logUserName = this.userInfo.first_name;
      if (this.userInfo.last_name != undefined) {
        this.logUserName = this.userInfo.first_name+" "+this.userInfo.last_name;
      }
    }
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      {country: ipaddress.country_code},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.currentDate = obj.current_date;
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.timevalue = Time_Formate(this.currentTime);
          this.fromDate = this.currentDate;
          this.toDate = this.currentDate;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  filterTypeChange(){
    this.clearData();
    if(this.filterType =="Bill number"){
      this.dateFlag = true;
      this.mr_no_flag = true;
      this.clientFlag=true;
      this.billFlag= false;  
      this.fName="";
      this.mName="";
      this.lName="";
      this.billingId="0";
      this.refundAmount = 0;
      this.saveFlag=false;
      this.billList=[];
      this.createpage_flag = false;
      this.bill_flag = false;
      this.advance_flag = false;
    } else if(this.filterType =="Date"){
      this.dateFlag = false;
      this.mr_no_flag = true;
      this.clientFlag=true;
      this.billFlag= true;
      this.saveFlag=true;
      this.fName="";
      this.mName="";
      this.lName="";
      this.billingId="0";
      this.refundAmount = 0;
      this.billList=[];
      this.createpage_flag = false;
      this.bill_flag = false;
      this.advance_flag = false;
      this.getCurrentDate();
    } else if(this.filterType =="Client"){
      this.dateFlag = true;
      this.mr_no_flag = true;
      this.clientFlag=false;
      this.billFlag= true;
      this.saveFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount = 0;
      this.billList=[];
      this.createpage_flag = false;
      this.bill_flag = false;
      this.advance_flag = false;
    } else if(this.filterType =="MR No"){
      this.mr_no_flag = false;
      this.dateFlag = true;
      this.clientFlag=true;
      this.billFlag= true;
      this.saveFlag=true;
      this.fName="";
      this.mName="";
      this.lName="";
      this.patient_id = "";
      this.billingId="0";
      this.refundAmount = 0;
      this.billList=[];
      this.createpage_flag = false;
      this.bill_flag = false;
      this.advance_flag = false;
    } 
  }

  clearData() {
    this.clntName = '';
    this.middleName = '';
    this.lastName = '';
    this.fromDate = this.currentDate;
    this.toDate = this.currentDate;
    this.billingId = '';
    this.patient_id = '';
  }

  clientFilter() {
    if (this.clntName != undefined && this.clntName.length >= 2) {
      this.clientFilterList = [];
      this.clntName = this.clntName.toLocaleUpperCase();
      this.clientList = [];
      this.middleName = "";
      this.lastName = "";
      var clntname = encrypt_decript.Encript(this.clntName).toString();
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyadm/', { client_name: clntname},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse( JSON.stringify(response));    
            if (obj.clients != null) {
              this.clientList = [];
              this.clientFilterList = obj.clients;
              for (var i = 0; i < this.clientFilterList.length; i++) { 
                var lName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
                var mName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
                this.clientList.push({
                  name: encrypt_decript.Decript(this.clientFilterList[i].first_name) + " " + mName + " " + lName,
                  first_name:encrypt_decript.Decript(this.clientFilterList[i].first_name),
                  last_name:lName,
                  client_id: this.clientFilterList[i].client_reg_id,
                  relation_id: this.clientFilterList[i].relation_id,
                  sub_rel_id:this.clientFilterList[i].sub_rel_id,
                });
              }
              this.filteredClientList = this.clientList;
            }
          },
          error => {});
    }   
  }

  selectClient(data) {
    this.clntName = data.first_name;
    this.clientId=undefined;
    this.relationId=undefined;
    this.subRelId=undefined;
    for (var i = 0; i < this.clientFilterList.length; i++) {
      if(this.clientFilterList[i].client_reg_id == data.client_id && this.clientFilterList[i].relation_id == data.relation_id){
        this.middleName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
        this.lastName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
        this.clientId=this.clientFilterList[i].client_reg_id;
        this.relationId= this.clientFilterList[i].relation_id;
        if(this.clientFilterList[i].sub_rel_id != undefined && this.clientFilterList[i].sub_rel_id != null){
          this.subRelId = this.clientFilterList[i].sub_rel_id;
        }
      }
    }
    this.clientList = [];
  }

  handleCheckboxChange(checkboxType: string) {
    this.createpage_flag = false;
    if (checkboxType === 'bill' && this.bill) {
      this.advance = false;
      this.advance_no_flag = false;
    } else if (checkboxType === 'advance' && this.advance) {
      this.bill = false;
      this.advance_no_flag = true;
      this.filterType = 'Date';
      this.filterTypeChange();
    }
  }

  searchBills(){
    this.billAmount = "";   
    this.refundAmount = "";
    this.billRemarks = "";
    this.fName = "";
    this.mName = "";
    this.lName = "";
    this.advanceId = "";
    this.advanceAmount = "";
    this.advanceRefundAmount = "";
    this.advanceRemarks = "";
    this.billList = [];
    this.advanceList = [];
    this.billDetails = [];
    this.patientId = '';
    this.salutation_desc = '';
    this.fName = '';
    this.mName = '';
    this.lName = '';
    this.clntAge = '';
    this.clntGenShort = '';
    this.mobile_no = '';
    this.docName = '';
    this.bill_id = '';
    this.created_date = '';
    this.created_time = '';
    this.em_contact = '';
    this.clntAddress1 = '';
    this.clntAddress2 = '';
    this.clntLocation = '';
    this.clntCity = '';
    this.clntZipcode = '';
    this.clntState = '';
    this.clntCountry = '';

    var btype;
    if(this.billtype=="Hospital"){
      btype ="doctor";
    } else {
      btype ="diagnosis"
    }
    
    if(this.filterType == "Bill number"){
      this.getBillDetailsById();
      this.saveFlag = false;  
    } else {
      var fromdate;
      var todate;
      var fieldvalue;
      if (this.fromDate != undefined){
        fromdate=Date_Formate(this.fromDate);
        todate=Date_Formate(this.toDate);
      }
      
      var is_advance;
      if (this.advance == true) {
        is_advance = "1";
      }
      else {
         is_advance = "0";
      }

      if(this.filterType == "Date"){
        fieldvalue = {
          center_id: this.hospId,
          from_date: Date_Formate(fromdate) ,
          to_date: Date_Formate(todate),
          app_type: btype,
          is_advance: is_advance,
          is_refund:"1"
        }
      } else if(this.filterType =="Client"){
        fieldvalue = {
          center_id: this.hospId,
          first_name: this.clntName,
          last_name: this.lastName,
          app_type: btype,
          is_advance: is_advance,
          is_refund:"1"
        }
      } else if(this.filterType =="MR No"){
        fieldvalue = {
          center_id: this.hospId,
          patient_id: this.patient_id,
          app_type: btype,
          is_advance: is_advance,
          is_refund:"1"
        }
      }

      if(this.billtype =="Diagnosis"){
        fieldvalue.provider_id =this.diagcenterid;
        fieldvalue.center_id = this.diagcenterid;
      }

      console.log(fieldvalue);
       
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/getbills/', {fieldvalue},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));     
            console.log(obj);  
            if(obj.bills != undefined && obj.bills != null && this.bill){
              this.billList = obj.bills;
              this.bill_flag = true;
              this.advance_flag = false;
              if (this.billList.length != 0 && this.billList.length == 1) {
                this.billingId = this.billList[0].bill_id;
                this.getBillDetails();
              }
            } 
            if(obj.advances != undefined && obj.advances != null && this.advance) {
              this.advanceList = obj.advances;
              this.advance_flag = true;
              this.bill_flag = false;
              if (this.advanceList.length != 0 && this.advanceList.length == 1) {
                this.advanceId = this.advanceList[0].bill_id;
                this.getAdvanceDetails();
              }
            }    
                   
          },
          error => {});
    }
  }
 
  getBillDetails(){
    this.patientId = "";
    this.salutation_desc = "";
    this.fName = "";
    this.mName = "";
    this.lName = "";
    this.billAmount = "";
    this.refundAmount = "";
    this.billRemarks = ""
    this.client_reg_id = "";
    this.clntAge = "";
    this.clntGenShort = "";
    this.clntAddress1 = "";
    this.clntAddress2 = "";
    this.clntLocation = "";
    this.clntCity = "";
    this.clntState = "";
    this.clntCountry = "";
    this.clntZipcode = "";
    this.mobile_no = "";
    this.bill_id = "";
    this.created_date = "";
    this.created_time = "";
    this.numToWords = "";
    this.docName = "";
    this.em_contact = "";
    this.profile_image = "";
    this.refundList = [];
    
    for(var i=0;i<this.billList.length;i++){
      if(this.billList[i].bill_id == this.billingId){
        this.fin_year = this.billList[i].fin_year;      
      }
    }
    this.getBillDetailsById();
  }

  getBillDetailsById(){
    this.createpage_flag = false;
    var headers = new HttpHeaders();
    if(this.billtype =="Hospital"){
      var senddata = {
        hptl_clinic_id: this.hospId,
        billing_id: this.billingId,
        app_type: "doctor",
        fin_year:this.fin_year,
        is_refund:"1"
      }

    } else {
      var senddata = {
        hptl_clinic_id: this.diagcenterid,
        billing_id: this.billingId,
        app_type: "diagnosis",
        fin_year:this.fin_year,
        is_refund:"1"
      }
    }
    
    console.log("senddata",senddata);
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbillhosp/', senddata, { headers: headers })
      .subscribe(
        response => {        
          var obj = JSON.parse(JSON.stringify(response));
          console.log("BilDetails",obj);
          this.patientId = obj.client_hosp_id != undefined ? obj.client_hosp_id : "";
          this.salutation_desc = obj.salutation_desc != undefined ? obj.salutation_desc+"." : "";
          this.fName = obj.first_name != undefined ? encrypt_decript.Decript(obj.first_name) : "";
          this.mName= obj.middle_name != undefined ? encrypt_decript.Decript(obj.middle_name) : "";
          this.lName= obj.last_name != undefined ? encrypt_decript.Decript(obj.last_name) : "";
          this.billAmount = obj.bill_amount != undefined ?  parseFloat(encrypt_decript.Decript(obj.bill_amount)) : "";        
          this.clntAge = obj.age != undefined ? obj.age : "";
          this.profile_image = obj.profile_image != undefined ? ipaddress.Ip_with_img_address + obj.profile_image : "";
          this.em_contact = obj.emerg_contact != undefined ? encrypt_decript.Decript(obj.emerg_contact) : "";
          if ( obj.gender != null &&  obj.gender != undefined) {
            this.clntGenShort = encrypt_decript.Decript(obj.gender);
            this.clntGenShort = this.clntGenShort[0];
            if (this.clntGenShort != undefined && this.clntGenShort[0] == "T") {
              this.clntGenShort = "Tgen";
            }
          }

          if (obj.caddress2 != null && obj.caddress2 != undefined && obj.caddress1 != undefined) {
            this.clntAddress1 = encrypt_decript.Decript(obj.caddress1)+ ",";
            if(obj.caddress2 != " " && obj.caddress2 != "null") {
              if (encrypt_decript.Decript(obj.caddress2) == "") {
                this.clntAddress2 == "";
              } else {
                this.clntAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
              }
            }

          } else if (obj.caddress1 != undefined) {
            this.clntAddress1 = encrypt_decript.Decript(obj.caddress1)+",";

          } else if (obj.caddress2 != undefined) {
            this.clntAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
          }

          var location = '';
          if (obj.clocation != undefined && obj.clocation != null) {
            location = obj.clocation+",";
          }

          var city = '';
          if (obj.ccity != undefined && obj.ccity != null) {
            city = obj.ccity+"-";
          }

          var zipcode;
          if (obj.czipcode != undefined && obj.czipcode != null) {
            zipcode = encrypt_decript.Decript(obj.czipcode)+",";
          }     
          
          var state = '';
          if (obj.cstate != undefined && obj.cstate != null) {
            state = obj.cstate+"-";
          }

          var country = '';
          if ( obj.ccountry != undefined &&  obj.ccountry != null) {
            country =  obj.ccountry+".";
          }

          var barcode_url;
          if (obj.barcode != undefined && obj.barcode != null) {
              this.barcode_flag = true;
              barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
          }

          this.clntLocation = location;
          this.clntCity = city;
          this.clntState = state;
          this.clntCountry = country;
          this.clntZipcode = zipcode;
          this.mobile_no = obj.mobile != undefined ? encrypt_decript.Decript(obj.mobile) : "";
          this.bill_id = obj.billing_id != undefined ? obj.billing_id : "";
          this.billingId = this.bill_id;
          this.created_date = obj.created_date != undefined ? obj.created_date : "";
          this.created_time = obj.created_time != undefined ? Time_Formate(obj.created_time) : "";
          this.barcode_url = barcode_url;

          if (obj.dr_first_name != undefined && obj.dr_middle_name != undefined && obj.dr_last_name != undefined) {
            this.docName = "Dr."+ obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;

          } else if (obj.dr_first_name != undefined && obj.dr_last_name != undefined) {
            this.docName = "Dr."+ obj.dr_first_name + " " + obj.dr_last_name;

          } else if (obj.dr_first_name != undefined) {
            this.docName = "Dr."+ obj.dr_first_name;
            
          } else {
            this.docName = "";
          }

          if(obj.bills != undefined){   
            this.billDetails=[];
            if(this.billtype=="Hospital"){
              for(var i=0;i<obj.bills.length;i++){
                this.billDetails.push({
                  charge_type:obj.bills[i].charge_type,
                  charge_desc:obj.bills[i].charge_desc,
                  amount_txt: parseFloat(obj.bills[i].amount_txt),
                  concession: parseFloat(obj.bills[i].concession),
                  fee: parseFloat(obj.bills[i].fee),
                })
              }
            } else if(this.billtype=="Diagnosis"){
              for(var i=0;i<obj.bills.length;i++){
                this.billDetails.push({
                  charge_type: obj.bills[i].test_id,
                  charge_desc: obj.bills[i].test_name,
                  amount_txt: parseFloat(obj.bills[i].amount_txt),
                  concession: parseFloat(obj.bills[i].concession),
                  fee: parseFloat(obj.bills[i].fee),
                })
              }
            }
          }
          for(var i=0;i<this.billList.length;i++){
            if(this.billList[i].bill_id == this.billingId){
              this.saveFlag=false; 
              this.createpage_flag = true;      
            }
          }

          if (this.bill == true) {
            this.bill_flag = true;
            this.advance_flag = false;
          }
      
          if (this.advance == true) {
            this.advance_flag = true;
            this.bill_flag = false;
          } 
          this.saveFlag=false; 
          this.createpage_flag = true;
        },
        error => {});  
  }

  getAdvanceDetails(){
    this.patientId = "";
    this.salutation_desc = "";
    this.fName = "";
    this.mName = "";
    this.lName = "";
    this.advanceAmount = "";
    this.advanceRefundAmount = "";
    this.advanceRemarks = "";
    this.client_reg_id = "";
    this.clntAge = "";
    this.clntGenShort = "";
    this.clntAddress1 = "";
    this.clntAddress2 = "";
    this.clntLocation = "";
    this.clntCity = "";
    this.clntState = "";
    this.clntCountry = "";
    this.clntZipcode = "";
    this.mobile_no = "";
    this.bill_id = "";
    this.created_date = "";
    this.created_time = "";
    this.numToWords = "";
    this.docName = "";
    this.em_contact = "";
    this.profile_image = "";
    this.refundList = [];
    this.createpage_flag = false;
    this.barcode_url = '';

    console.log("AdvanceListArray",this.advanceList);
    for (var i=0;i<this.advanceList.length;i++) {
      if (this.advanceList[i].bill_id == this.advanceId) {
        this.saveFlag=false;         
        this.patientId = this.advanceList[i].mrno != undefined ? this.advanceList[i].mrno : "";
        this.salutation_desc = this.advanceList[i].salutation != undefined ? this.advanceList[i].salutation+"." : "";
        this.fName = this.advanceList[i].first_name != undefined ? encrypt_decript.Decript(this.advanceList[i].first_name) : "";
        this.mName= this.advanceList[i].middle_name != undefined ? encrypt_decript.Decript(this.advanceList[i].middle_name) : "";
        this.lName= this.advanceList[i].last_name != undefined ? encrypt_decript.Decript(this.advanceList[i].last_name) : "";
        this.advanceAmount = parseFloat(this.advanceList[i].amount);
        this.advanceRefundAmount = parseFloat(this.advanceList[i].amount);             
        this.client_reg_id = this.advanceList[i].client_reg_id;     
        this.clntAge = this.advanceList[i].age != undefined ? this.advanceList[i].age : "";

        if ( this.advanceList[i].gender != null &&  this.advanceList[i].gender != undefined) {
          this.clntGenShort = encrypt_decript.Decript(this.advanceList[i].gender);
          this.clntGenShort = this.clntGenShort[0];
          if (this.clntGenShort != undefined && this.clntGenShort[0] == "T") {
            this.clntGenShort = "Tgen";
          }
        }
          
        if ((this.advanceList[i].address1  != undefined && this.advanceList[i].address2 != undefined) && (this.advanceList[i].address1 != null && this.advanceList[i].address2 != null)) {
          this.clntAddress1 = encrypt_decript.Decript(this.advanceList[i].address1) + ",";
          if(this.advanceList[i].address2 != " " && this.advanceList[i].address2 != "null") {
            if (encrypt_decript.Decript(this.advanceList[i].address2) == "") {
              this.clntAddress2 == "";
            } else {
              this.clntAddress2 = encrypt_decript.Decript(this.advanceList[i].address2)+",";
            }
          }         
        } else if (this.advanceList[i].address1 != undefined && this.advanceList[i].address1 != null) {
          this.clntAddress1 = encrypt_decript.Decript(this.advanceList[i].address1)+",";
        } else if (this.advanceList[i].address2 != undefined && this.advanceList[i].address2 != null) {
          this.clntAddress2 = encrypt_decript.Decript(this.advanceList[i].address2)+",";
        } else {
          this.clntAddress1 = "";
          this.clntAddress2 = "";
        }

        if (this.advanceList[i].barcode != undefined && this.advanceList[i].barcode != null) {
            this.barcode_flag = true;
            this.barcode_url = ipaddress.Ip_with_img_address + this.advanceList[i].barcode;
        }
        
        this.profile_image = this.advanceList[i].profile_image != undefined ? ipaddress.Ip_with_img_address + this.advanceList[i].profile_image : "";
        this.em_contact = this.advanceList[i].emerg_contact != undefined ? encrypt_decript.Decript(this.advanceList[i].emerg_contact) : "";
        this.clntLocation = this.advanceList[i].location != undefined ? this.advanceList[i].location+"," : "";
        this.clntCity = this.advanceList[i].city != undefined ? this.advanceList[i].city+"-" : "";
        this.clntState = this.advanceList[i].state != undefined ? this.advanceList[i].state+"," : "";
        this.clntCountry = this.advanceList[i].country != undefined ? this.advanceList[i].country+"." : "";
        this.clntZipcode = this.advanceList[i].zipcode != undefined ? encrypt_decript.Decript(this.advanceList[i].zipcode)+"," : "";
        this.mobile_no = this.advanceList[i].mobile != undefined ? encrypt_decript.Decript(this.advanceList[i].mobile) : "";
        this.bill_id = this.advanceList[i].bill_id != undefined ? this.advanceList[i].bill_id : "";
        this.created_date = this.advanceList[i].date != undefined ? this.advanceList[i].date : "";
        this.created_time = this.advanceList[i].created_time != undefined ? Time_Formate(this.advanceList[i].created_time) : "";
        this.numToWords =  this.advanceRefundAmount == 0 ? "" : convertNumberToWords(this.advanceRefundAmount,ipaddress.country_code);              
        this.createpage_flag = true;
      }
    }
  }

  calculateRefund(value){
    var flag: boolean;
    if(this.refundList.length !=0){
      for(var i=0;i<this.refundList.length;i++){
        if(this.refundList[i].charge_type == value){
          this.refundAmount = parseFloat(this.refundAmount) - parseFloat(this.refundList[i].fee);
          this.refundList.splice(i,1);
          flag = true;
        }
      }
    } 
  
    if(flag != true){
      for(var i=0;i<this.billDetails.length;i++){
        if(this.billDetails[i].charge_type == value){
          this.refundList.push({
            charge_type: this.billDetails[i].charge_type,
            fee: this.billDetails[i].fee
          });   
             
          this.refundAmount = Number(this.refundAmount) + Number(this.billDetails[i].fee);
          this.numToWords =  this.refundAmount == 0 ? "" : convertNumberToWords(this.refundAmount,ipaddress.country_code);
        }
      }
    }  
    this.refundType = (Number.parseInt(this.billAmount) - this.refundAmount != 0) ? "partial" : "full payment";   
  }

  save(){
    this.getCurrentDate();
    this.finalRefundList = [];
    var saveflag = true;
    if(this.bill) {
      saveflag = this.billRemarks == "" ? false : true;
    }
    else {
      saveflag = this.advanceRemarks == "" ? false : true; 
    } 
    var provid;
    var btype;
    if(this.billtype=="Hospital"){
      btype ="doctor";
      this.title = "Service Charge Refund";
      provid=this.hospId
    } else {
      btype ="diagnosis";
      this.title = "Lab Bill Refund";
      provid = this.diagcenterid;
    }

    if (this.refundList != undefined && this.refundList.length != 0) {
      for(var i=0;i<this.refundList.length;i++){
        this.finalRefundList.push(this.refundList[i].charge_type);
      }
    }
    
    var headers = new HttpHeaders();
    var sendData;
    if(saveflag == true){
      if (this.bill) {
         sendData = {
          pay_type: "bill",
          hptl_clinic_id: provid,
          created_by: this.userId,
          bill_id: this.billingId,
          charge_type: this.finalRefundList,
          refund_amount: this.refundAmount,
          refund_type: this.refundType,
          remarks: this.billRemarks,
          country:"IN",
          bill_type: btype,
          fin_year:this.fin_year
        }
      } else if (this.advance) {
        sendData = {
          pay_type: "advance",
          balance: this.advanceRefundAmount,
          refund_amount: this.advanceAmount,
          refund_reason: this.advanceRemarks, 
          hptl_clinic_id: provid,
          client_reg_id: this.client_reg_id,
          advance_id: this.advanceId,
          country:"IN"
        }                    
      }
      console.log(sendData);
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/sref',sendData,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj);
            if (obj.key === "1") {
              this.saveFlag = true;  
              this.toastr.success(Message_data.refundSavedSuccess);
              if(this.bill) {
                this.print_item_name = "billPrint";
                this.print_area(this.print_item_name);
                this.bill = false;
              } else if (this.advance) {
                this.print_item_name = "advancePrint";
                this.print_area(this.print_item_name);
                this.advance = false;
              }     
              if (this.userInfo.user_type == 'Admin') {
                this.messageservice.sendMessage("Frontrefund");
              } else {
                this.messageservice.sendMessage("refund");
              }                 
            } else {
              this.toastr.error("Unable to save refund bill,please try later");
            }          
          });

    } else {
      this.saveFlag = false;  
      this.toastr.error("Unable to save refund bill,please try later");
    }
  }

  print_area(print_item) {
    var margin_top;
    let printContents, popupWin;
    if(print_item === "billPrint" && this.printTemplate === "banner") {
      margin_top = '0px';
      printContents = this.printbillbanner.nativeElement.innerHTML;
    } else if (print_item === "billPrint" && this.printTemplate !== "banner") {
      margin_top = '122px';
      printContents = this.printbillnoheader.nativeElement.innerHTML;
    } else if(print_item === "advancePrint" && this.printTemplate === "banner") {
      margin_top = '0px';
      printContents = this.printadvancebanner.nativeElement.innerHTML;
    } else if(print_item === "advancePrint" && this.printTemplate !== "banner") {
      margin_top = '122px';
      printContents = this.printadvancenoheader.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  back(){
    if (this.userInfo.user_type == 'Admin') {
      this.messageservice.sendMessage("Frontrefund");
    } else {
      this.messageservice.sendMessage("refund");
    }   
  }
  
}
