<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Assessment</h5>
          </div>
          <div class="headerButtons">
            <div>
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backbutton()" width="85px"
                style="margin-right: 9px;" />
            </div>

            <div *ngIf="show_write_rx">
              <img src="../../../assets/ui_icons/buttons/write_RX_Button.svg" (click)="writerx()" width="85px"
                style="margin-right: 9px;" />
            </div>
            <div>
              <img src="../../../assets/img/printer.svg" (click)="print()" width="25px" height="25px" 
                style="cursor: pointer;margin-right: 9px;" />
            </div>
            <div *ngIf="show_write_rx">
              <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" width="85px"
                style="margin-right: 9px;" />
              <!-- <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                (click)="update()" *ngIf="updateFlag"> -->
            </div>
            <!-- <div>
              <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save()" width="85px"
                style="margin-right: 9px;" />
                </div> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
      <div class="row">
        <div class="Details" style="margin-top: -10px;">
          <div class="row" style="padding: 0 12px;">
            <div class="cover_div" style="padding-bottom: 13px;">
              <div class="header_lable">Personal Details</div>
              <div class="content_cover">

                <div class="row">
                  <div class="col-2" style="text-align: center;">
                    <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                      class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                  </div>
                  <div class="col-10">
                    <div class="row">
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 550;">MR no:</span> {{mrnno}}</p>

                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 550;">Name:</span> {{patient_name}}
                        </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 550;">Age/Gender:</span>
                          {{patient_age}}/{{patient_gender}}</p>

                      </div>

                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 550;">Doctor:</span> {{doctor_name}}</p>

                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 550;">Speciality:</span>
                          {{speclization_arry}}</p>
                      </div>
                      <div class="col-6">
                        <p class="textformat"> <span style="font-weight: 550;">Address:</span> {{clntAddress1}}</p>
                      </div>
                      <!-- <div class="col-3">
                        <p class="textformat">    Address2: {{this.clntAddress2}}</p>
                    </div> -->
                      <!-- <div class="col-3">
                        <p class="textformat"> Zipcode: {{this.clntZipcode}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Location: {{this.clntLocation}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> District / City: {{this.clntCity}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> State: {{this.clntState}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Country: {{this.clntCountry}}</p>
                      </div> -->
                      <!-- <div class="col-3">
                        <p class="textformat">Mobile: {{this.clntContNumber}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Emergency contact1: {{this.clntEmergNumber}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Emergency contact2: {{this.clntEmergNumber1}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Aadhar Number: {{this.aadharNumber}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Whatsapp Number: {{this.whatsappNumber}}</p>
                      </div> -->
                      <!-- <div class="col-3">
                                    <p class="textformat">   Symptoms: {{this.clntSymptom}}</p>
                                  </div> -->


                      <!-- <div class="col-3">
                        <p class="textformat"> Address: {{this.clntAddress1}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Address2: {{this.clntAddress2}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Zipcode: {{this.clntZipcode}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Location: {{this.clntLocation}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> District / City: {{this.clntCity}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> State: {{this.clntState}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Country: {{this.clntCountry}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Channel: {{this.ref_source}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> Referred by: {{this.referredDoctor}} </p>
                      </div> -->
                      <!-- <div class="col-3">
                                    <p class="textformat">   Visit purpose: {{this.visit_purpose}}</p>
                                  </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;">
              <div class="row">
                <div class="col-4">
                  <mat-label class="matlabel" style="font-weight: 600;">In-patient / Out-patient : </mat-label>
                </div>
                <div class="col-8">
                  <div class="switch-field">
                    <input type="radio" id="radio-one" (click)="changetype('app',2,'yes')" />
                    <label for="radio-one"
                      [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">Out-patient</label>
                    <input type="radio" id="radio-two" (click)="changetype('app',2,'no')" />
                    <label for="radio-two"
                      [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">In-patient</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;"
              [hidden]="inpatientflag">
              <div class="row">
                <div class="col-4">
                  <mat-label class="matlabel" style="font-weight: 600;">Normal/ICU : </mat-label>
                </div>
                <div class="col-8">
                  <div class="switch-field">
                    <input type="radio" id="radio-three" (click)="changetype('admission',3,'yes')" />
                    <label for="radio-three" [ngClass]="ynoptapp1 ? 'radio_active':'radio_inactive'">Normal</label>
                    <input type="radio" id="radio-four" (click)="changetype('admission',4,'no')" />
                    <label for="radio-four" [ngClass]="!ynoptapp1 ? 'radio_active':'radio_inactive'">ICU</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <mat-label class="matlabel"> <b class="label_bold">Complaints<span class="required-marker">*</span></b>
                <quill-editor #editor_chief id="editor_chief" [(ngModel)]="chief_comp" [style]="editorStyle"
                  [modules]="modules" placeholder="Enter Complaints" [styles]="{height: 'calc(35vh - 100px)'}">
                </quill-editor>
                <div class="auto_complete_text1" *ngIf="chiefComplaintsList.length != 0">
                  <ul *ngFor="let chiefComplaints of chiefComplaintsList">
                    <li>
                      <a (click)="select_chiefComplaints(chiefComplaints)">{{chiefComplaints}}</a>
                    </li>
                  </ul>
                </div>
              </mat-label>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
             
              <mat-label class="matlabel"> <b class="label_bold">Present illness</b>
                <quill-editor id="editor1" [(ngModel)]="pres_ill" [styles]="{height: 'calc(35vh - 100px)'}"
                  [modules]="modules" placeholder="Enter Present illness" (onContentChanged)="changelinemgmt($event)">
                </quill-editor>
                <div>
                  <!-- Preview -->
                  <quill-view-html hidden id="linemgmt" [content]="pres_ill"></quill-view-html>
                </div>
              </mat-label>
            </div>
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel">
                    <b class="label_bold">Present illness</b>
                  </mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="editor1" [(ngModel)]="pres_ill" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter Present illness"
                    (onContentChanged)="changelinemgmt($event)">
                  </quill-editor>
                  <div> -->
            <!-- Preview -->
            <!-- <quill-view-html hidden id="linemgmt" [content]="pres_ill"></quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              
              <mat-label class="matlabel"> <b class="label_bold">Past medical history</b>
                <quill-editor id="pastill" [(ngModel)]="past_illness" [styles]="{height: 'calc(35vh - 100px)'}"
                  [modules]="modules" placeholder="Enter Past illness" (onContentChanged)="changelinemgmt1($event)">
                </quill-editor>
                <div>
                  <!-- Preview -->
                  <quill-view-html hidden id="pastill" [content]="past_illness"></quill-view-html>
                </div>
              </mat-label>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <mat-label class="matlabel"> <b class="label_bold">Surgical History</b>
                <quill-editor id="family" [(ngModel)]="surgicalvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                  [modules]="modules" placeholder="Enter Surgical History"
                  (onContentChanged)="changesurgical($event)">
                </quill-editor>
                <div>
                  <!-- Preview -->
                  <quill-view-html hidden id="family" [content]="surgicalvalue"></quill-view-html>
                </div>
              </mat-label>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <mat-label class="matlabel"> <b class="label_bold">Family history</b>
                <quill-editor id="family" [(ngModel)]="family_history" [styles]="{height: 'calc(35vh - 100px)'}"
                  [modules]="modules" placeholder="Enter Family history" (onContentChanged)="changelinemgmt2($event)">
                </quill-editor>
                <div>
                  <!-- Preview -->
                  <quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                </div>
              </mat-label>
            </div>
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel">
                    <b class="label_bold">Surgical History</b>
                  </mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="family" [(ngModel)]="surgicalvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter Surgical History"
                    (onContentChanged)="changesurgical($event)">
                  </quill-editor>
                  <div>-->
            <!-- Preview -->
            <!-- <quill-view-html hidden id="family" [content]="surgicalvalue"></quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel">
                    <b class="label_bold">Heart disease</b>
                  </mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="family" [(ngModel)]="heartdisease" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter Heart disease"
                    (onContentChanged)="changeheartdisease($event)">
                  </quill-editor>
                  <div>
                    <quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel">
                    <b class="label_bold">Family history</b>
                  </mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="family" [(ngModel)]="family_history" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter Family history"
                    (onContentChanged)="changelinemgmt2($event)">
                  </quill-editor>
                  <div>-->
            <!-- Preview -->
            <!--<quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col-12" style="margin-top: 15px;">
              <mat-label class="matlabel ">Pain Scale</mat-label>
              <div class="wrapper" style="text-align: center;">
                <ul class="pain-scale">
                  <li *ngFor="let level of painLevels" class="pain-scale__level" style="text-align: center;"
                    (click)="handlePainScaleClick(level.number)">
                    <div>
                      <img class="pain-scale__emoji emojiimage"
                        [ngStyle]="{ 'background-color': level.selected ? '#d3d3d3' : '#fff', 'box-shadow': level.selected ? 'rgb(38, 57, 77) 0px 20px 30px -10px' : 'none' }"
                        [src]="level.emoji">
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- diabe -->
          <div class="row">
            <div class="col-12" style="margin-top: 15px">
              <div class="row" style="margin: 15px;">
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">Diabetes : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt2 != undefined">
                        <input type="radio" id="radio-five" (click)="changeAllery('Diabetes',1,'yes')" />
                        <label for="radio-five" [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-six" (click)="changeAllery('Diabetes',1,'no')" />
                        <label for="radio-six" [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="diabflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="diabdetials"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">Hypertension : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt3 != undefined">
                        <input type="radio" id="radio-seven" (click)="changeAllery('hyper',1,'yes')" />
                        <label for="radio-seven" [ngClass]="ynopt3 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-eight" (click)="changeAllery('hyper',1,'no')" />
                        <label for="radio-eight" [ngClass]="!ynopt3 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4">

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="hyperflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="hype_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">CKD : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt12 != undefined">
                        <input type="radio" id="radio-nine" (click)="changeAllery('CKD',1,'yes')" />
                        <label for="radio-nine" [ngClass]="ynopt12 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-ten" (click)="changeAllery('CKD',1,'no')" />
                        <label for="radio-ten" [ngClass]="!ynopt12 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>

                    <div class="col-4">

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="ckdflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="ckddetails"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">CAD : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt4 != undefined">
                        <input type="radio" id="radio-eleven" (click)="changeAllery('cad',1,'yes')" />
                        <label for="radio-eleven" [ngClass]="ynopt4 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-tweleve" (click)="changeAllery('cad',1,'no')" />
                        <label for="radio-tweleve" [ngClass]="!ynopt4 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4">

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="cadflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="caddetails"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">CVD : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt5 != undefined">
                        <input type="radio" id="radio-thirteen" (click)="changeAllery('cvd',1,'yes')" />
                        <label for="radio-thirteen" [ngClass]="ynopt5 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-fourteen" (click)="changeAllery('cvd',1,'no')" />
                        <label for="radio-fourteen" [ngClass]="!ynopt5 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="cvdflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="cvd_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">Tuberculosis : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt6 != undefined">
                        <input type="radio" id="radio-fifteen" (click)="changeAllery('tuber',1,'yes')" />
                        <label for="radio-fifteen" [ngClass]="ynopt6 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-sixteen" (click)="changeAllery('tuber',1,'no')" />
                        <label for="radio-sixteen" [ngClass]="!ynopt6 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="tuberflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="tuber_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">BA : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt15 != undefined">
                        <input type="radio" id="radio-twontyone" (click)="changeAllery('ba',1,'yes')" />
                        <label for="radio-twontyone" [ngClass]="ynopt15 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-twontytwo" (click)="changeAllery('ba',1,'no')" />
                        <label for="radio-twontytwo" [ngClass]="!ynopt15 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="baflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="ba_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">PTB : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt16 != undefined">
                        <input type="radio" id="radio-twontythree" (click)="changeAllery('ptb',1,'yes')" />
                        <label for="radio-twontythree" [ngClass]="ynopt16 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-twontyfour" (click)="changeAllery('ptb',1,'no')" />
                        <label for="radio-twontyfour" [ngClass]="!ynopt16 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="ptbflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="ptb_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">COPD : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt17 != undefined">
                        <input type="radio" id="radio-twontyfive" (click)="changeAllery('copd',1,'yes')" />
                        <label for="radio-twontyfive" [ngClass]="ynopt17 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-twontysix" (click)="changeAllery('copd',1,'no')" />
                        <label for="radio-twontysix" [ngClass]="!ynopt17 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="copdflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="copd_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">APD : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt18 != undefined">
                        <input type="radio" id="radio-twontyseven" (click)="changeAllery('apd',1,'yes')" />
                        <label for="radio-twontyseven" [ngClass]="ynopt18 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-twontyeight" (click)="changeAllery('apd',1,'no')" />
                        <label for="radio-twontyeight" [ngClass]="!ynopt18 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="apdflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="apd_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">SEIZURE DISORDER : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt19 != undefined">
                        <input type="radio" id="radio-twontynine" (click)="changeAllery('seizure',1,'yes')" />
                        <label for="radio-twontynine" [ngClass]="ynopt19 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-thirtyeight" (click)="changeAllery('seizure',1,'no')" />
                        <label for="radio-thirtyeight" [ngClass]="!ynopt19 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="seizureflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="seizure_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">THYROID DISORDER : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt20 != undefined">
                        <input type="radio" id="radio-thirtynine" (click)="changeAllery('thyroid',1,'yes')" />
                        <label for="radio-thirtynine" [ngClass]="ynopt20 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-fourty" (click)="changeAllery('thyroid',1,'no')" />
                        <label for="radio-fourty" [ngClass]="!ynopt20 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="thyroidflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="thyroid_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">JAUNDICE : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt21 != undefined">
                        <input type="radio" id="radio-fourtyone" (click)="changeAllery('jaundice',1,'yes')" />
                        <label for="radio-fourtyone" [ngClass]="ynopt21 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-fourtytwo" (click)="changeAllery('jaundice',1,'no')" />
                        <label for="radio-fourtytwo" [ngClass]="!ynopt21 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="jaundiceflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="jaundice_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">SURGICAL DISORDER : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt22 != undefined">
                        <input type="radio" id="radio-fourtythree" (click)="changeAllery('surgical',1,'yes')" />
                        <label for="radio-fourtythree" [ngClass]="ynopt22 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-fourtyfour" (click)="changeAllery('surgical',1,'no')" />
                        <label for="radio-fourtyfour" [ngClass]="!ynopt22 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="surgicalflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="surgical_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div> -->
                <div class="col-4 paddingcheck">
                  <div class="row">
                    <div class="col-4 width">
                      <mat-label class="matlabel" style="font-weight: 600;">Others : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt7 != undefined">
                        <input type="radio" id="radio-seventeen" (click)="changeAllery('Others',1,'yes')" />
                        <label for="radio-seventeen" [ngClass]="ynopt7 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-eighteen" (click)="changeAllery('Others',1,'no')" />
                        <label for="radio-eighteen" [ngClass]="!ynopt7 ? 'radio_active':'radio_inactive'">No</label>
                      </div>

                    </div>
                    <div class="col-4">

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="otherflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="Others_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- habitual -->
          <div class="row" style="padding: 0 12px;">
            <div class="cover_div" style="margin-top: 10px;">
              <div class="header_lable">
                Habitual
              </div>
              <div class="content_cover">
                <div class="row" style="margin-top: 20px;">
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Alcohol
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <div class="vl"></div>
                          <mat-expansion-panel [expanded]="panelOpenState1" hideToggle [(ngModel)]="toggAlchol"
                            (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false"
                            class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState1 ? '../../../assets/ui_icons/alcohol_red.svg' : '../../../assets/ui_icons/alcohol.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text">Quantity
                                  <select class="sdropdown" ngDefaultControl [(ngModel)]="Alc_Quant">
                                    <option disabled value="Select">Select</option>
                                    <option value={{quan.description}} *ngFor="let quan of Quantity_array">
                                      {{quan.description}}</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Mixing Type
                                  <select class="sdropdown" [(ngModel)]="Alc_Mix">
                                    <option disabled value="Select">Select</option>
                                    <option value={{Mix.description}} *ngFor="let Mix of Mixing_array">
                                      {{Mix.description}}
                                    </option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Duration
                                  <select class="sdropdown" [(ngModel)]="alchol_duration">
                                    <option value="&lt;1 Year">&lt;1 Year</option>
                                    <option value="1 - 2 Years"> 1 - 2 Years</option>
                                    <option value="3 - 5 Years"> 3 - 5 Years</option>
                                    <!-- <option value="&lt;5 Years">&lt;5 Years</option> -->
                                    <option value=">5 Years"> >5 Years</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text" style="margin-bottom: 0;">Freqency/day
                                  <select class="sdropdown" [(ngModel)]="alcohol_frequency">
                                    <option value=1>1</option>
                                    <option value="2"> 2</option>
                                    <option value="3"> 3</option>
                                    <option value="4"> 4</option>
                                    <option value=">5 to 10">>5 to 10</option>
                                    <option value="10+">10+</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Smoke
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <div class="vl"></div>
                          <mat-expansion-panel [expanded]="panelOpenState2" hideToggle
                            (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false"
                            class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState2 ? '../../../assets/ui_icons/smoke_red.svg' : '../../../assets/ui_icons/smoke.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text">Duration
                                  <select class="sdropdown" [(ngModel)]="smoke_duration">
                                    <option value="&lt;1 Year"> &lt;1 Year</option>
                                    <option value="1 - 2 Years"> 1 - 2 Years</option>
                                    <option value="3 - 5 Years"> 3 - 5 Years</option>
                                    <!-- <option value="&lt;5 Years "> &lt;5 Years </option> -->
                                    <option value="&gt;5 Years">&gt;5 Years </option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Freqency/day(times)
                                  <select class="sdropdown" [(ngModel)]="smoke_frequency">
                                    <option value=1>1</option>
                                    <option value="2"> 2</option>
                                    <option value="3"> 3</option>
                                    <option value="4"> 4</option>
                                    <option value=">5 to 10">>5 to 10</option>
                                    <option value="10+">10+</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Gutka
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <div class="vl"></div>
                          <mat-expansion-panel hideToggle [expanded]="panelOpenState3"
                            (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false"
                            class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState3 ? '../../../assets/ui_icons/gutkha_red.svg' : '../../../assets/ui_icons/gutkha.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text">Duration
                                  <select class="sdropdown" [(ngModel)]="gutka_duration">
                                    <option value="&lt;1 Year">&lt;1 Year</option>
                                    <option value="1 - 2 Years"> 1 - 2 Years</option>
                                    <option value="3 - 5 Years"> 3 - 5 Years</option>
                                    <!-- <option value="&lt;5 Years ">&lt;5 Years </option> -->
                                    <option value="&gt;5 Years">&gt;5 Years</option>

                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Freqency/day (Times)
                                  <select class="sdropdown" [(ngModel)]="gutka_frequency">
                                    <option value=1>1</option>
                                    <option value="2"> 2</option>
                                    <option value="3"> 3</option>
                                    <option value="4"> 4</option>
                                    <option value=">5 to 10">>5 to 10</option>
                                    <option value="10+">10+</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Exercise
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <div class="vl"></div>
                          <mat-expansion-panel hideToggle [expanded]="panelOpenState4"
                            (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false"
                            class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState4 ? '../../../assets/ui_icons/exercise_red.svg' : '../../../assets/ui_icons/exercise.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text">Exercise
                                  <select class="sdropdown" [(ngModel)]="life_exer"
                                    (ngModelChange)="Exercise_change($event)" disableOptionCentering>
                                    <option disabled value="Select">Select</option>
                                    <option *ngFor="let exercise of Physcial_exercise" value={{exercise.description}}>
                                      {{exercise.description}}</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" *ngIf="!yoga_div">
                                  <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;">
                                    <table style="margin-top: -5px;">
                                      <tr *ngFor="let yoga of Get_theyoga">
                                        <td class="test">
                                          <mat-checkbox multiple
                                            [checked]="yoga_check.indexOf(yoga.yoga_asana_id) > -1"
                                            (change)="yoga_click(yoga.yoga_asana_id,$event.checked);">
                                          </mat-checkbox>
                                        </td>
                                        <td style="text-align: left !important;text-align: left !important;                                    
                                            color: black;
                                            width: -webkit-fit-content;
                                            width: -moz-fit-content;
                                            width: fit-content;
                                            font-size: 12px !important;
                                            font-family: Arial;
                                            font-weight: 400;
                                            letter-spacing: 0.5px;">
                                          <span>{{yoga.description}}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </mat-label>
                                <!-- <mat-label class="col-12" class="hab_conter_text" *ngIf="!yoga_div"><br>
                                    <select class="sdropdown" disableOptionCentering multiple >
                                      <option disabled value="Select">Select</option>
                                      <option #matoption color="primary" (click)="yoga_click(yoga.yoga_asana_id,matoption)"
                                          *ngFor="let yoga of Get_theyoga" value={{yoga.description}}>
                                          {{yoga.description}}
                                      </option>
                                    </select>
                                </mat-label> -->

                                <mat-label class="col-12" class="hab_conter_text">Duration<br>
                                  <select class="sdropdown" [(ngModel)]="life_exerdure" disableOptionCentering>
                                    <option disabled value="Select">Select</option>
                                    <option value="10 mins">10 mins</option>
                                    <option value="15 mins">15 mins</option>
                                    <option value="30 mins">30 mins</option>
                                    <option value="45 mins">45 mins</option>
                                  </select>
                                </mat-label>
                                <!-- <mat-label class="col-12" class="hab_conter_text">Frequency<br>
                                    <select class="sdropdown" [(ngModel)]="life_exe_freq" 
                                      disableOptionCentering >
                                      <option disabled value="Select">Select</option>
                                      <option value="Daily">Daily</option>
                                      <option value="Thrice a week">Thrice a week</option>
                                      <option value="4 days a week">4 days a week</option>
                                      <option value="5 days a week">5 days a week</option>
                                    </select>
                                </mat-label> -->
                                <mat-label class="col-12" class="hab_conter_text">Exercise routine<br>
                                  <select class="sdropdown" [(ngModel)]="exer_run" disableOptionCentering>
                                    <option disabled value="Select">Select</option>
                                    <option *ngFor="let routine of exercise_routine_arry"
                                      value="{{routine.description}}">
                                      {{routine.description}}</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- diet text editor -->
          <div class="row" style="margin-top: 15px;">
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <mat-label class="matlabel"> <b class="label_bold">Diet<span class="required-marker">*</span></b>
                <quill-editor id="family" [(ngModel)]="diet_data" [styles]="{height: 'calc(35vh - 100px)'}"
                  [modules]="modules" placeholder="Enter Diet" (onContentChanged)="changediet($event)">
                </quill-editor>
                <div>
                  <!-- Preview -->
                  <quill-view-html hidden id="family" [content]="diet_data"></quill-view-html>
                </div>
              </mat-label>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <mat-label class="matlabel"> <b class="label_bold">General examination</b>
                <quill-editor id="family" [(ngModel)]="generalexamination" [styles]="{height: 'calc(35vh - 100px)'}"
                  [modules]="modules" placeholder="Enter General examination" (onContentChanged)="changegen($event)">
                </quill-editor>
                <div>
                  <!-- Preview -->
                  <quill-view-html hidden id="family" [content]="generalexamination"></quill-view-html>
                </div>
              </mat-label>
            </div>
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel">
                    <b class="label_bold">Diet</b>
                  </mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="family" [(ngModel)]="diet_data" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter Diet" (onContentChanged)="changediet($event)">
                  </quill-editor>
                  <div>-->
            <!-- Preview -->
            <!-- <quill-view-html hidden id="family" [content]="diet_data"></quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel">
                    <b class="label_bold">General examination</b>
                  </mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="family" [(ngModel)]="generalexamination" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter General examination"
                    (onContentChanged)="changegen($event)">
                  </quill-editor>
                  <div>-->
            <!-- Preview -->
            <!-- <quill-view-html hidden id="family" [content]="generalexamination"></quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- allergies -->
          <div class="col-12 col-sm-12" style="margin-top: 15px">
            <div class="row">
              <div class="col-2">
                <mat-label class="matlabel" style="font-weight: 600;">Allergies :
                </mat-label>
              </div>
              <div class="col-6">
                <div class="switch-field" *ngIf="ynopt8 != undefined">
                  <input type="radio" id="radio-thirty" (click)="changeAllery1('main',1,'yes')" />
                  <label for="radio-thirty" [ngClass]="ynopt8 ? 'radio_active':'radio_inactive'">Yes</label>
                  <input type="radio" id="radio-thirtyone" (click)="changeAllery1('main',1,'no')" />
                  <label for="radio-thirtyone" [ngClass]="!ynopt8 ? 'radio_active':'radio_inactive'">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="margin-top: 5px !important;">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                *ngIf="allergyFlag">
                <div class="row">
                  <div class="col-6">
                    <mat-label class="matlabel" style="font-weight: 600;">Drug
                      allergy : </mat-label>
                  </div>
                  <div class="col-6">
                    <div class="switch-field" *ngIf="ynopt9 != undefined">
                      <input type="radio" id="radio-thirtytwo" (click)="changeAllery1('drug',1,'yes')" />
                      <label for="radio-thirtytwo" [ngClass]="ynopt9 ? 'radio_active':'radio_inactive'">Yes</label>
                      <input type="radio" id="radio-thirtythree" (click)="changeAllery1('drug',1,'no')" />
                      <label for="radio-thirtythree" [ngClass]="!ynopt9 ? 'radio_active':'radio_inactive'">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                *ngIf="drugDetailsFlag">
                <mat-label class="matlabel">Details
                  <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="drug_details" matInput></textarea>
                </mat-label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                *ngIf="allergyFlag">
                <div class="row">
                  <div class="col-6">
                    <mat-label class="matlabel" style="font-weight: 600;">
                      Environmental allergy : </mat-label>
                  </div>
                  <div class="col-6">
                    <div class="switch-field" *ngIf="ynopt10 != undefined">
                      <input type="radio" id="radio-thirtyfour" (click)="changeAllery1('evn',1,'yes')" />
                      <label for="radio-thirtyfour" [ngClass]="ynopt10 ? 'radio_active':'radio_inactive'">Yes</label>
                      <input type="radio" id="radio-thirtyfive" (click)="changeAllery1('evn',1,'no')" />
                      <label for="radio-thirtyfive" [ngClass]="!ynopt10 ? 'radio_active':'radio_inactive'">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                *ngIf="engDetailsFlag && allergyFlag">
                <mat-label class="matlabel">Details
                  <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="env_details" matInput></textarea>
                </mat-label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                *ngIf="allergyFlag">
                <div class="row">
                  <div class="col-6">
                    <mat-label class="matlabel" style="font-weight: 600;">Food
                      allergy : </mat-label>
                  </div>
                  <div class="col-6">
                    <div class="switch-field" *ngIf="ynopt11 != undefined">
                      <input type="radio" id="radio-thirtysix" (click)="changeAllery1('food',1,'yes')" />
                      <label for="radio-thirtysix" [ngClass]="ynopt11 ? 'radio_active':'radio_inactive'">Yes</label>
                      <input type="radio" id="radio-thirtyseven" (click)="changeAllery1('food',1,'no')" />
                      <label for="radio-thirtyseven" [ngClass]="!ynopt11 ? 'radio_active':'radio_inactive'">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                *ngIf="foodDetailsFlag && allergyFlag">
                <mat-label class="matlabel">Details
                  <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="food_details" matInput></textarea>
                </mat-label>
              </div>
            </div>
          </div>
          <!-- Family history -->

          <div class="row" style="padding: 0 12px;">
            <div class="cover_div" style="margin-top: 25px;">
              <div class="header_lable">
                Vitals & Systemic Examination
              </div>
              <div class="content_cover">
                <div class="container  p-0"
                  style="margin-top: 2px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                  <div class="row">
                    <p><span style="font-weight: 550;">Vitals :</span></p>
                  </div>

                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Height
                            <input class="ipcss " (change)="calculateCalories()" [(ngModel)]="height"
                              onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46" />
                            <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput -->
                          </mat-label>
                        </div>


                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="hmeasure" (change)="calculateCalories()">
                              <option value="cms">cms</option>
                              <option value="inch">inch</option>
                            </select>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Weight<br>
                            <input class="ipcss widthch1" (change)="calculateCalories()" [(ngModel)]="weight"
                              onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                              matInput />
                            <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="wmeasure" (change)="calculateCalories()">
                              <option value="kgs">kgs</option>
                              <option value="lbs">lbs</option>
                            </select>
                          </mat-label>
                        </div>
                      </div>
                    </div>

                    <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->

                    <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">BMI
                        <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                      </mat-label>
                    </div>


                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1">
                      <mat-label class="matlabel">BMR<br>
                        <input class="ipcss " [(ngModel)]="bmr" disabled matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="bloodPressureFlag">
                      <mat-label class="matlabel">Blood pressure (mm Hg)</mat-label>
                      <div class="row" [hidden]="bloodPressureFlag">
                        <div class=" col-6" [hidden]="bloodPressureFlag">
                          <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                            maxlength="3" [(ngModel)]="BP01" (keyup)="updateUserprofile()"
                            [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="sistol(BP01)">
                        </div>
                        <div class="col-6" [hidden]="bloodPressureFlag">
                          <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                            maxlength="3" [(ngModel)]="BP02" (keyup)="updateUserprofile()"
                            [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="diostol(BP02)">
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <div class="row">
                        <div class="col-12">
                          <mat-label class="matlabel">PR (min)<br>
                            <input matInput class="ipcss " maxlength="25" placeholder="PR" [(ngModel)]="pr_txt"
                              (input)="pulsevalue(pr_txt)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                          </mat-label>
                        </div>
                        <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                          <span style="display:inline-block">/</span><span style="display:inline-block">min</span>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Temperature (°F)</mat-label>
                      <div class="row">
                        <div class="col-12">
                          <input type="number" matInput class="ipcss noappt_width"
                            [ngStyle]="{'color': colorstyle ? 'red' : 'black'}" [(ngModel)]="temperature"
                            (keyup)="updateUserprofile()" (input)="tempcolor(temperature)">

                        </div>
                        <!-- <div class="col-4">
                          <mat-label class="matlabel"
                            style="margin-left: -10px !important;position: relative;top: 7px;">°F</mat-label> -->
                          <!-- <select [(ngModel)]="temp_read" class="ipcss widthappt"
                            (ngModelChange)="chnagetemp($event)">
                            <option value="°C">°C</option>
                            <option value="F">F</option>
                          </select> -->

                        <!-- </div> -->
                      </div>
                    </div>
                   
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <div class="row">
                        <div class="col-12">
                          <mat-label class="matlabel">RR (min)<br>
                            <input matInput class="ipcss " maxlength="25" placeholder="RR" [(ngModel)]="rr_txt"
                              (input)="rrvalue(rr_txt)" [ngStyle]="{'color': rrcolorstyle ? 'red' : 'black'}">
                          </mat-label>
                        </div>
                        <!-- <div class="col-4" style="padding: 0px;">
                          <span style="display:inline-block">/</span><span style="display:inline-block">min</span>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <div class="row">
                        <div class="col-12">
                          <mat-label class="matlabel">Spo2 (%)<br>
                            <input matInput class="ipcss " maxlength="25" placeholder="Spo2" [(ngModel)]="spo2"
                              (input)="spo2value(spo2)" [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                          </mat-label>
                        </div>
                        <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                          <span>%</span>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                      <div class="row">
                        <div class="col-12">
                          <mat-label class="matlabel">CBG (mg/dl)<br>
                            <input matInput class="ipcss " maxlength="25" placeholder="CBG" [(ngModel)]="cbg_txt"
                              (input)="cbgvalue(cbg_txt)" [ngStyle]="{'color': cbgcolorstyle ? 'red' : 'black'}">
                          </mat-label>
                        </div>
                        <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                          <span> mg/dl</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container  p-0"
                  style="margin-top: 11px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                  <div class="row">
                    <p><span style="font-weight: 550;">Systemic Examination :</span></p>
                  </div>

                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">CVS<br>
                        <input matInput class="ipcss " placeholder="CVS" [(ngModel)]="cvs_txt">
                      </mat-label>
                    </div>
                    <!-- newly added rs key -->

                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">RS<br>
                        <input matInput class="ipcss " maxlength="25" placeholder="RS" [(ngModel)]="rs_text">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Abdomen<br>
                        <input matInput class="ipcss " placeholder="ABD" maxlength="25" [(ngModel)]="abd_txt">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">CNS<br>
                        <input matInput class="ipcss " maxlength="25" placeholder="CNS" [(ngModel)]="cns_txt">
                      </mat-label>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <div class="row">
                        <div class="col-8">
                          <mat-label class="matlabel">Pulse<br>
                            <input matInput class="ipcss " placeholder="pulse" [(ngModel)]="pulse"
                              (input)="pulsevalue(pulse)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                          </mat-label>
                        </div>
                        <div class="col-4" style="margin-top:26px;padding: 0px;">
                          <span> bpm</span>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-3">
                      <mat-label class="matlabel">&nbsp;<br>
                        <img src="../../../assets/ui_icons/buttons/add_button.svg" class="add_button btn-outline-success rounded"
                          (click)="addVitals('data')" />
                      </mat-label>
                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4"
                    *ngIf="vitals_List.length != 0">
                    <table>
                      <thead>
                        <tr>
                          <th style="text-align: left;">S.No</th>
                          <th style="text-align: left;">Height</th>
                          <th style="text-align: left;">Weight</th>
                          <th style="text-align: left;">BMI</th>
                          <th style="text-align: left;">Bmr</th>
                          <th style="text-align: left;">BP (mm Hg)</th>
                          <th style="text-align: left;">PR (bpm)</th>
                          <th style="text-align: left;">Temp (°F)</th>
                          <!-- <th style="text-align: left;">Pulse</th> -->
                          <th style="text-align: left;">RR (bpm)</th>
                          <th style="text-align: left;">SPO2 (%)</th>
                          <th style="text-align: left;">CBG (mg/dl)</th>
                          <th style="text-align: left;">CVS</th>
                          <th style="text-align: left;">RS</th>
                          <th style="text-align: left;">ABD</th>
                          <th style="text-align: left;">CNS</th>


                          <th style="text-align: left;">Date & time</th>
                          <!-- <th style="text-align: left;">Time</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let vital of vitals_List">
                          <td style="text-align: left;">{{vital.sNo}}</td>
                          <td style="text-align: left;">{{vital.height}}</td>
                          <td style="text-align: left;">{{vital.weight}}</td>
                          <td style="text-align: left;">{{vital.bmi}}</td>
                          <td style="text-align: left;">{{vital.bmr}}</td>
                          <td style="text-align: left;">
                            <span [style.color]="vital.is_bpAbnormal ? 'red' : 'black'">
                              {{ vital.bloodPressurekey }}
                            </span>
                          </td>
                          <td style="text-align: left;"><span
                              [style.color]="vital.is_pulseRateAbnormal ? 'red' : 'black'">{{vital.PR}}</span></td>
                          <td style="text-align: left;">
                            <span [style.color]="vital.is_tempAbnormal ? 'red' : 'black'">{{vital.temperature}}</span>
                          </td>
                          <!-- <td style="text-align: left;">
                            {{vital.pulse}}</td> -->
                          <td style="text-align: left;">
                            <span
                              [style.color]="vital.is_respiratoryRateAbnormal ? 'red' : 'black'">{{vital.RR}}</span>
                          </td>

                          <td style="text-align: left;">
                            <span [style.color]="vital.is_spo2Abnormal ? 'red' : 'black'">{{vital.SPO2}}</span>
                          </td>
                          <td style="text-align: left;"><span
                              [style.color]="vital.is_cbgAbnormal ? 'red' : 'black'">{{vital.cbg_txt}}</span></td>
                          <td style="text-align: left;">{{vital.Cvs}}</td>
                          <td style="text-align: left;">{{vital.Rs}}</td>
                          <td style="text-align: left;">{{vital.ABD}}</td>
                          <td style="text-align: left;">{{vital.Cns}}</td>

                          <!-- <td style="text-align: left;">{{vital.cbd_txt}}</td> -->
                          <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                          <!-- <td style="text-align: left;">{{vital.time}}</td> -->
                        </tr>
                      </tbody>
                    </table>



                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  systemic ex -->

          <div class="row" style="margin-top: 15px;">
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel"> <b class="label_bold">Local examination</b></mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="editor1" [(ngModel)]="local_examination" [maxLength]="10"
                    [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules" placeholder="Enter Local examination"
                    (onContentChanged)="changeexamination($event)">
                  </quill-editor>
                  <div>
                    <!-- Preview -->
                    <quill-view-html hidden id="local_examination" [content]="local_examination" [maxLength]="10">
                    </quill-view-html>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel"><b class="label_bold">Local examination</b></mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="editor1" [(ngModel)]="local_examination" [maxLength]="10"
                    [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                    placeholder="Enter Local examination" (onContentChanged)="changeexamination($event)">
                  </quill-editor>
                  <div>-->
            <!-- Preview -->
            <!-- <quill-view-html hidden id="local_examination" [content]="local_examination" [maxLength]="10">
                    </quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel"> <b class="label_bold">Provisional diagnosis</b></mat-label>
                </div>  
                <div class="col-12">
                  <quill-editor id="editor1" [(ngModel)]="prov_diagnosis" [maxLength]="10"
                    [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                    placeholder="Enter Provisional diagnosis" (onContentChanged)="changeprov($event)">
                  </quill-editor>
                  <div>
                    <!-- Preview -->
                    <quill-view-html hidden id="local_examination" [content]="prov_diagnosis" [maxLength]="10">
                    </quill-view-html>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel"> <b class="label_bold">Line of management</b></mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="line_mgmt" [(ngModel)]="line_mgmt" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter Line of managment"
                    (onContentChanged)="changelinemgmt3($event)">
                  </quill-editor>
                  <div>
                    <!-- Preview -->
                    <quill-view-html hidden id="line_mgmt" [content]="line_mgmt"></quill-view-html>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <div class="cover_div">
                <div class="header_lable">
                  Assign
                </div>
                <div class="content_cover">
                  <div class="row">

                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel">Specialization<br>
                        <select id="mandatory_specfication" class="ipcss widthappt" [(ngModel)]="splName"
                          (change)="getDoctorList(splName)">
                          <option *ngFor="let spl of splList" value="{{spl.spl_name}}">{{spl.spl_name}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel">Doctor<br>
                        <select class="ipcss widthappt" [(ngModel)]="doctorname"
                          (change)="getDoctorDetails(doctorname)">
                          <option *ngFor="let doctor of doctorList" value="{{doctor.docname}}">{{doctor.docname}}
                          </option>
                        </select>
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel"><b class="label_bold">Provisional diagnosis</b></mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="editor1" [(ngModel)]="prov_diagnosis" [maxLength]="10"
                    [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                    placeholder="Enter Provisional diagnosis" (onContentChanged)="changeprov($event)">
                  </quill-editor>
                  <div>-->
            <!-- Preview -->
            <!-- <quill-view-html hidden id="local_examination" [content]="prov_diagnosis" [maxLength]="10">
                    </quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
              <div class="row">
                <div class="col-12 margin_10">
                  <mat-label class="matlabel">
                    <b class="label_bold">Line of managment</b>
                  </mat-label>
                </div>
                <div class="col-12">
                  <quill-editor id="line_mgmt" [(ngModel)]="line_mgmt" [styles]="{height: 'calc(35vh - 100px)'}"
                    [modules]="modules" placeholder="Enter Line of managment"
                    (onContentChanged)="changelinemgmt3($event)">
                  </quill-editor>
                  <div>-->
            <!-- Preview -->
            <!-- <quill-view-html hidden id="line_mgmt" [content]="line_mgmt"></quill-view-html>
                  </div>
                </div>
              </div>
            </div> -->

          <!-- <div class="row">
            <div class="col-6">
              <div class="cover_div">
                <div class="header_lable">
                  Assign
                </div>
                <div class="content_cover">
                  <div class="row">

                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel">Specialization<br>
                        <select id="mandatory_specfication" class="ipcss widthappt" [(ngModel)]="splName"
                          (change)="getDoctorList(splName)">
                          <option *ngFor="let spl of splList" value="{{spl.spl_name}}">{{spl.spl_name}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel">Doctor<br>
                        <select class="ipcss widthappt" [(ngModel)]="doctorname" 
                          (change)="getDoctorDetails(doctorname)">
                          <option *ngFor="let doctor of doctorList" value="{{doctor.docname}}">{{doctor.docname}}
                          </option>
                        </select>
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div [hidden]="true">
        <div id="assessmentPrint">
          <table style="border:'0';width:100%">              
            <thead>
              <tr>
                <th style="width:100%;">                                 
                  <div>
                    <table style="width: 100%;">
                      <tr>
                        <td style="text-align: center;">
                          <p style="font-size: 24px;"><strong style="font-size: 24px !important">{{hospital_name}}</strong></p>
                          <p>{{hospitalAddress}} {{Hospital_location}}</p>
                          <p><img src="../../../assets/img/phone.png" width="15px" height="15px" />&nbsp; {{hosp_tel}}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
      
                  <div style="margin-left: 35px;">
                    <p style="width: 211px !important;
                              padding: 1px !important;
                              background: #eaeaec !important;
                              display: inline-flex !important;
                              position: relative !important;
                              padding-left: 5px !important;
                              border: 1px solid #b5b5b6;
                              top: 16px !important;
                              border-radius: 5px 5px 5px 0;">
                      <strong>Personal information</strong>
                    </p>
                  </div>
                  <div style="border: 1px solid #b5b5b6; width: 90%;margin-left: 35px;border-radius: 5px;">
                    <table style="margin-top: 5px; text-align: left !important;">
                      <tr>
                        <td class="align_left"><strong>MR No :</strong> {{mrnno}} </td>
                      </tr>
                      <tr style="background-color: #fff;">
                        <td class="align_left"><strong>Name :</strong> {{patient_name}} </td>
                      </tr>
                      <tr>
                        <td class="align_left"><strong>Age/Gender :</strong> {{patient_age}} / {{patient_gender}} </td>
                      </tr>
                      <tr>
                        <td class="align_left"><strong>Address :</strong> {{clntAddress1}} </td>
                      </tr>
                      <tr>
                        <td class="align_left"><strong>Doctor :</strong> {{doctor_name}} </td>
                      </tr>
                      <tr style="background-color: #fff;">
                        <td class="align_left" style="display: flex;align-items: center;color: #000;height: 15px;">
                          <span><strong>Complaints :</strong></span>&nbsp;
                          <span [innerHtml]="chief_comp"></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="align_left"><strong></strong>
                          <img style="width: 130px; height: 35px;margin: 5px;" src={{barcode}} />
                        </td>
                      </tr>
                    </table>
                  </div>           
                </th>
              </tr>
            </thead>
            <tbody>  
              <tr>  
                <td width="100%" style="margin-top: 20px;">  
                  <div style="margin-left: 35px;">
                    <p style="width: 211px !important;
                              padding: 1px !important;
                              background: #eaeaec !important;
                              display: inline-flex !important;
                              position: relative !important;
                              padding-left: 5px !important;
                              border: 1px solid #b5b5b6;
                              top: 16px !important;
                              border-radius: 5px 5px 5px 0;">
                      <strong>Observation</strong>
                    </p>
                  </div>
                  <div style="border: 1px solid #b5b5b6; width: 90%; margin-left: 35px;border-radius: 5px;">
                    <div class="rowCover" style="display: inline-flex !important; flex-direction: column;">
                      <div *ngIf="this.chief_comp != undefined && this.chief_comp != ''" class="vitalLable">
                        <strong>Complaints :</strong>&nbsp;<span [innerHtml]="chief_comp"></span>
                      </div>
                      <div *ngIf="this.pres_ill != undefined && this.pres_ill != ''" class="vitalLable">
                        <strong>Present illness :</strong>&nbsp;<span [innerHtml]="pres_ill"></span>
                      </div>
                      <div *ngIf="this.med_hist != undefined && this.med_hist != ''" class="vitalLable">
                        <strong>Past medical history :</strong>&nbsp;<span [innerHtml]="med_hist"></span>
                      </div>
                      <div *ngIf="this.surgicalvalue != undefined && this.surgicalvalue != ''" class="vitalLable">
                        <strong>Surgical history :</strong>&nbsp;<span [innerHtml]="surgicalvalue"></span>
                      </div>
                      <div *ngIf="this.family_history != undefined && this.family_history != ''" class="vitalLable">
                        <strong>Family history :</strong>&nbsp;<span [innerHtml]="family_history"></span>
                      </div>
                      <div *ngIf="this.diabdetials != undefined && this.diabdetials != ''" class="vitalLable">
                        <strong>Diabetes :</strong>&nbsp;<span [innerHtml]="diabdetials"></span>
                      </div>
                      <div *ngIf="this.hype_details != undefined && this.hype_details != ''" class="vitalLable">
                        <strong>Hypertension :</strong>&nbsp;<span [innerHtml]="hype_details"></span>
                      </div>
                      <div *ngIf="this.ckddetails != undefined && this.ckddetails != ''" class="vitalLable">
                        <strong>CKD :</strong>&nbsp;<span [innerHtml]="ckddetails"></span>
                      </div>
                      <div *ngIf="this.caddetails != undefined && this.caddetails != ''" class="vitalLable">
                        <strong>CAD :</strong>&nbsp;<span [innerHtml]="caddetails"></span>
                      </div>
                      <div *ngIf="this.cvd_details != undefined && this.cvd_details != ''" class="vitalLable">
                        <strong>CVD :</strong>&nbsp;<span [innerHtml]="cvd_details"></span>
                      </div>
                      <div *ngIf="this.tuber_details != undefined && this.tuber_details != ''" class="vitalLable">
                        <strong>Tuberculosis :</strong>&nbsp;<span [innerHtml]="tuber_details"></span>
                      </div>
                      <div *ngIf="this.Others_details != undefined && this.Others_details != ''" class="vitalLable">
                        <strong>Others :</strong>&nbsp;<span [innerHtml]="Others_details"></span>
                      </div>
                      <div *ngIf="this.diet_data != undefined && this.diet_data != ''" class="vitalLable">
                        <strong>Diet :</strong>&nbsp;<span [innerHtml]="diet_data"></span>
                      </div>
                      <div *ngIf="this.generalexamination != undefined && this.generalexamination != ''" class="vitalLable">
                        <strong>General examination :</strong>&nbsp;<span [innerHtml]="generalexamination"></span>
                      </div>
                      <div *ngIf="this.local_examination != undefined && this.local_examination != ''" class="vitalLable">
                        <strong>Local examination :</strong>&nbsp;<span [innerHtml]="local_examination"></span>
                      </div>
                      <div *ngIf="this.prov_diagnosis != undefined && this.prov_diagnosis != ''" class="vitalLable">
                        <strong>Provisional diagnosis :</strong>&nbsp;<span [innerHtml]="prov_diagnosis"></span>
                      </div>
                      <div *ngIf="this.line_mgmt != undefined && this.line_mgmt != ''" class="vitalLable">
                        <strong>Line of managment :</strong>&nbsp;<span [innerHtml]="line_mgmt"></span>
                      </div>
                    </div>
                  </div>
      
                  <div *ngIf="allergyFlag" style="margin-left: 35px;">
                    <p style="width: 211px !important;
                              padding: 1px !important;
                              background: #eaeaec !important;
                              display: inline-flex !important;
                              position: relative !important;
                              padding-left: 5px !important;
                              border: 1px solid #b5b5b6;
                              top: 16px !important;
                              border-radius: 5px 5px 5px 0;">
                      <strong>Allergies</strong>
                    </p>
                  </div>
                  <div *ngIf="allergyFlag" style="border: 1px solid #b5b5b6; width: 90%; margin-left: 35px;border-radius: 5px;">
                    <div class="rowCover" style="display: inline-flex !important; flex-direction: column;">
                      <div *ngIf="drugDetailsFlag && drug_details != ''" class="vitalLable">
                        <strong>Drug allergy :</strong>&nbsp;<span [innerHtml]="drug_details"></span>
                      </div>
                      <div *ngIf="engDetailsFlag && env_details != ''" class="vitalLable">
                        <strong>Environmental allergy :</strong>&nbsp;<span [innerHtml]="env_details"></span>
                      </div>
                      <div *ngIf="foodDetailsFlag && food_details != ''" class="vitalLable">
                        <strong>Food allergy :</strong>&nbsp;<span [innerHtml]="food_details"></span>
                      </div>
                    </div>
                  </div>
      
                  <div style="margin-left: 35px;" *ngIf="vitals_List != undefined && vitals_List.length != 0">
                    <p style="width: 211px !important;
                              padding: 1px !important;
                              background: #eaeaec !important;
                              display: inline-flex !important;
                              position: relative !important;
                              padding-left: 5px !important;
                              border: 1px solid #b5b5b6;
                              top: 16px !important;
                              border-radius: 5px 5px 5px 0;">
                      <strong>Vitals </strong>
                    </p>
                  </div>
                  <div *ngIf="vitals_List != undefined && vitals_List.length != 0" style="border: 1px solid #b5b5b6; width: 90%;margin-left: 35px;border-radius: 5px;">
                    <div class="rowCover" style="display: inline-flex !important;width:100% !important;">
                      <table *ngIf="vitals_List.length != 0" id="" class="table table-hover table-dynamic" cellpadding="5"
                        cellspacing="0" style="border: 1px solid #000; width: 100%;">
                        <thead>
                          <tr style="text-align: center;">
                            <th style="text-align: center;">S.No</th>
                            <th style="text-align: center;">Height</th>
                            <th style="text-align: center;">Weight</th>
                            <th style="text-align: center;">BMI</th>
                            <th style="text-align: center;">Temp (°F)</th>
                            <th style="text-align: center;">BP (mm Hg)</th>
                            <th style="text-align: center;">Date</th>
                            <th style="text-align: left;">Time</th> 
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let vital of vitals_List">
                            <td style="text-align: left;">{{vital.sNo}}</td>
                            <td style="text-align: left;">{{vital.height}}</td>
                            <td style="text-align: left;">{{vital.weight}}</td>
                            <td style="text-align: left;">{{vital.bmi}}</td>
                            <td style="text-align: left;">{{vital.temperature}}</td>
                            <td style="text-align: left;">{{vital.bloodPressure}}</td>
                            <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                            <td style="text-align: left;">{{vital.time}}</td> 
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>                     
                </td>
              </tr>
            </tbody>
           </table>
        </div>
      </div>

      <div [hidden]="true" #printbanner id="printbanner">
        <div class="bill_border">
          <div>
            <div>
              <table style="width: 98%;margin-left: 15px !important;margin-right: 15px !important;">
                <thead>
                  <th>
                    <div style="height: 275px">
                      <div style="height: 110px;margin-right: 15px;">
                        <img alt="image" src={{hospital_logo}} width="98%" height="110px"
                          style="vertical-align: middle;">
                      </div>
                      <div style="margin-top: 5px; height: 162px;">
                        <table style="width: 98%; border: 1px solid black;border-radius: 10px">
                          <tr>
                            <td style="width: 58%; vertical-align: top;padding: 0px;">
                              <div>
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                        MR no</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{mrnno}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                        Patient name</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{patient_name}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                        Age / gender</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{patient_age}} / {{patient_gender}}</td>
                                    </tr>
                                  </tbody>
                                </table>   
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                        Doctor</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{doctor_name}}</td>
                                    </tr>
                                  </tbody>
                                </table>                                                               
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;vertical-align: top;">
                                        Address</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;vertical-align: top;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;"><span *ngIf="address != ''">{{address}}{{clntLocation}}</span>
                                        <br />
                                        <span>{{clntCity}}{{clntZipcode}}{{clntState}}{{clntCountry}}</span>                                         
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>                               
                              </div>
                            </td>
                            <td style="width: 42%;vertical-align: top;">                             
                              <div>                               
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                        Speciality</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{speclization_arry}}</td>
                                    </tr>
                                  </tbody>
                                </table> 
                                <table style="width: 100%;" class="head-table" *ngIf="chief_comp != undefined && chief_comp != ''">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                        Complaints</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;"><span [innerHtml]="chief_comp"></span></td>
                                    </tr>
                                  </tbody>
                                </table>                                                                
                                <img style="width: 130px; height: 35px; margin-top: 5px;padding-left: 10px;"
                                src={{barcode}} />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <div style="width: 98%">
                      <div> 
                        <p><strong>Observation</strong></p>
                        <div class="rowCover" style="display: inline-flex !important; flex-direction: column;padding-left: 5px;width: 100%;border: 1px solid black;border-radius: 5px;">
                          <div *ngIf="this.chief_comp != undefined && this.chief_comp != ''" class="vitalLable">
                            <strong>Complaints</strong>&nbsp;<br><span [innerHtml]="chief_comp" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.pres_ill != undefined && this.pres_ill != ''" class="vitalLable">
                            <strong>Present illness</strong>&nbsp;<br><span [innerHtml]="pres_ill" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.med_hist != undefined && this.med_hist != ''" class="vitalLable">
                            <strong>Past medical history</strong>&nbsp;<br><span [innerHtml]="med_hist" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.surgicalvalue != undefined && this.surgicalvalue != ''" class="vitalLable">
                            <strong>Surgical history</strong>&nbsp;<br><span [innerHtml]="surgicalvalue" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.family_history != undefined && this.family_history != ''" class="vitalLable">
                            <strong>Family history</strong>&nbsp;<br><span [innerHtml]="family_history" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.diabdetials != undefined && this.diabdetials != ''" class="vitalLable">
                            <strong>Diabetes</strong>&nbsp;<br><span [innerHtml]="diabdetials" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.hype_details != undefined && this.hype_details != ''" class="vitalLable">
                            <strong>Hypertension</strong>&nbsp;<br><span [innerHtml]="hype_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.ckddetails != undefined && this.ckddetails != ''" class="vitalLable">
                            <strong>CKD</strong>&nbsp;<br><span [innerHtml]="ckddetails" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.caddetails != undefined && this.caddetails != ''" class="vitalLable">
                            <strong>CAD</strong>&nbsp;<br><span [innerHtml]="caddetails" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.cvd_details != undefined && this.cvd_details != ''" class="vitalLable">
                            <strong>CVD</strong>&nbsp;<br><span [innerHtml]="cvd_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.tuber_details != undefined && this.tuber_details != ''" class="vitalLable">
                            <strong>Tuberculosis</strong>&nbsp;<br><span [innerHtml]="tuber_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.ba_details != undefined && this.ba_details != ''" class="vitalLable">
                            <strong>BA</strong>&nbsp;<br><span [innerHtml]="ba_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.ptb_details != undefined && this.ptb_details != ''" class="vitalLable">
                            <strong>PTB</strong>&nbsp;<br><span [innerHtml]="ptb_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.copd_details != undefined && this.copd_details != ''" class="vitalLable">
                            <strong>COPD :</strong>&nbsp;<br><span [innerHtml]="copd_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.apd_details != undefined && this.apd_details != ''" class="vitalLable">
                            <strong>APD</strong>&nbsp;<br><span [innerHtml]="apd_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.seizure_details != undefined && this.seizure_details != ''" class="vitalLable">
                            <strong>SEIZURE DISORDER</strong>&nbsp;<br><span [innerHtml]="seizure_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.thyroid_details != undefined && this.thyroid_details != ''" class="vitalLable">
                            <strong>THYROID DISORDER :</strong>&nbsp;<br><span [innerHtml]="thyroid_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.jaundice_details != undefined && this.jaundice_details != ''" class="vitalLable">
                            <strong>JAUNDICE</strong>&nbsp;<br><span [innerHtml]="jaundice_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.surgical_details != undefined && this.surgical_details != ''" class="vitalLable">
                            <strong>SURGICAL DISORDER</strong>&nbsp;<br><span [innerHtml]="surgical_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.Others_details != undefined && this.Others_details != ''" class="vitalLable">
                            <strong>Others</strong>&nbsp;<br><span [innerHtml]="Others_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="this.diet_data != undefined && this.diet_data != ''" class="vitalLable">
                            <strong>Diet</strong>&nbsp;<br><span [innerHtml]="diet_data" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.generalexamination != undefined && this.generalexamination != ''" class="vitalLable">
                            <strong>General examination</strong>&nbsp;<br><span [innerHtml]="generalexamination" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.local_examination != undefined && this.local_examination != ''" class="vitalLable">
                            <strong>Local examination</strong>&nbsp;<br><span [innerHtml]="local_examination" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.prov_diagnosis != undefined && this.prov_diagnosis != ''" class="vitalLable">
                            <strong>Provisional diagnosis</strong>&nbsp;<br><span [innerHtml]="prov_diagnosis" style="margin: 3px !important;"></span>
                          </div>
                          <div *ngIf="this.line_mgmt != undefined && this.line_mgmt != ''" class="vitalLable">
                            <strong>Line of managment</strong>&nbsp;<br><span [innerHtml]="line_mgmt" style="margin: 3px !important;"></span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="allergyFlag" style="margin-top: 15px;">
                        <p><strong>Allergies</strong></p>
                        <div class="rowCover" style="display: inline-flex !important; flex-direction: column;width: 100%;border: 1px solid black;border-radius: 5px;">
                          <div *ngIf="drugDetailsFlag && drug_details != ''" class="vitalLable">
                            <strong>Drug allergy</strong>&nbsp;<br><span [innerHtml]="drug_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="engDetailsFlag && env_details != ''" class="vitalLable">
                            <strong>Environmental allergy</strong>&nbsp;<br><span [innerHtml]="env_details" style="padding-left: 15px;"></span>
                          </div>
                          <div *ngIf="foodDetailsFlag && food_details != ''" class="vitalLable">
                            <strong>Food allergy</strong>&nbsp;<br><span [innerHtml]="food_details" style="padding-left: 15px;"></span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="vitals_List != undefined && vitals_List.length != 0" style="width: 100%;border-radius: 5px;margin-top: 15px;">
                        <p><strong>Vitals</strong></p>
                        <div class="rowCover" style="display: inline-flex !important;width:100% !important;">
                          <table *ngIf="vitals_List.length != 0" id="" class="table table-hover table-dynamic" cellpadding="5"
                            cellspacing="0" style="border: 1px solid #000;border-radius: 5px;width: 100%;">
                            <thead>
                              <tr style="text-align: center;">
                                <th style="text-align: center;">S.No</th>
                                <th style="text-align: center;">Height</th>
                                <th style="text-align: center;">Weight</th>
                                <th style="text-align: center;">BMI</th>
                                <th style="text-align: center;">Temp</th>
                                <th style="text-align: center;">BP</th>
                                <th style="text-align: center;">Date & time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let vital of vitals_List">
                                <td style="text-align: left;">{{vital.sNo}}</td>
                                <td style="text-align: left;">{{vital.height}}</td>
                                <td style="text-align: left;">{{vital.weight}}</td>
                                <td style="text-align: left;">{{vital.bmi}}</td>
                                <td style="text-align: left;">{{vital.temperature}}</td>
                                <td style="text-align: left;">{{vital.bloodPressure}}</td>
                                <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td width="100%">
                      <table style="border:'0';width:100%">
                        <tr>
                          <td style="width:100%;height: 50px;">&nbsp;</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div> 
      
      <div [hidden]="true" #printnoheader id="printnoheader">
        <div class="bill_border">
          <div>
            <div>
              <table style="width: 98%;margin-left: 15px !important;margin-right: 15px !important;">
                <thead>
                  <th>
                    <div style="margin-top: 5px; height: 142px;">
                      <table style="width: 98%; border: 1px solid black;border-radius: 10px">
                        <tr>
                          <td style="width: 58%; vertical-align: top;padding: 0px;">
                            <div>
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                      MR no</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{mrnno}}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                      Patient name</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{patient_name}}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                      Age / gender</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{patient_age}} / {{patient_gender}}</td>
                                  </tr>
                                </tbody>
                              </table>                                                                  
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                      Doctor</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{doctor_name}}</td>
                                  </tr>
                                </tbody>
                              </table>                                                                  
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;vertical-align: top;">
                                      Address</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;vertical-align: top;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;"><span *ngIf="address != ''">{{address}}{{clntLocation}}</span>
                                      <br />
                                      <span>{{clntCity}}{{clntZipcode}}{{clntState}}{{clntCountry}}</span>                                         
                                    </td>
                                  </tr>
                                </tbody>
                              </table>                               
                            </div>
                          </td>
                          <td style="width: 42%;vertical-align: top;">                             
                            <div>  
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                      Speciality</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{speclization_arry}}</td>
                                  </tr>
                                </tbody>
                              </table>                              
                              <table style="width: 100%;" class="head-table" *ngIf="chief_comp != undefined && chief_comp != ''">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                      Complaints</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;"><span [innerHtml]="chief_comp"></span></td>
                                  </tr>
                                </tbody>
                              </table>                                                                
                              <img style="width: 130px; height: 35px; margin-top: 5px;padding-left: 10px;"
                              src={{barcode}} />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div style="width: 98%">
                        <div> 
                          <p><strong>Observation</strong></p>
                          <div class="rowCover" style="display: inline-flex !important; flex-direction: column;padding-left: 5px;width: 100%;border: 1px solid black;border-radius: 5px;">
                            <div *ngIf="this.chief_comp != undefined && this.chief_comp != ''" class="vitalLable">
                              <strong>Complaints</strong>&nbsp;<br><span [innerHtml]="chief_comp" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.pres_ill != undefined && this.pres_ill != ''" class="vitalLable">
                              <strong>Present illness</strong>&nbsp;<br><span [innerHtml]="pres_ill" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.med_hist != undefined && this.med_hist != ''" class="vitalLable">
                              <strong>Past medical history</strong>&nbsp;<br><span [innerHtml]="med_hist" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.surgicalvalue != undefined && this.surgicalvalue != ''" class="vitalLable">
                              <strong>Surgical history</strong>&nbsp;<br><span [innerHtml]="surgicalvalue" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.family_history != undefined && this.family_history != ''" class="vitalLable">
                              <strong>Family history</strong>&nbsp;<br><span [innerHtml]="family_history" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.diabdetials != undefined && this.diabdetials != ''" class="vitalLable">
                              <strong>Diabetes</strong>&nbsp;<br><span [innerHtml]="diabdetials" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.hype_details != undefined && this.hype_details != ''" class="vitalLable">
                              <strong>Hypertension</strong>&nbsp;<br><span [innerHtml]="hype_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.ckddetails != undefined && this.ckddetails != ''" class="vitalLable">
                              <strong>CKD</strong>&nbsp;<br><span [innerHtml]="ckddetails" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.caddetails != undefined && this.caddetails != ''" class="vitalLable">
                              <strong>CAD</strong>&nbsp;<br><span [innerHtml]="caddetails" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.cvd_details != undefined && this.cvd_details != ''" class="vitalLable">
                              <strong>CVD</strong>&nbsp;<br><span [innerHtml]="cvd_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.tuber_details != undefined && this.tuber_details != ''" class="vitalLable">
                              <strong>Tuberculosis</strong>&nbsp;<br><span [innerHtml]="tuber_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.ba_details != undefined && this.ba_details != ''" class="vitalLable">
                              <strong>BA</strong>&nbsp;<br><span [innerHtml]="ba_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.ptb_details != undefined && this.ptb_details != ''" class="vitalLable">
                              <strong>PTB</strong>&nbsp;<br><span [innerHtml]="ptb_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.copd_details != undefined && this.copd_details != ''" class="vitalLable">
                              <strong>COPD :</strong>&nbsp;<br><span [innerHtml]="copd_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.apd_details != undefined && this.apd_details != ''" class="vitalLable">
                              <strong>APD</strong>&nbsp;<br><span [innerHtml]="apd_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.seizure_details != undefined && this.seizure_details != ''" class="vitalLable">
                              <strong>SEIZURE DISORDER</strong>&nbsp;<br><span [innerHtml]="seizure_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.thyroid_details != undefined && this.thyroid_details != ''" class="vitalLable">
                              <strong>THYROID DISORDER :</strong>&nbsp;<br><span [innerHtml]="thyroid_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.jaundice_details != undefined && this.jaundice_details != ''" class="vitalLable">
                              <strong>JAUNDICE</strong>&nbsp;<br><span [innerHtml]="jaundice_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.surgical_details != undefined && this.surgical_details != ''" class="vitalLable">
                              <strong>SURGICAL DISORDER</strong>&nbsp;<br><span [innerHtml]="surgical_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.Others_details != undefined && this.Others_details != ''" class="vitalLable">
                              <strong>Others</strong>&nbsp;<br><span [innerHtml]="Others_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="this.diet_data != undefined && this.diet_data != ''" class="vitalLable">
                              <strong>Diet</strong>&nbsp;<br><span [innerHtml]="diet_data" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.generalexamination != undefined && this.generalexamination != ''" class="vitalLable">
                              <strong>General examination</strong>&nbsp;<br><span [innerHtml]="generalexamination" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.local_examination != undefined && this.local_examination != ''" class="vitalLable">
                              <strong>Local examination</strong>&nbsp;<br><span [innerHtml]="local_examination" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.prov_diagnosis != undefined && this.prov_diagnosis != ''" class="vitalLable">
                              <strong>Provisional diagnosis</strong>&nbsp;<br><span [innerHtml]="prov_diagnosis" style="margin: 3px !important;"></span>
                            </div>
                            <div *ngIf="this.line_mgmt != undefined && this.line_mgmt != ''" class="vitalLable">
                              <strong>Line of managment</strong>&nbsp;<br><span [innerHtml]="line_mgmt" style="margin: 3px !important;"></span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="allergyFlag" style="margin-top: 15px;">
                          <p><strong>Allergies</strong></p>
                          <div class="rowCover" style="display: inline-flex !important; flex-direction: column;width: 100%;border: 1px solid black;border-radius: 5px;">
                            <div *ngIf="drugDetailsFlag && drug_details != ''" class="vitalLable">
                              <strong>Drug allergy</strong>&nbsp;<br><span [innerHtml]="drug_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="engDetailsFlag && env_details != ''" class="vitalLable">
                              <strong>Environmental allergy</strong>&nbsp;<br><span [innerHtml]="env_details" style="padding-left: 15px;"></span>
                            </div>
                            <div *ngIf="foodDetailsFlag && food_details != ''" class="vitalLable">
                              <strong>Food allergy</strong>&nbsp;<br><span [innerHtml]="food_details" style="padding-left: 15px;"></span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="vitals_List != undefined && vitals_List.length != 0" style="width: 100%;border-radius: 5px;margin-top: 15px;">
                          <p><strong>Vitals</strong></p>
                          <div class="rowCover" style="display: inline-flex !important;width:100% !important;">
                            <table *ngIf="vitals_List.length != 0" id="" class="table table-hover table-dynamic" cellpadding="5"
                              cellspacing="0" style="border: 1px solid #000;border-radius: 5px;width: 100%;">
                              <thead>
                                <tr style="text-align: center;">
                                  <th style="text-align: center;">S.No</th>
                                  <th style="text-align: center;">Height</th>
                                  <th style="text-align: center;">Weight</th>
                                  <th style="text-align: center;">BMI</th>
                                  <th style="text-align: center;">Temp</th>
                                  <th style="text-align: center;">BP</th>
                                  <th style="text-align: center;">Date & time</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let vital of vitals_List">
                                  <td style="text-align: left;">{{vital.sNo}}</td>
                                  <td style="text-align: left;">{{vital.height}}</td>
                                  <td style="text-align: left;">{{vital.weight}}</td>
                                  <td style="text-align: left;">{{vital.bmi}}</td>
                                  <td style="text-align: left;">{{vital.temperature}}</td>
                                  <td style="text-align: left;">{{vital.bloodPressure}}</td>
                                  <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td width="100%">
                      <table style="border:'0';width:100%">
                        <tr>
                          <td style="width:100%;height: 50px;">&nbsp;</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>