<div *ngIf="medPresFlag == 'min'">
  <div *ngIf="medInpatientViewPrec">
    <p *ngIf="viewpreslist_array.length == 0">No prescription details found</p>
    <div class="dig_table_overflow" style="height: 200px;" *ngIf="viewpreslist_array.length != 0">
      <div class="table-responsive">
        <table id="tbl" class="table table-hover table-dynamic">
          <thead>
            <tr>
              <th style="padding-left: 8px !important;width: 100px;">Date</th>
              <th>Prescription</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let prescription of viewpreslist_array | slice: 0 : 2">
              <td *ngIf="!header_footer_flag" style="font-size: 11px !important;">{{prescription.date}}</td>
              <td style="font-size: 11px !important;">{{"Dr. " + prescription.tot_doct_name}} prescribed for
                <span [innerHTML]="prescription.disease"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="!medInpatientViewPrec">
    <p *ngIf="med_pres_list.length == 0">No prescription details found</p>
    <div class="dig_table_overflow" style="height: 200px;" *ngIf="med_pres_list.length != 0">
      <div class="table-responsive">
        <table id="tbl" class="table table-hover table-dynamic">
          <thead>
            <tr>
              <th style="padding-left: 8px !important;width: 100px;">Date</th>
              <th>Prescription</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let prescription of med_pres_list | slice: 0 : 2">
              <td *ngIf="!header_footer_flag" style="font-size: 11px !important;">{{prescription.date}}</td>
              <td style="font-size: 11px !important;">{{"Dr. " + prescription.doctorname}} prescribed for
                <span [innerHTML]="prescription.disease"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="medPresFlag == 'max'">
  <div *ngIf="!addmed" class="splitter">
    <div style="float: right;margin-bottom: 10px;">
      <img *ngIf="!add_MP" style="width: 23px !important; position: absolute; top: 5px; right: 33px;"
        src="../../../assets/ui_icons/minussybl.svg" (click)="addMedPrescription('create')" />
      <a style="margin:0 5px;" *ngIf="backFlag" (click)="backInpatiantInfo()"><img
          src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo" /></a>

      <a *ngIf="list_button_flag" (click)="spiltarea('list_view')"><img
          src="../../../assets/ui_icons/pres_list_view.svg" class="backimgbtn_inpatinfo"
          style="position: absolute; top: 7px; right: 61px; width: 20px;" /></a>
      <a *ngIf="!print_view_flag && printFlag" (click)="print_Viewprescription()"><span class="cardsave"
          style="right: 84px;"><img src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>
    </div>

    <div *ngIf="medInpatientViewPrec">
      <div class="container">
        <div class="row" style="max-height: 250px;">
          <div *ngIf="viewpreslist_array.length ==0">
            <p class="nodata">No prescription data found</p>
          </div>
          <div *ngIf="viewpreslist_array.length !=0">
            <div *ngFor="let viewlist of viewpreslist_array">
              <!-- <div class="cover_div"> -->
              <div class="row">
                <div class="col-6">
                  <mat-label class="matlabel">Doctor name: {{viewlist.tot_doct_name}}</mat-label>
                </div>
                <div class="col-6">
                  <mat-label class="matlabel" style="float:right;">{{viewlist.view_date}}</mat-label>
                </div>
                <div class="col-6">
                  <mat-label class="matlabel">Diagnosis: {{viewlist.disease}}</mat-label>
                </div>
                <div class="col-12">

                  <div class="col-12">
                    <div class="dig_table_overflow">
                      <table id="tbl" class="table table-hover table-dynamic">
                        <thead>
                          <tr>
                            <th>Medcine name</th>
                            <!-- <th>Duration</th> -->
                            <th>Intake</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let viewmed of viewlist.medicines;  let i=index">
                            <td style="text-align: left;padding-left: 30px;">{{viewmed.drug}}</td>
                            <!-- <td style="text-align: left;padding-left: 30px;"><span *ngIf="viewmed.duration !== undefined">{{ viewmed.duration }}</span></td> -->
                            <td style="text-align: left;padding-left: 30px;">{{viewmed.catagory }}</td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- <table style="border: 1px solid #06438a;width: 100%;height: 41px;border: 1px solid #06438a;margin: 5px auto;">
                                        <tr *ngFor="let viewmed of viewlist.medicines">
                                            <td style="text-align: left;padding-left: 5px;">{{viewmed.drug}}</td>
                                            <td style="text-align: left;padding-left: 5px;">{{viewmed.catagory}}</td>
                                        </tr>
                                    </table> -->
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!medInpatientViewPrec">
      <p class="nodata" *ngIf="med_pres_list.length == 0">No prescription details found</p>
      <div *ngIf="presListView">
        <div class="personaldetails">
          <div class="block1">

            <div class="dig_table_overflow">
              <table id="tbl" class="table table-hover table-dynamic" *ngIf="med_pres_list.length != 0">
                <thead>
                  <tr>
                    
                    <th style="padding-left: 9px !important;">Date & time</th>
                    <th>Prescription</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let prescription of med_pres_list">
                    <td *ngIf="!header_footer_flag">{{prescription.date}} {{prescription.App_time}}</td>
                    <td style="text-align: left;padding-left: 20px;">{{"Dr. " + prescription.doctorname}}
                      prescribed for <span [innerHTML]="prescription.disease"></span></td>
                    <td *ngIf="medPresFlag == 'max'"><a (click)="prescriptionGetvalue(prescription.pres_drug_id)"><img
                          src="../../../assets/ui_icons/search_icon.svg" class="editDelicon" /></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!presListView">
        <p *ngIf="med_view_list "><strong>Prescription details</strong></p>
        <div class="personaldetails">
          <div *ngIf="med_view_list">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="!hospflag">
                <mat-label class="matlabel"><b>{{hptl_name}}</b></mat-label><br>
                <mat-label class="matlabel">{{address}}</mat-label><br>
                <mat-label class="matlabel">{{doc_loc}},{{doc_city}}</mat-label><br>
                <mat-label class="matlabel">{{doc_state}},{{doc_country}}</mat-label><br>
                <mat-label class="matlabel">{{doc_telephone }}</mat-label><br>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div *ngIf="docnameflag" class="med_heading">Doctor :<span class="med_content">{{dr_total_name}},
                    {{doctor_qualify_data}}</span></div>
                
                <div class="med_heading">Date & time  :<span class="med_content">{{date_prescription}} {{pres_time}}</span></div>
              </div>
            </div>

            <hr class="borderStylehr">
            <div class="row">
              <div class="col-12">
                <div class="med_heading">Patient :<span class="med_content">{{total_name}}</span></div>
                <div class="med_heading" style="display: flex;">Diagnosis :<span class="med_content"
                    [innerHtml]="dis_txt">
                  </span></div>
                <div class="med_heading" *ngIf="complaints != ''">Complaints :<span class="med_content"
                    [innerHtml]="complaints"></span></div>
                <div class="med_heading" *ngIf="next_txt1 != ''">Next visit :<span
                    class="med_content">{{next_txt1}}</span></div>
                <div class="med_heading" [hidden]="instr_flag">{{'Instructions' |translate}}:<div class="matlabel"
                    [innerHtml]="instructions"></div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="cover_div">
                <div class="header_lable">
                  Vitals & other information
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: 10px !important;">
                      <mat-label class="matlabel  col-4" *ngIf="height_flag" style="margin-right: 20px !important;">
                        <strong>{{'Height'}}:</strong> {{height}}
                      </mat-label>

                      <mat-label class="matlabel  col-4" *ngIf="weight_flag" style="margin-right: 20px !important;">
                        <strong>{{'Weight'}}:</strong> {{weight}}
                      </mat-label>
                      
                      <mat-label class="matlabel  col-4" *ngIf="bp_flag" style="margin-right: 20px !important;">
                        <strong>{{'BP'}}:</strong> {{clnt_bp}}
                      </mat-label>
                      
                      <mat-label class="matlabel  col-4" *ngIf="pulseflag" style="margin-right: 20px !important;">
                        <strong>{{'Pulse'}}:</strong> {{clnt_pulse}}
                      </mat-label>
                      
                      <mat-label class="matlabel  col-4" *ngIf="temparature_flag" style="margin-right: 20px !important;">
                        <strong>{{'Temperature'}}:</strong> {{temparature}}
                      </mat-label>
                      
                      <mat-label class="matlabel  col-4" *ngIf="rrflag" style="margin-right: 20px !important;">
                        <strong>{{'RR'}}:</strong> {{clnt_rr}}
                      </mat-label>
                     
                      <mat-label class="matlabel  col-4" *ngIf="spoflag" style="margin-right: 20px !important;">
                        <strong>{{'Spo2'}}:</strong> {{clnt_spo2}}
                      </mat-label>
                      
                      <mat-label class="matlabel  col-4" *ngIf="cbgflag" style="margin-right: 20px !important;">
                        <strong>{{'CBG'}}:</strong> {{clnt_cbg}}
                      </mat-label>
                     
                      <mat-label class="matlabel  col-4" *ngIf="cvsflag" style="margin-right: 20px !important;">
                        <strong>{{'CVS'}}:</strong> {{clnt_cvs}}
                      </mat-label>
                      
                      <mat-label class="matlabel  col-4" *ngIf="rsflag" style="margin-right: 20px !important;">
                        <strong>{{'RS'}}:</strong> {{clnt_rs}}
                      </mat-label>
                     
                      <mat-label class="matlabel  col-4" *ngIf="abdflag" style="margin-right: 20px !important;">
                        <strong>{{'Abdomen'}}:</strong> {{clnt_abd}}
                      </mat-label>
                      
                      <mat-label class="matlabel  col-4" *ngIf="cnsflag" style="margin-right: 20px !important;">
                        <strong>{{'CNS'}}:</strong> {{clnt_cns}}
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div><br/>
            <div class="col-12">
              <div class="dig_table_overflow">
                <table id="tbl" class="table table-hover table-dynamic">
                  <thead>
                    <tr>
                      <th>Medcine name</th>
                      <th>Dosage</th>
                      <th>Duration</th>
                      <th>Intake</th>
                      <th>Route</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let medicene of listProducts;  let i=index">
                      <td style="text-align: left;padding-left: 30px;">{{medicene.short_name}}
                        {{medicene.drug_name}}</td>
                      <td style="text-align: left;padding-left: 30px;">{{medicene.every_six}}</td>
                      <td>{{medicene.days}} {{medicene.period}}</td>
                      <td style="text-align: left;padding-left: 30px;">{{medicene.dure_txt_table}}
                        {{medicene.drug_intake }}</td>
                      <td style="text-align: left;padding-left: 30px;">{{medicene.route}}
                        {{medicene.intake_mode }}</td>
                      <td style="text-align: left;padding-left: 30px;">{{medicene.frequency}} {{medicene.remarks}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!pharma_control">
              <div class="details1" *ngFor="let pharma of pharcy_details">
                <mat-label><b>{{pharma.pharmacy_name}}</b></mat-label>
                <mat-label>{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</mat-label>
                <mat-label>{{pharma.phar_loc}}</mat-label>
                <mat-label>{{pharma.phar_city}} - {{pharma.phar_zipcode}}</mat-label>
                <mat-label>{{pharma.phar_state}}, {{pharma.phar_cnty}}</mat-label>
                <mat-label>{{pharma.telephone}}</mat-label>
              </div>
            </div>
            <div style="margin-top: 10px;"
              *ngIf="print_labarray.length!=0 || print_radioarray.length !=0 || print_package.length != 0">
              <p><strong>DX</strong></p>
              <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;">
                <div *ngIf="print_labarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Lab Tests:</strong></div>
                  <div class="test-names-container">
                  
                    <ng-container *ngFor="let test of print_labarray; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                  </div>

                </div>
                <div *ngIf="print_radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Radiology Tests:</strong></div>
                  <div class="test-names-container">
                  
                    <ng-container *ngFor="let test of print_radioarray; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="print_package.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Packages :</strong></div>
                  <div class="test-names-container">
                    
                    <ng-container *ngFor="let test of print_package; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 10px;"
              *ngIf="print_labarraynew.length!=0 || print_radioarraynew.length !=0 ||printnext_package.length != 0 ">
              <p><strong>Test for Next Visit: </strong></p>
              <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;">
                <div *ngIf="print_labarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Lab Tests:</strong></div>
                  <div class="test-names-container">
                  
                    <ng-container *ngFor="let test of print_labarraynew; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                  </div>

                </div>
                <div *ngIf="print_radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Radiology Tests:</strong></div>
                  <div class="test-names-container">
                 
                    <ng-container *ngFor="let test of print_radioarraynew; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="printnext_package.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Packages :</strong></div>
                  <div class="test-names-container">
                  
                    <ng-container *ngFor="let test of printnext_package; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- medical  -->
  <div *ngIf="addmed">
    <a (click)="print_area('')" *ngIf="printFlag"><span class="cardsave" style="right: 60px;"><img
          src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>
    <a [hidden]="!saveflag" (click)="savePrescription('print')"><span class="cardsave"><img
          src="../../../assets/img/save.svg" style="width:20px" style="width:25px" /></span> </a>

    <a (click)="view_previouspres()"><span class="cardsave" style="right: 87px;"><img
          src="../../../assets/img/Second_opinion_tabs_icons/Observation.png" style="width:25px" /></span> </a>

    <div id="pdffile">
      <div class="personaldetails">
           <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel" [expanded]="frommedical" *ngIf="medicalprescription">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title">
                Medical prescription
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div style="float: right;margin-bottom: 10px; position: relative;">
              <a style="margin-right: 5px;    position: absolute; top: -30px; right: 78px; " *ngIf="backFlag"
                (click)="backInpatiantInfo()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                  class="backimgbtn_inpatinfo" /></a>
              <a style="margin-right: 5px;  position: absolute;  top: -30px; right: -19px;" *ngIf="backFlag"
                (click)="Medical_prescription_save(null)"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                  class="backimgbtn_inpatinfo" /></a>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="cover_div">
                  <div class="header_lable">
                    Vitals & other information
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: 10px !important;">
                        <mat-label class="matlabel  col-4" *ngIf="height_flag" style="margin-right: 20px !important;">
                          <strong>{{'Height'}}:</strong> {{height}}
                        </mat-label>

                        <mat-label class="matlabel  col-4" *ngIf="weight_flag" style="margin-right: 20px !important;">
                          <strong>{{'Weight'}}:</strong> {{weight}}
                        </mat-label>
                        
                        <mat-label class="matlabel  col-4" *ngIf="bp_flag" style="margin-right: 20px !important;">
                          <strong>{{'BP'}}:</strong> {{clnt_bp}}
                        </mat-label>
                        
                        <mat-label class="matlabel  col-4" *ngIf="pulseflag" style="margin-right: 20px !important;">
                          <strong>{{'Pulse'}}:</strong> {{clnt_pulse}}
                        </mat-label>
                        
                        <mat-label class="matlabel  col-4" *ngIf="temparature_flag" style="margin-right: 20px !important;">
                          <strong>{{'Temperature'}}:</strong> {{temparature}}
                        </mat-label>
                        
                        <mat-label class="matlabel  col-4" *ngIf="rrflag" style="margin-right: 20px !important;">
                          <strong>{{'RR'}}:</strong> {{clnt_rr}}
                        </mat-label>
                       
                        <mat-label class="matlabel  col-4" *ngIf="spoflag" style="margin-right: 20px !important;">
                          <strong>{{'Spo2'}}:</strong> {{clnt_spo2}}
                        </mat-label>
                        
                        <mat-label class="matlabel  col-4" *ngIf="cbgflag" style="margin-right: 20px !important;">
                          <strong>{{'CBG'}}:</strong> {{clnt_cbg}}
                        </mat-label>
                       
                        <mat-label class="matlabel  col-4" *ngIf="cvsflag" style="margin-right: 20px !important;">
                          <strong>{{'CVS'}}:</strong> {{clnt_cvs}}
                        </mat-label>
                        
                        <mat-label class="matlabel  col-4" *ngIf="rsflag" style="margin-right: 20px !important;">
                          <strong>{{'RS'}}:</strong> {{clnt_rs}}
                        </mat-label>
                       
                        <mat-label class="matlabel  col-4" *ngIf="abdflag" style="margin-right: 20px !important;">
                          <strong>{{'Abdomen'}}:</strong> {{clnt_abd}}
                        </mat-label>
                        
                        <mat-label class="matlabel  col-4" *ngIf="cnsflag" style="margin-right: 20px !important;">
                          <strong>{{'CNS'}}:</strong> {{clnt_cns}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="row">

                          <div class="col-8">
                            <mat-label class="matlabel" style="margin-top: 10px !important;">
                              <mat-radio-group class="radiobtngrp" color="primary" labelPosition="before">
                                <mat-radio-button value="autoRx" (change)="fillType($event.value)">
                                  {{'Auto Rx'}}
                                </mat-radio-button>&nbsp;
                                <mat-radio-button value="refill" (change)="fillType($event.value)">
                                  {{'Refill'}}
                                </mat-radio-button>&nbsp;
                                <mat-radio-button value="previous" (click)="fillType('previous')">
                                  {{'Previous Rx'}}
                                </mat-radio-button>
                              </mat-radio-group>
                            </mat-label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div class="cover_div">
                  <div class="header_lable">Complaints</div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                        <quill-editor id="editor1" [(ngModel)]="complaints" [styles]="{height: 'calc(35vh - 100px)'}"
                          [modules]="modules" placeholder="Enter Text" (onContentChanged)="changecomplaints($event)">
                        </quill-editor>
                        <div>
                          <!-- Preview -->
                          <quill-view-html hidden id="linemgmt" [content]="complaints"></quill-view-html>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div class="cover_div">
                  <div class="header_lable">Diagnosis<span class="required-marker" *ngIf="not_Inpatient">*</span></div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                        <quill-editor id="pastill" [(ngModel)]="dis_txt" [styles]="{height: 'calc(35vh - 100px)'}"
                          [modules]="modules" placeholder="Enter Text" (onContentChanged)="changediagnosis($event)">
                        </quill-editor>
                        <div>
                          <!-- Preview -->
                          <quill-view-html hidden id="pastill" [content]="dis_txt"></quill-view-html>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <div class="cover_div">
                  <div class="header_lable">
                    Medicine details
                  </div>
                  <div class="content_cover" style="position: relative;">
                    <a (click)="getmedicine()" class="seacrhicon"><img src="../../../assets/ui_icons/tablet_icon.svg"
                        height="30px" width="30px" /></a>

                    <div class="row" *ngIf="medicineFlag">
                      <div class="col-xl-2"> </div>
                      <div class="col-md-12 col-lg-12 col-xl-8" style="margin-top: 15px;">
                        <div>
                          <table style="width: 75%; margin: 0 auto;" *ngIf="mediceList.length != 0" mdbTable datatable
                            [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                            <thead>
                              <tr>
                                <th style="width: 45% !important;">Medicine name</th>
                                <th>Quantity</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let person of mediceList; let i = index" (click)="setMedicien(person.med_id)">
                                <td style="font-size: 12px;text-align: left;">{{person.type_name}}
                                  {{person.med_name}} </td>
                                <td style="font-size: 12px;text-align: right;">{{person.quantity}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-xl-2"> </div>
                    </div>

                    <div class="row" *ngIf="!medicineFlag">
                      <div class="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-3">
                        <mat-checkbox color="primary" [(ngModel)]="new_med" (change)="showMedType(new_med)">
                        </mat-checkbox>
                        <mat-label class="matlabel">
                          {{'New medicine'}}
                        </mat-label>
                      </div>

                      <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-9">
                        <mat-label class="matlabel" [hidden]="newmed_flag">{{'Medicine type' }}
                          <input type="text" class="ipcss " required maxlength="50" [(ngModel)]="medtypetxt"
                            (keyup)="Get_medicine_type($event)" (focus)="clearmedtype()" aria-label="Number" matInput
                            autocomplete="on" [matAutocomplete]="auto2" style="max-width: 175px;" />
                          <mat-autocomplete #auto2="matAutocomplete">
                            <mat-option id="optionfont" (click)="select_medicine_type(medtype)"
                              *ngFor="let medtype of Medical_prescription_array" value={{medtype.med_name}}>
                              {{medtype.med_name}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-label>
                      </div>
                    
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3" 
                      [hidden]="filteredMedicineColorArray.length == 0" 
                      style="width:70px;">
                   <span class="color-square" 
                         *ngFor="let color of filteredMedicineColorArray; let i=index" 
                         [ngStyle]="{'background-color': color.color, 'position': 'relative', 'left': getLeftPosition(i) + 'px'}">
                   </span>
                 </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <mat-label class="matlabel">{{'Medicine name'}}
                          <input type="text" class="ipcss " required maxlength="50" [(ngModel)]="medicine_nametxt"
                            (keyup)="Medicine_name_change($event,'medicine')" aria-label="Number" matInput
                            [matAutocomplete]="auto6" />
                          <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                            <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                              *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                              {{medicine.name}}
                              <span style="font-weight: lighter;"> &nbsp; -
                                &nbsp;{{medicine.quantity}}</span>
                            </mat-option>
                          </mat-autocomplete>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="genericDisplayFlag">
                        <mat-label class="matlabel">Generic name
                          <input type="text" class="ipcss " required maxlength="50" [(ngModel)]="ganericName"
                            (keyup)="Medicine_name_change($event,'generic')" aria-label="Number" matInput
                            [matAutocomplete]="auto6" />
                          <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                            <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                              *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                              {{medicine.name}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-label>
                      </div>


                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" [hidden]="daydur_show">
                        <mat-label class="matlabel">{{'Duration'}}</mat-label>
                        <div class="row">
                          <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6" *ngIf="InpatMedPres">
                            <input type="text" class="ipcss" matInput required maxlength="5"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="day_txt" />
                          </div>
                          <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6" *ngIf="!InpatMedPres">
                            <input type="text" class="ipcss" matInput maxlength="5"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="day_txt" />
                          </div>

                          <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6" [hidden]="daydur_show">
                            <mat-select disableOptionCentering class="ipcss " (selectionChange)="Duration()"
                              [hidden]="daydur_show" [(ngModel)]="daydur_txt">
                              <mat-option disabled>Select</mat-option>
                              <mat-option value="day(s)">day(s)</mat-option>
                              <mat-option value="week(s)">week(s)</mat-option>
                              <mat-option value="month(s)">month(s)</mat-option>
                            </mat-select>
                          </div>
                        </div>
                      </div>

                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="intake_show">
                        <mat-label class="matlabel">{{'Intake'}}<br>
                          <mat-select disableOptionCentering class="ipcss " required (selectionChange)="intake_change()"
                            [(ngModel)]="afterfood_txt">
                            <mat-option value="Select">Select</mat-option>
                            <mat-option value="Empty stomach">Empty stomach</mat-option>
                            <mat-option value="After food">After food</mat-option>
                            <mat-option value="Before food">Before food</mat-option>
                            <mat-option value="With food">With Food</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>

                      <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="dure_show">
                        <mat-label [hidden]="dure_show" class="matlabel">{{'Min'}}
                          <input type="text" class="ipcss " matInput [required]="dure_require"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                            [(ngModel)]="dure_txt" />
                        </mat-label>
                      </div>
                      <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="!dure_show">

                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="mix_show">
                        <mat-label class="matlabel">{{'Mixed with'}}<br>
                          <mat-select disableOptionCentering class="ipcss " [(ngModel)]="mixing_txt">
                            <mat-option value="Select">Select</mat-option>
                            <mat-option *ngFor="let mixing of mixtype" value={{mixing}}>{{mixing}}</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
                      <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2" [hidden]="intakemode">
                        <mat-label class="matlabel">{{'Route'}}<br>
                          <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="intake_mode" (click)="selectinatkemode(intake_mode)">
                            <mat-option disabled>Select</mat-option>
                            <mat-option *ngFor="let intake of intakemodes" value={{intake.intake_mode_desc}}>
                              {{intake.intake_mode_desc}}</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" [hidden]="intakesession">
                        <mat-label class="matlabel">{{'Intake session'}}<br>
                          <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="intake_txt">
                            <mat-option disabled>Select</mat-option>
                            <mat-option *ngFor="let intake of Get_Intake_array" (click)="sesssion_basechange()"
                              value={{intake.description}}>
                              {{intake.description}}</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>

                      <div [hidden]="morn_eventxt" class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
                        <div [hidden]="mor_lable">
                          <mat-label class="matlabel input">{{'Mor'}}
                            <input type="text" class="ipcss " matInput [required]="morning_req" [(ngModel)]="mor"
                              [disabled]="morning_dis" maxlength="5"
                              onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)" />
                          </mat-label>
                        </div>

                        <div [hidden]="quan_lable">
                          <mat-label class="matlabel">{{'Qty'}}
                            <input type="text" class="ipcss " matInput [required]="morning_req" maxlength="5"
                              onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                              [disabled]="morning_dis" [(ngModel)]="mor" />
                          </mat-label>
                        </div>
                      </div>

                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 " [hidden]="after_eventxt">
                        <span class="iocn_position">-</span>
                        <mat-label class="matlabel">{{'Aft'}}
                          <input type="text" class="ipcss " matInput [disabled]="afternoon_dis" maxlength="5"
                            onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                            [required]="afternoon_req" [(ngModel)]="aft" />
                        </mat-label>
                      </div>

                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="eve_eventxt">
                        <span class="iocn_position ">-</span>
                        <mat-label class="matlabel">{{'Eve'}}
                          <input type="text" class="ipcss " matInput [disabled]="even_dis" [required]="evening_req"
                            maxlength="5"
                            onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                            [(ngModel)]="eve" />
                        </mat-label>
                      </div>

                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="night_eventxt">
                        <span class="iocn_position">-</span>
                        <mat-label class="matlabel">{{'Ngt'}}
                          <input type="text" class="ipcss " matInput [disabled]="ngt_dis" [required]="night_req"
                            maxlength="5"
                            onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                            [(ngModel)]="ngt" />
                        </mat-label>
                      </div>

                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel">{{'Note'}}<br>
                          <textarea class="ipcss widthappt" id="myInput" maxlength="250"
                            [(ngModel)]="remark_txt"></textarea>
                        </mat-label>
                      </div>
                      <div *ngIf="Med_addbtn" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-auto"
                        style="text-align: right;">
                        <a (click)="Medicalpres_add()"><img src="../../../assets/ui_icons/buttons/add_button.svg"
                            class="saveimgbtn_inpatinfo" /></a>
                        <a (click)="concernformprint('concern')"  *ngIf="highRiskPresent" ><img src="../../../assets/ui_icons/buttons/confirm_button_icons.svg"
                          class="saveimgbtn_inpatinfo" /></a>
                      </div>
                      <!-- <div *ngIf="highRiskPresent" class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-auto"
                      style="margin: 26px !important;">
                      <a style="margin:0 5px; position: relative;"  *ngIf="highRiskPresent" (click)="concernformprint('concern')"><img style="width: 30px;"
                        src="../../../assets/ui_icons/buttons/confirm_button_icons.png" />
                    </a>
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" style="margin: 20px 0;">
                <div class="col-12 dig_table_overflow" [hidden]="Medicine_table">
                  <div class="table-responsive">
                    <table id="tbl" *ngIf="listProducts.length !=0 ">
                      <thead>
                        <tr>
                          <th style="width: 11%;">{{'Medicine type'}}</th>
                          <th style="width: 12%;"></th>
                          <th style="width: 16%;">{{'Medicine name'}}</th>
                          <th style="width: 7%;">{{'Duration'}}</th>
                          <th style="width: 12%;">{{'Intake session'}}</th>
                          <th style="width: 10%;">{{'Frequency'}}</th>
                          <th style="width: 10%;">{{'Route'}}</th>
                          <th style="width: 15%;">{{'Intake'}}</th>
                          <th style="width: 22%;">{{'Note'}}</th>
                          <th>{{'Action'}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let product of listProducts">
                          <td style="text-align: left;">{{product.med_typetxt}}</td>
                          <td style="width:40px;text-align:left">
                            <span class="color-square" data-toggle="tooltip" data-placement="top"
                              title="Look alike" [hidden]="product.look_alikecolor == ''"
                              [ngStyle]="{'background-color': product.look_alikecolor, 'position': 'relative','left': '5px'}">
                            </span>
                            <span class="color-square" data-toggle="tooltip" data-placement="top"
                              title="Sound alike" [hidden]="product.sound_alikecolor == ''"
                              [ngStyle]="{'background-color': product.sound_alikecolor, 'position': 'relative','left': '10px'}">
                            </span>
                            <span class="color-square" data-toggle="tooltip" data-placement="top"
                              title="High risk" [hidden]="product.high_riskcolor == ''"
                              [ngStyle]="{'background-color': product.high_riskcolor , 'position': 'relative','left': '15px'}">
                            </span>
                            <span class="color-square" data-toggle="tooltip" data-placement="top"
                              title="OT / ICU" [hidden]="product.ward_onlycolor == ''"
                              [ngStyle]="{'background-color': product.ward_onlycolor , 'position': 'relative','left':  '20px'}">
                            </span>
                          </td>
                          <td style="text-align: left;">{{product.drug_name}}<br><span>{{product.genericname}}</span>
                          </td>
                          <td style="text-align: left;">{{product.days}} {{product.period }}</td>
                          <td>{{product.every_six}}</td>
                          <td style="text-align: left;">{{product.frequency }}</td>
                          <td style="text-align: left;">{{product.intake_mode }}</td>
                          <td style="text-align: left;">{{product.dure_txt_table}} {{product.drug_intake}} {{product.mixval}}</td>
                          <td style="text-align: left;">{{product.remarks}}</td>
                          <td>
                            <a
                              (click)="editMedicalPres(product.drug_id,product.rowid,product.genericname,product.intake)">
                              <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                class="editDelicon1" /></a>&nbsp;&nbsp;
                            <a (click)="Delete_medicalpres(product.med_typetxt,product.drug_name)"><img
                                src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon1" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div class="cover_div">
                  <div class="header_lable">
                    Pharmacy details
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-12">
                        <mat-label class="matlabel">{{'Preferred pharmacy' }}
                          <input type="text" class="ipcss" maxlength="50" [(ngModel)]="prfer_pharmatxt"
                            (keyup)="Prefer_pharmacy_change($event)" aria-label="Number" matInput
                            [matAutocomplete]="auto1" />
                          <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option id="optionfont" (click)="Pharma_medicine_name(prfer_pharmatxt)"
                              *ngFor="let pharma of Pharmanamelist" [value]="pharma">
                              {{pharma}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-label>
                      </div>
                      <div class="col-12">
                        <mat-label class="matlabel">{{'Preferred pharmacy address' }}<br>
                          <textarea class=" ipcss  txtarea" disabled="true" id="myInput" rows="1"
                            [(ngModel)]="prefer_add"></textarea>
                        </mat-label>
                      </div>
                      <div class="col-12">
                        <div>
                          <mat-label class="matlabel">
                            <mat-checkbox labelPosition="before" [default]="true" [ngModel]="true"
                              [(ngModel)]="order_now"> {{'Would you like to place order now' }}
                            </mat-checkbox>
                          </mat-label><br>
                        </div>
                        <div *ngIf="showInst">
                          <mat-label class="matlabel"><strong>{{"Dr."+doctorname}}
                              <span style="font-size: 10px;">{{qualification}}</span></strong></mat-label>
                          <br>
                          <mat-label class="matlabel"> ({{licence_code}})</mat-label><br>
                          <!-- <mat-label class="matlabel">{{'Find your prescription online at'}}
                                          www.tervys.com
                                       </mat-label><br> -->
                          <!-- <mat-label class="matlabel"><b><i>This is digitally generated Prescription</i></b>
                                       </mat-label> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                <div class="cover_div" style="padding-bottom: 17px;">
                  <div class="header_lable">
                    Instructions
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-12" *ngIf="showInst">
                        <quill-editor id="editor" [(ngModel)]="instructions" [style]="editorStyle" [modules]="modules"
                          placeholder="Enter Text" (onSelectionChanged)="onSelectionChanged($event)"
                          (onContentChanged)="onContentChanged($event)"></quill-editor>
                      </div>
                      <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" *ngIf="nextvisitflag">
                        <mat-label class="matlabel">{{'Next visit' }}<br>
                          <input type="date" class="ipcss_date widthappt" id="appt_date"
                            (change)="OnDateMatePicker(nextvisit,'med')" [(ngModel)]="nextvisit" min={{currentDate}}
                            #matInput style="width: 140px;">
                        </mat-label>
                      </div>
                      <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" *ngIf="nextvisitflag">
                        <mat-label class="matlabel">{{'Days' }}<br>
                          <input type="number" class="ipcss_date widthappt" (keyup)="Noofdays(no_of_days)"
                            [(ngModel)]="no_of_days" #matInput style="width: 140px;">
                        </mat-label>
                      </div>

                    </div>
                    <div class="row" *ngIf="nextvisitflag">
                      <mat-label class="matlabel">
                     
                        <mat-checkbox labelPosition="before" [(ngModel)]="book_app_now" (change)="checkNextVisit()"> {{'Would u like to book appointment for next visit?' }} </mat-checkbox>
                      </mat-label><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="expandpanel" [expanded]="!frommedical" *ngIf="diagpresflag">
            <mat-expansion-panel-header class="exppanel width" *ngIf="!inpatiant_save">
              <mat-panel-title class="title">
                Diagnosis prescription
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <!-- diagnosis content start -->
            <div>
              <div *ngIf="allow_diag_selectflag">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Country</mat-label>
                    <select class="ipcss" [(ngModel)]="search_country"
                      (ngModelChange)="countryChange(search_country, '1')">
                      <option [ngValue]=null>select</option>
                      <option *ngFor="let country of clnt_country_list" value="{{country.description}}">
                        {{country.description}}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">State</mat-label>
                    <select class="ipcss" [(ngModel)]="search_state" (ngModelChange)="stateChange(search_state, '1')">
                      <option [ngValue]=null>select</option>
                      <option *ngFor="let state of clnt_state_list" value="{{state.description}}">
                        {{state.description}}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">District / City</mat-label>
                    <select class="ipcss" [(ngModel)]="search_city" (ngModelChange)="cityChange(search_city, '1')">
                      <option [ngValue]=null>select</option>
                      <option *ngFor="let city of clnt_city_list" value="{{city.description}}">
                        {{city.description}}</option>
                    </select>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Location</mat-label>
                    <input type="text" class="ipcss" [(ngModel)]="search_location" name="loc_name"
                      (keyup)="location_change($event)" type="text" aria-label="Number" matInput
                      [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option id="optionfont" (click)="location_selected(location)"
                        *ngFor="let location of clnt_location_list" [value]="location.description">
                        {{location.description}}
                      </mat-option>
                    </mat-autocomplete>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Diagnosis center<br>
                      <mat-select disableOptionCentering class="ipcss" [(ngModel)]="sercah_center">
                        <mat-option *ngFor="let diag of diag_detail_List" (click)="serch_centerchange(diag)"
                          value="{{diag.diag_centre_name}}"> {{diag.diag_centre_name}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Diagnosis address<br>
                      <textarea class="ipcss" [(ngModel)]="obs_add_diag" name="obs_add_diag" matInput></textarea>
                    </mat-label>
                  </div>

                </div>
              </div>
              <div class="row" *ngIf="nextvisitflag">
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" *ngIf="nextvisitflag">
                  <mat-label class="matlabel">{{'Days' }}<br>
                    <input type="number" class="ipcss_date widthappt" (keyup)="Noofdaysdiag(no_of_daysdiag)"
                      
                      [(ngModel)]="no_of_daysdiag" #matInput >
                  </mat-label>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" *ngIf="nextvisitflag">
                  <mat-label class="matlabel">{{'Next visit' }}<br>
                    <input type="date" class="ipcss widthbilllist" id="appt_date"
                      (change)="OnDateMatePicker1(next_datetxt_diag,'diag')" [(ngModel)]="next_datetxt_diag"
                     
                      [min]="currentDate" #matInput >
                  </mat-label>
                </div>

                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" *ngIf="nextvisitflag" style="margin-top: 24px">
                  <mat-label class="matlabel wt">
                    <mat-checkbox color="primary" labelPosition="before" [(ngModel)]="nextvist_test"
                      (change)="changenextvisitdiagtest(nextvist_test)">Test for Next visit </mat-checkbox>
                  </mat-label>
                </div>
              </div>
              <div class="row" *ngIf="!nextvisitflag">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ">
                  <mat-label class="matlabel">Booking date
                    <input type="date" class="ipcss" max="9999-12-31" [(ngModel)]="appDate"
                      (click)="clickAppDate(appDate)" min="{{currentDate}}">
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ">
                  <mat-label class="matlabel">Booking Session
                    <select class="ipcss" [(ngModel)]="visitSession" (change)="selectSession()">
                      <option *ngFor="let session of getVisitingSessionArray"> {{session.description}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel">Time</mat-label>
                  <div class="row">
                    <div class="col-6" style="padding-right: 0px;">
                      <select class="ipcss  " [(ngModel)]="startTime">
                        <option *ngFor="let name of sessionTimeStart" value="{{name}}">{{name}}</option>
                      </select>
                    </div>
                    <div class="col-6" [hidden]="sessionFlag" style="padding-right: 0px;">
                      <select class="ipcss " [(ngModel)]="endTime">
                        <option *ngFor="let name of sessionTimeEnd" value="{{name}}">{{name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="nodata" *ngIf="test_flag">No data found</div>
              <div class="row" *ngIf="testflagnew" style="margin-top: 10px !important;">
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                  <div class="row" style="margin-bottom: 10px;">
                    <div class="col-3">
                      <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="LabtestName"
                        (keyup)="mainFilter(LabtestName)" placeholder="Search tests" matInput />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-xl-7 col-lg-7 col-md-12">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype ">
                            <p class="titletest"><strong>Laboratory tests</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="LabtestName" (keyup)="filterLabArray(LabtestName)" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let subtest of filteredLabArray" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'labtest',subtest.total_TAT)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'labtest',subtest.total_TAT)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.test_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype ">
                            <p class="titletest"><strong>Radiology tests</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="radiotestName" (keyup)="filterRadioArray(radiotestName)" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let subtest of filteredRadioArray" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest',subtest.total_TAT)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest',subtest.total_TAT)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.test_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype ">
                            <p class="titletest"><strong>Microbiology tests</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="microtestName" (keyup)="filterMicroArray(microtestName)" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let subtest of filteredMicroArray" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest',subtest.total_TAT)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest',subtest.total_TAT)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.test_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype ">
                            <p class="titletest"><strong>Profiles & packages</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="profilesName" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let package of filteredProfilesArray" id="testlbel">
                                <span *ngIf="package.checked == true" class="check_img"><img
                                    (click)="clickTest(false, package.diag_profile_id)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="package.checked == false" class="check_img"><img
                                    (click)="clickTest(true, package.diag_profile_id)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{package.profile_name}}
                              </div>
                              <div *ngFor="let subtest of filteredpackageArray" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="changepackage(false,subtest.health_pkg_id)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="changepackage(true,subtest.health_pkg_id)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.package_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                          <div class="diagnosis_testgrid testtype ">
                            <p class="titletest"><strong>Package</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="LabtestName" (keyup)="filterLabArray(LabtestName)" placeholder="Search tests" matInput />
                              <div *ngFor="let subtest of filteredpackageArray" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="changepackage(false,subtest.health_pkg_id)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="changepackage(true,subtest.health_pkg_id)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.package_name}}
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <!-- <div *ngFor="let testtype of Blood_test">
                                    <div *ngIf="!bloodtest && testtype.test_type_name=='Blood Test'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!scantest && testtype.test_type_name=='Scan'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestsname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div *ngIf="!ultratest && testtype.test_type_name=='Ultra Sound'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestuname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!xraytest && testtype.test_type_name=='X-ray'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="row">
                                                <div class="matmat" *ngFor="let s of subtestxname">
                                                   <div class="testtypep">{{s.test_name}}
                                                      <span>
                                                         <img
                                                            (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                            src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                            class="dig_close" alt="">
                                                      </span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!biopsytest && testtype.test_type_name=='Biopsy'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestbname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!urinetest && testtype.test_type_name=='Urine Test'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtesturname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!faecestest && testtype.test_type_name=='Faeces Test'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestfname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!culturetest && testtype.test_type_name=='Culture'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestcname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div> -->
                    </div>
                    <div class="col-12 col-sm-12 col-xl-4 col-lg-4 col-md-12">
                      <div class="row">
                        <h2 *ngIf="this.labarray.length !=0" class="tervys_heading2">Laboratory</h2>
                        <div class="row">
                          <div *ngFor="let price of this.labarray"
                            class="col-12 card-container d-flex flex-row align-items-center justify-content-between">
                            <p style="margin: 0px !important;font-size: 13px;">{{price.test_name}}
                              {{price.totalTAT}}</p>
                            <img (click)="removeTest(price.test_id,'lab')"
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="this.radioarray.length !=0" class="tervys_heading2">Radiology</h2>
                        <div class="row">
                          <div *ngFor="let price of this.radioarray"
                            class="col-12 card-container d-flex flex-row align-items-center justify-content-between">
                            <p style="margin: 0px !important;font-size: 13px;">
                              {{price.test_name}}{{price.totalTAT}}</p>
                            <img (click)="removeTest(price.test_id,'radio')"
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="selected_profiles.length !=0" class="tervys_heading2">Profiles</h2>
                        <div class="row">
                          <div *ngFor="let price of selected_profiles" class="card-container">
                            <div class="col-12 d-flex flex-row align-items-center justify-content-between"
                              data-bs-toggle="collapse" [attr.data-bs-target]="'#'+ 'target' +price.diag_profile_id"
                              [attr.aria-controls]="price.diag_profile_id" aria-expanded="false">
                              <p style="margin: 0px !important;font-size: 13px;">{{price.profile_name}}</p>
                              <img (click)="removeTest(price.diag_profile_id,'profile')"
                                src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                            </div>
                            <div style="margin-left: 20px; margin-top: 5px;" class="collapse"
                              [attr.id]="'target' +price.diag_profile_id">
                              <ul style="padding-left: 0px !important;">
                                <li *ngFor="let test of price.tests">
                                  {{test.test_name}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="selectedpackages.length !=0" class="tervys_heading2">Package</h2>
                        <div class="row">
                          <div *ngFor="let price of selectedpackages" class="card-container">
                            <div class="col-12 d-flex flex-row align-items-center justify-content-between"
                              data-bs-toggle="collapse" [attr.data-bs-target]="'#'+ 'target' +price.health_pkg_id"
                              [attr.aria-controls]="price.health_pkg_id" aria-expanded="false">
                              <p style="margin: 0px !important;font-size: 13px;">{{price.package_name}}</p>
                              <img (click)="removeTest(price.health_pkg_id,'package')"
                                src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                            </div>
                            <div style="margin-left: 20px; margin-top: 5px;" class="collapse"
                              [attr.id]="'target' +price.health_pkg_id">
                              <ul style="padding-left: 0px !important;">
                                <li *ngFor="let test of price.tests">
                                  {{test.test_name}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!testflagnew">
                <div class="col-3">
                  <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="LabtestNamenew"
                    (keyup)="mainFilternew(LabtestNamenew)" placeholder="Search tests" matInput />
                </div>
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-xl-7 col-lg-7 col-md-12">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype" id="nlab">
                            <p class="titletest"><strong>Laboratory tests</strong></p>
                            <div class="diag_test_content_cover p-3" id="nlab">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="LabtestNamenew" (keyup)="filterLabArraynew(LabtestNamenew)" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let subtest1 of filteredLabArraynew" id="testlbel1">
                                <span *ngIf="subtest1.checked == true" class="check_img"><img
                                    (click)="ChangeTestTypenew(false,subtest1.diag_tests_id, subtest1.test_name, subtest1.test_cat, subtest1, subtest1.test_type, 'labtest')"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest1.checked == false" class="check_img"><img
                                    (click)="ChangeTestTypenew(true,subtest1.diag_tests_id, subtest1.test_name, subtest1.test_cat, subtest1, subtest1.test_type, 'labtest')"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest1.test_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype" id="nradio">
                            <p class="titletest"><strong>Radiology tests</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="radiotestNamenew" (keyup)="filterRadioArraynew(radiotestNamenew)" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let subtest of filteredRadioArraynew" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="ChangeTestTypenew(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest')"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="ChangeTestTypenew(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest')"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.test_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype" id="nmicro">
                            <p class="titletest"><strong>Microbiology tests</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                                [(ngModel)]="microtestNamenew" (keyup)="filterMicroArraynew(microtestNamenew)" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let subtest of filteredMicroArraynew" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="ChangeTestTypenew(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest')"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="ChangeTestTypenew(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest')"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.test_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="diagnosis_testgrid testtype" id="nprof">
                            <p class="titletest"><strong>Profiles & packages</strong></p>
                            <div class="diag_test_content_cover p-3">
                              <!-- <input type="text" class="ipcss" aria-label="Number"
                                  [(ngModel)]="profilesNamenew" placeholder="Search tests" matInput /> -->
                              <div *ngFor="let package of filteredProfilesArraynew" id="testlbel">
                                <span *ngIf="package.checked == true" class="check_img"><img
                                    (click)="clickTestnew(false, package.diag_profile_id)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="package.checked == false" class="check_img"><img
                                    (click)="clickTestnew(true, package.diag_profile_id)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{package.profile_name}}
                              </div>
                              <div *ngFor="let subtest of filteredpackageArraynew" id="testlbel">
                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                    (click)="changepackageNew(false,subtest.health_pkg_id)"
                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                    width="12px" /></span>

                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                    (click)="changepackageNew(true,subtest.health_pkg_id)"
                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                    width="12px" /></span>
                                {{subtest.package_name}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-xl-4 col-lg-4 col-md-12">
                      <div class="row">
                        <h2 *ngIf="this.labarray.length !=0" class="tervys_heading2">Laboratory</h2>
                        <div class="row">
                          <div *ngFor="let price of this.labarray"
                            class="col-12 card-container d-flex flex-row align-items-center justify-content-between">
                            <p style="margin: 0px !important;font-size: 13px;">{{price.test_name}}
                              {{price.totalTAT}}</p>
                            <img (click)="removeTest(price.test_id,'lab')"
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="this.radioarray.length !=0" class="tervys_heading2">Radiology</h2>
                        <div class="row">
                          <div *ngFor="let price of this.radioarray"
                            class="col-12 card-container d-flex flex-row align-items-center justify-content-between">
                            <p style="margin: 0px !important;font-size: 13px;">
                              {{price.test_name}}{{price.totalTAT}}</p>
                            <img (click)="removeTest(price.test_id,'radio')"
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="selected_profiles.length !=0" class="tervys_heading2">Profiles</h2>
                        <div class="row">
                          <div *ngFor="let price of selected_profiles"
                            class="col-12 card-container d-flex flex-row align-items-center justify-content-between">
                            <p style="margin: 0px !important;font-size: 13px;">{{price.profile_name}}</p>
                            <img (click)="removeTest(price.diag_profile_id,'profile')"
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="this.labarraynew.length !=0" class="tervys_heading2">Next Visit
                          Laboratory</h2>
                        <div class="row">
                          <div *ngFor="let price of this.labarraynew"
                            class="col-12 card-container d-flex flex-row align-items-center justify-content-between">
                            <p style="margin: 0px !important;font-size: 13px;">{{price.test_name}}</p>
                            <img (click)="removeTestnew(price.test_id,'lab')"
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="this.radioarraynew.length !=0" class="tervys_heading2">Next Visit
                          Radiology</h2>
                        <div class="row">
                          <div *ngFor="let price of this.radioarraynew"
                            class="col-12 card-container d-flex flex-row align-items-center justify-content-between">
                            <p style="margin: 0px !important;font-size: 13px;">{{price.test_name}}</p>
                            <img (click)="removeTestnew(price.test_id,'radio')"
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="selected_profilesnew.length !=0" class="tervys_heading2">Next Visit Profiles</h2>
                        <div class="row">
                          <div *ngFor="let price of selected_profilesnew" class="card-container">
                            <div class="col-12 d-flex flex-row align-items-center justify-content-between"
                              data-bs-toggle="collapse" [attr.data-bs-target]="'#'+ 'target' +price.diag_profile_id"
                              [attr.aria-controls]="price.diag_profile_id" aria-expanded="false">
                              <p style="margin: 0px !important;font-size: 13px;">{{price.profile_name}}</p>
                              <img (click)="removeTestnew(price.diag_profile_id,'profile')"
                                src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                            </div>
                            <div style="margin-left: 20px; margin-top: 5px;" class="collapse"
                              [attr.id]="'target' +price.diag_profile_id">
                              <ul style="padding-left: 0px !important;">
                                <li *ngFor="let test of price.tests">
                                  {{test.test_name}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <h2 *ngIf="selectedpackagesnew.length !=0" class="tervys_heading2">Next Visit Package</h2>
                        <div class="row">
                          <div *ngFor="let price of selectedpackagesnew" class="card-container">
                            <div class="col-12 d-flex flex-row align-items-center justify-content-between"
                              data-bs-toggle="collapse" [attr.data-bs-target]="'#'+ 'target' +price.health_pkg_id"
                              [attr.aria-controls]="price.health_pkg_id" aria-expanded="false">
                              <p style="margin: 0px !important;font-size: 13px;">{{price.package_name}}</p>
                              <img (click)="removeTestnew(price.health_pkg_id,'package')"
                                src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" />
                            </div>
                            <div style="margin-left: 20px; margin-top: 5px;" class="collapse"
                              [attr.id]="'target' +price.health_pkg_id">
                              <ul style="padding-left: 0px !important;">
                                <li *ngFor="let test of price.tests">
                                  {{test.test_name}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="row col-sm-12 col-xl-12 " *ngIf="testnowflag">
                <mat-label class="matlabel wt">
                  <mat-checkbox color="primary" labelPosition="before" [default]="true" [ngModel]="true"
                    [(ngModel)]="test_now">Would u like to
                    test now</mat-checkbox>
                </mat-label>
              </div>
            </div>
            <!-- <div class="row">
                     <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">{{'Next visit' }}<br>
                           <input type="date" class="ipcss_date widthappt" id="appt_date"
                              (change)="OnDateMatePicker(next_datetxt)" [(ngModel)]="next_datetxt"
                              min={{currentDate}} #matInput style="width: 140px;">
                        </mat-label>
                     </div>
                  </div> -->

            <!--tempppp <div class="row">
                     <mat-label class="matlabel">
                        <mat-checkbox labelPosition="before" [(ngModel)]="book_app_now" (change)="checkNextVisit()"> {{'Would u like to book appointment for next visit? ' |translate}} </mat-checkbox>
                     </mat-label><br>
                  </div> -->
            <!-- diagnosis content end -->
          </mat-expansion-panel>

        </mat-accordion>

        <div class="row col-sm-12 col-xl-12" *ngIf="print_medidiag">
          <mat-label class="matlabel wt">
            <mat-checkbox color="primary" labelPosition="before" [(ngModel)]="print_same_page">Would u like print
              medical and diagnosis prescription in same page</mat-checkbox>
          </mat-label>
        </div>
      </div>
    </div>
  </div>
  <!-- expand  -->
  <div [hidden]="true">
    <div *ngIf="print_same_page">
    <div #printlogowithname id="printlogowithname"
      style="background-image: url({{bg_image}}); background-position: center;">
      <div *ngIf="!print_same_page">
        <div *ngIf="listProducts.length != 0 && print_view_flag">
          <table style="margin-left:50px;margin-top:70px;">
            <tr>
              <td style="width: 450px; vertical-align: top;">
                <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                <p style="margin-left: 110px;">{{doct_city}}</p>
                <p style="margin-left: 110px;">{{doct_state}}</p>
              </td>
              <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <br /><br />
          <table style="margin-left: 50px; margin-top:20px;">
            <tr>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                <p><b>{{'Age'|translate}}/{{'Gender'|translate}} :</b> {{client_age}}/{{client_gender}}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                <p *ngIf="disease_flag"><b>Diagnosis : </b>{{disease_name}}</p>

              </td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <div class="d-flex align-items-center justify-content-center">
                  <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                    [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                    [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                    [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                    [bc-margin-right]="marginRight"></ngx-barcode>
                </div>
                <!-- <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p> -->
              </td>
            </tr>
          </table>
          <div style="display: flex;margin-left: 50px;" class="rowformat">
            <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;"><b>{{'Referred by'|translate}}:
              </b>{{refer_txt}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'BP'|translate}}:
              </b>{{clnt_bp}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'Pulse'|translate}}:
              </b>{{clnt_pulse}}</p>
            <p *ngIf="height_flag" class="refformat" style="margin: 2px !important;"><b>{{'Height'|translate}}:
              </b>{{height}} </p>

            <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;"><b>{{'Weight'|translate}}:
              </b>
              {{weight}}</p>
            <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;"><b>
                {{'Temperature'|translate}}: </b>{{temparature}} </p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'CVS'|translate}}:
              </b>{{clnt_cvs}}</p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'RS'|translate}}: </b>
              {{clnt_rs}}</p>

            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b> {{'Kidney'|translate}}:
              </b>{{clnt_kidney}}</p>
          </div>
          <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
            <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
          </div>
          <br />
          <table *ngIf="listProducts.length != 0"
            style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
            <tr>
              <th [style]="med_style">
                <b>{{'Medicine'|translate}}</b>
              </th>
              <th [style]="dosage_style">
                <b>{{'Dosage'|translate}}</b>
              </th>
              <th [style]="dur_style">
                <b>{{'Duration'|translate}}</b>
              </th>
              <th [style]="dur_style">
                <b>{{'Route'|translate}}</b>
              </th>
              <th [style]="freq_style">
                <b>{{'Frequency'|translate}}</b>
              </th>
              <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                [hidden]="dur_flag"> <b>{{'Intake'|translate}}</b></th>
              <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                [hidden]="notes_flag">
                <b>{{'Note'|translate}}</b>
              </th>
            </tr>
            <tr *ngFor="let product of listProducts">
              <td style="border: 1px solid black;border-collapse: collapse;width: 220px;">
                {{product.med_typetxt}} {{product.drug_name}} <span
                  style="font-size:12px">({{product.genericname}})</span>
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.every_six}}
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                {{product.period|translate}}</td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.intake_mode}}</td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.frequency
                        |
                        translate}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                [hidden]="dur_flag">{{product.dure_txt_table}}
                {{product.drug_intake|translate}} {{product.mixval}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;"
                [hidden]="notes_flag">{{product.remarks}}</td>
            </tr>
          </table>

          <div style="margin-left: 50px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_instruction"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="complaints!=undefined"><b>{{'Complaints' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_complaints"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="dis_txt!=undefined"><b>{{'Diagnosis' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_disease"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;" *ngIf="treatflag">
            <p><b>{{'Treatment' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="treatment"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;">
            <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
          </div>
          <br />
          <div>
            <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
            </p>
            <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
            <!-- <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p> -->
            <br />
          </div>
        </div>
        <div *ngIf="print_diag">
          <div class="page-break" *ngIf="listProducts.length != 0"></div>
          <table style="margin-left:50px;margin-top:70px;">
            <tr>
              <td style="width: 450px; vertical-align: top;">
                <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                <p style="margin-left: 110px;">{{doct_city}}</p>
                <p style="margin-left: 110px;">{{doct_state}}</p>
              </td>
              <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <br /><br />
          <table style="margin-left: 50px; margin-top:20px;">
            <tr>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                <p><b>{{'Age'|translate}}/{{'Gender'|translate}} :</b> {{client_age}}/{{client_gender}}</p>
                <!-- <p><b>:</b>  </p> -->
              </td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <div class="d-flex align-items-center justify-content-center">
                  <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                    [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                    [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                    [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                    [bc-margin-right]="marginRight"></ngx-barcode>
                </div>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <div style="margin-left: 50px;" *ngIf="print_diag">
            <p><b>Diagnostic Test</b></p>
            <div *ngIf="pritn_blood_flag">
              <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{blood_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_scan_flag">
              <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{scan_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_us_flag">
              <p id="print_blood_head"><b>{{us_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{us_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_xray_flag">
              <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{xray_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_bio_flag">
              <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{bio_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_ut_flag">
              <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{ut_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_ft_flag">
              <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{ft_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_culture_flag">
              <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{culture_test_content}}
              </p>
            </div>
            <!-- <div style="margin-left: 50px;">
                     <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                  </div> -->
            <br />
            <div style="margin-left: 50px;">
              <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                  {{qualification}}</strong>
              </p>
              <p style="font-size: 13px">({{licence_code}})</p>
              <!-- <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                     </p>
                     <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                     </p> -->
              <br />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="print_same_page">
        <table>
          <thead>
            <tr>
              <td>
               
                <div class=“empty-header“ [style]="preslogo_style"> </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class=“content“>

                  <div>
                    <div
                      style="margin-left: 25px !important;margin-right:25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                    
                      <div>
                        <div style="display: flex;vertical-align: top;">
                          <table style="width: 450px;vertical-align: top;">
                            <!-- <tr>
                             <td><strong>{{'MR No'}}</strong></td>
                             <td>: {{mrNumber}}</td>
                           </tr> -->
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Name'|translate}}</strong></td>
                              <td>: {{doct_clientname}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Age'|translate}}/{{'Gender'|translate}}</strong></td>
                              <td>: {{client_age}}/{{client_gender}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Mobile'}}</strong></td>
                              <td>: {{mobile}}</td>
                            </tr>


                          </table>
                          <table style="width: 350px;vertical-align: top;">
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Date'|translate}}</strong></td>
                              <td>: {{Appoint_Date}} {{printtime}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td>
                                <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                                  [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                  [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                  [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                  [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                  [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                  [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Complaints' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="complaints"></div>
                        </span>
                      </p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Diagnosis' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="dis_txt"></div>
                        </span>
                      </p>
                    </div>
                    <div
                      style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                      class="rowformat">
                      <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                      <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'BP'|translate}}:</b>{{clnt_bp}}</p>
                      <p *ngIf="pulseflag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Pulse'|translate}}:</b>{{clnt_pulse}}</p>
                      <p *ngIf="height_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Height'|translate}}:</b>{{height}} </p>

                      <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Weight'|translate}}:</b>{{weight}}</p>
                      <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Temperature'|translate}}: </b>{{temparature}} </p>
                      <p *ngIf="cvsflag" class="refformat" style="margin: 2px !important;">
                        <b>{{'CVS'|translate}}:</b>{{clnt_cvs}}</p>
                      <p *ngIf="rsflag" class="refformat" style="margin: 2px !important;">
                        <b>{{'RS'|translate}}:</b>{{clnt_rs}}</p>

                      <p *ngIf="kidney_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Kidney'|translate}}:</b>{{clnt_kidney}}</p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;"
                      *ngIf="listProducts.length != 0">
                      <p><strong>Rx</strong></p>
                    </div>
                    <table *ngIf="listProducts.length != 0"
                      style="margin-left: 25px !important;margin-right:25px !important;; border: 1px solid black;border-collapse: collapse;">

                      <tr style="text-align: center;">
                        <th [style]="med_style">
                          {{'Medicine'|translate}}
                        </th>
                        <th [style]="dosage_style">
                          <p>Mor</p>
                          <p *ngIf="printLanguageflag"> {{'Mor'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Aft</p>
                          <p *ngIf="printLanguageflag">{{'Aft'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Eve</p>
                          <p *ngIf="printLanguageflag">{{'Eve'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Ngt</p>
                          <p *ngIf="printLanguageflag"> {{'Ngt'|translate}}</p>

                        </th>
                        <th [style]="dur_style">
                          <p>Duration</p>
                          <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>
                        </th>
                        <th [style]="dur_style">
                          <p>Route</p>
                          <p *ngIf="printLanguageflag">{{'Route'|translate}}</p>
                        </th>
                        <th [style]="freq_style">
                          {{'Frequency'|translate}} & {{'Notes'|translate}}
                        </th>
                        <!-- <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                       [hidden]="dur_flag"> <strong>{{'Intake'|translate}}</strong></th>
                     <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                       [hidden]="notes_flag">
                       <strong>{{'Note'|translate}}</strong>
                     </th> -->
                      </tr>
                      <tr *ngFor="let product of listProducts">
                        <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                          {{product.med_typetxt}} {{product.drug_name}} <div style="font-size:12px">
                            {{product.genericname}}</div>
                        </td>
                        <td style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;"
                          colspan="4" *ngIf="product.param_four ==true">
                          {{product.every_six}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.morning1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.afternoon1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.evening1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.night1}}
                        </td>
                        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.days}}
                          {{product.period|translate}}</td>
                        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.intake_mode}}</td>
                        <td
                          style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                          {{product.frequency
                                      |
                                      translate}} {{product.dure_txt_table}}
                          {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                        <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                        </td>
                      <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                        </td> -->
                      </tr>
                    </table>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Instructions' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="instructions"></div>
                        </span>
                      </p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p><strong>{{'Next visit' |translate}}: </strong>{{nextvisit_print}}</p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;"
                      *ngIf="treatflag">
                      <p><strong>{{'Treatment' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="treatment"></div>
                        </span>
                      </p>
                    </div>

                  </div>

                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0 || labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                    <p><strong>DX</strong></p>
                    <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;"
                      *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0">
                      <div *ngIf="labarray.length !=0 || selected_profilesnew.length!=0 "
                        style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <ng-container *ngFor="let test of selected_profiles; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.profile_name}}
                          </ng-container>

                          <!-- <ng-container *ngFor="let profile of selected_profiles let last = last">
                           <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                         </ng-container> -->
                        </div>

                      </div>
                      <div *ngIf="radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of radioarray; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <ng-container *ngFor="let test of radioarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="selectedpackages.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of selectedpackages let last = last">
                          <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <ng-container *ngFor="let test of selectedpackages; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 || selectedpackagesnew.elngth != 0">
                    <p *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                      <strong>Test for next visit:</strong></p>

                    <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                      <div *ngIf="labarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of labarraynew; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <ng-container *ngFor="let test of selected_profilesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.profile_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let profile of selected_profilesnew let last = last">
                           <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                         </ng-container> -->
                        </div>

                      </div>
                      <div *ngIf="radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of radioarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                        </div>
                      </div>
                      <div *ngIf="selectedpackagesnew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of selectedpackagesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of selectedpackagesnew; let last = last">
                          <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                        </div>
                      </div>
                    </div>


                  </div>

                  <div>
                    <p
                      style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 3px;">
                      <strong>{{"Dr."+doct_name}}
                        {{qualification}}</strong>
                    </p>
                    <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                      ({{licence_code}})</p>

                    <br />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <div class=”empty-footer”>&nbsp;</div>
          </tfoot>
        </table>
        <div class=”header”>&nbsp;</div>
        <div class=”footer”>&nbsp;</div>
      </div>
      <div *ngIf="!print_view_flag">
        <div *ngIf="listProducts.length != 0">
          <table style="margin-left:50px;margin-top:70px;">
            <tr>
              <td style="width: 450px; vertical-align: top;">
                <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                <p style="margin-left: 110px;">{{doct_city}}</p>
                <p style="margin-left: 110px;">{{doct_state}}</p>
              </td>
              <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <br /><br />
          <table style="margin-left: 50px; margin-top:20px;">
            <tr>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                <p><b>{{'Age'|translate}}/{{'Gender'|translate}} :</b> {{client_age}}/{{client_gender}}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                <p *ngIf="disease_flag"><b>Diagnosis : </b>{{disease_name}}</p>

              </td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <div class="d-flex align-items-center justify-content-center">
                  <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                    [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                    [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                    [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                    [bc-margin-right]="marginRight"></ngx-barcode>
                </div>
                <!-- <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p> -->
              </td>
            </tr>
          </table>
          <div style="display: flex;margin-left: 50px;" class="rowformat">
            <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;"><b>{{'Referred by'|translate}}:
              </b>{{refer_txt}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'BP'|translate}}:
              </b>{{clnt_bp}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'Pulse'|translate}}:
              </b>{{clnt_pulse}}</p>
            <p *ngIf="height_flag" class="refformat" style="margin: 2px !important;"><b>{{'Height'|translate}}:
              </b>{{height}} </p>

            <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;"><b>{{'Weight'|translate}}:
              </b>
              {{weight}}</p>
            <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;"><b>
                {{'Temperature'|translate}}: </b>{{temparature}} </p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'CVS'|translate}}:
              </b>{{clnt_cvs}}</p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'RS'|translate}}: </b>
              {{clnt_rs}}</p>

            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b> {{'Kidney'|translate}}:
              </b>{{clnt_kidney}}</p>
          </div>
          <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
            <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
          </div>
          <br />
          <table *ngIf="listProducts.length != 0"
            style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
            <tr>
              <th [style]="med_style">
                <b>{{'Medicine'|translate}}</b>
              </th>
              <th [style]="dosage_style">
                <b>{{'Dosage'|translate}}</b>
              </th>
              <th [style]="dur_style">
                <b>{{'Duration'|translate}}</b>
              </th>
              <th [style]="dur_style">
                <b>{{'Route'|translate}}</b>
              </th>
              <th [style]="freq_style">
                <b>{{'Frequency'|translate}}</b>
              </th>
              <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                [hidden]="dur_flag"> <b>{{'Intake'|translate}}</b></th>
              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;min-width: 250px !important;"
                [hidden]="notes_flag">
                <b>{{'Note'|translate}}</b>
              </th>
            </tr>
            <tr *ngFor="let product of listProducts">
              <td style="border: 1px solid black;border-collapse: collapse;width: 220px;">
                {{product.med_typetxt}} {{product.drug_name}} <span
                  style="font-size:12px">({{product.genericname}})</span>
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.every_six}}
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                {{product.period|translate}}</td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.intake_mode}}</td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.frequency
                        |
                        translate}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                {{product.drug_intake|translate}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
            </tr>
          </table>

          <div style="margin-left: 50px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions'}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_instruction"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="complaints!=undefined"><b>{{'Complaints'}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_complaints"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="dis_txt!=undefined"><b>{{'Diagnosis'}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_disease"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;" *ngIf="treatflag">
            <p><b>{{'Treatment'}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="treatment"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;">
            <p *ngIf="next_txt!=undefined"><b>{{'Next visit'}}: </b>{{next_txt}}</p>
          </div>
          <br />
          <div>
            <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
            </p>
            <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
            <!-- <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p> -->
            <br />
          </div>
        </div>
      </div>
    </div>

    <div #printbanner id="printbanner" style="background-image: url({{bg_image}}); background-position: center;">
      <div *ngIf="print_same_page">
        <table>
          <thead>
            <tr>
              <td>
                <div class=“empty-header“ [style]="preslogo_style"> </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class=“content“>

                  <div>
                    <div
                      style="margin-left: 25px !important;margin-right:25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                    
                      <div>
                        <div style="display: flex;vertical-align: top;">
                          <table style="width: 450px;vertical-align: top;">
                            <!-- <tr>
                             <td><strong>{{'MR No'}}</strong></td>
                             <td>: {{mrNumber}}</td>
                           </tr> -->
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Name'}}</strong></td>
                              <td>: {{doct_clientname}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Age'}}/{{'Gender'}}</strong></td>
                              <td>: {{client_age}}/{{client_gender}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Mobile'}}</strong></td>
                              <td>: {{mobile}}</td>
                            </tr>


                          </table>
                          <table style="width: 350px;vertical-align: top;">
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Date & Time'}}</strong></td>
                              <td>: {{Appoint_Date}} {{printtime}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td *ngIf="barcode_flag"><img style="width: 130px; height: 35px; margin-left: -12px;"
                                  src={{barcode_url}} />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                    
                      <p style="margin:0px"><strong>{{'Complaints'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="complaints"></div>
                        </span>
                      </p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Diagnosis'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="dis_txt"></div>
                        </span>
                      </p>
                    </div>
                    <div
                      style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                      class="rowformat">
                      <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Referred by'}}: </b>{{refer_txt}}</p>
                     
                        <p *ngIf="height_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                          <b>{{'Height'}}:</b>{{height}}
                        </p>

                        <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;">
                        
                          <b>{{'Weight'}}:</b>{{weight}}
                        </p>
                       
                        <p *ngIf="bp_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                          <b>{{'BP'}}:</b>{{clnt_bp}}
                        </p>
                        <p *ngIf="pulseflag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                          <b>{{'Pulse'}}:</b>{{clnt_pulse}}
                        </p>
                        <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;">
                      
                          <b>{{'Temperature'}}: </b>{{temparature}}
                        </p>
                        <p *ngIf="rrflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'RR'}}:</b>{{clnt_rr}}
                        </p>
                        <p *ngIf="spoflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'Spo2'}}:</b>{{clnt_spo2}}
                        </p>
                        <p *ngIf="cbgflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'CBG'}}:</b>{{clnt_cbg}}
                        </p>                       
                      
                        <p *ngIf="cvsflag" class="refformat" style="margin: 2px !important;">
                        
                          <b>{{'CVS'}}:</b>{{clnt_cvs}}
                        </p>
                        <p *ngIf="rsflag" class="refformat" style="margin: 2px !important;">
                        
                          <b>{{'RS'}}:</b>{{clnt_rs}}
                        </p>
                      
                        <p *ngIf="abdflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'Abdomen'}}:</b>{{clnt_abd}}
                        </p>
                        <p *ngIf="cnsflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'CNS'}}:</b>{{clnt_cns}}
                        </p>
                      <p *ngIf="heartrateflag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Heart rate'}}:</b>{{clnt_heartrate}}</p>
                      <p *ngIf="kidney_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Kidney'}}:</b>{{clnt_kidney}}</p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;"
                      *ngIf="listProducts.length != 0">
                      <p><strong>Rx</strong></p>
                    </div>
                    <table *ngIf="listProducts.length != 0"
                      style="margin-left: 25px !important;margin-right:25px !important;; border: 1px solid black;border-collapse: collapse;">

                      <tr style="text-align: center;">
                        <th [style]="med_style">
                          <p>Medicine</p>
                          <p *ngIf="printLanguageflag"> {{'Medicine'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Mor</p>
                          <p *ngIf="printLanguageflag"> {{'Mor'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Aft</p>
                          <p *ngIf="printLanguageflag">{{'Aft'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Eve</p>
                          <p *ngIf="printLanguageflag">{{'Eve'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Ngt</p>
                          <p *ngIf="printLanguageflag"> {{'Ngt'|translate}}</p>

                        </th>
                        <th [style]="dur_style">
                          <p>Duration</p>
                          <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>
                        </th>
                        <th [style]="dur_style">
                          <p>Route</p>
                          <p *ngIf="printLanguageflag">{{'Route'|translate}}</p>
                        </th>
                        <th [style]="freq_style">
                          {{'Frequency'|translate}} & {{'Notes'|translate}}
                        </th>
                        <!-- <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                       [hidden]="dur_flag"> <strong>{{'Intake'|translate}}</strong></th>
                     <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                       [hidden]="notes_flag">
                       <strong>{{'Note'|translate}}</strong>
                     </th> -->
                      </tr>
                      <tr *ngFor="let product of listProducts">
                        <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                          {{product.med_typetxt}} {{product.drug_name}} <div style="font-size:12px">
                            {{product.genericname}}</div>
                        </td>
                        <td
                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;"
                          colspan="4" *ngIf="product.param_four ==true">
                          {{product.every_six}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.morning1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.afternoon1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.evening1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.night1}}
                        </td>
                        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.days}}
                          {{product.period|translate}}</td>
                        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.intake_mode}}</td>
                        <td
                          style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                          {{product.frequency
                                      |
                                      translate}} {{product.dure_txt_table}}
                          {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                        <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                        </td>
                      <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                        </td> -->
                      </tr>
                    </table>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Instructions'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="instructions"></div>
                        </span>
                      </p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p><strong>{{'Next visit'}}: </strong>{{nextvisit_print}}</p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;"
                      *ngIf="treatflag">
                      <p><strong>{{'Treatment'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="treatment"></div>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0 || labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                    <p><strong>DX</strong></p>
                    <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;"
                      *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0">
                      <div *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 "
                        style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <ng-container *ngFor="let profile of selected_profiles; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ profile.profile_name}}
                          </ng-container>

                          <!-- <ng-container *ngFor="let test of labarray; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <!-- <ng-container *ngFor="let profile of selected_profiles let last = last">
                           <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                         </ng-container> -->
                        </div>

                      </div>
                      <div *ngIf="radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of radioarray; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <ng-container *ngFor="let test of radioarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="selectedpackages.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of selectedpackages; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of selectedpackages let last = last">
                          <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                        </div>
                      </div>
                    </div>

                  </div>
                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 || selectedpackagesnew.length != 0">
                    <p *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                      <strong>Test for next visit:</strong></p>

                    <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                      <div *ngIf="labarraynew.length !=0 || selected_profilesnew.length != 0"
                        style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <ng-container *ngFor="let profile of selected_profilesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ profile.profile_name}}
                          </ng-container>
                        </div>

                      </div>
                      <div *ngIf="radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <ng-container *ngFor="let test of radioarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="selectedpackagesnew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of selectedpackagesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of selectedpackagesnew; let last = last">
                          <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                        </div>
                      </div>
                    </div>


                  </div>

                  <div>
                    <p
                      style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 3px;">
                      <strong>{{"Dr."+doctorname}}
                        {{qualification}}</strong>
                    </p>
                    <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                      ({{licence_code}})</p>

                    <br />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <div class=”empty-footer”>&nbsp;</div>
          </tfoot>
        </table>
        <div class=”header”>&nbsp;</div>
        <div class=”footer”>&nbsp;</div>
      </div>
    </div>

    <div #printnoheader id="printnoheader">
      <div *ngIf="print_same_page">
        <table>
          <thead>
            <tr>
              <td>
               
                <div class=“empty-header“ [style]="preslogo_style"> </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class=“content“>

                  <div>
                    <div
                      style="margin-left: 25px !important;margin-right:25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                   
                      <div>
                        <div style="display: flex;vertical-align: top;">
                          <table style="width: 450px;vertical-align: top;">

                            <tr style="vertical-align: top;">
                              <td><strong>{{'Name'}}</strong></td>
                              <td>: {{doct_clientname}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Age'}}/{{'Gender'}}</strong></td>
                              <td>: {{client_age}}/{{client_gender}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Mobile'}}</strong></td>
                              <td>: {{mobile}}</td>
                            </tr>


                          </table>
                          <table style="width: 350px;vertical-align: top;">
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Date & Time'}}</strong></td>
                              <td>: {{Appoint_Date}} {{printtime}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td>
                                <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                                  [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                  [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                  [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                  [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                  [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                  [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>

                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Complaints'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="complaints"></div>
                        </span>
                      </p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Diagnosis'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="dis_txt"></div>
                        </span>
                      </p>
                    </div>
                    <div
                      style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                      class="rowformat">
                      <p *ngIf="ref_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                        <b>{{'Referred by'}}: </b>{{refer_txt}}</p>
                        <p *ngIf="height_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                          <b>{{'Height'}}:</b>{{height}}
                        </p>

                        <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;">
                          <b>{{'Weight'}}:</b>{{weight}}
                        </p>
                       
                        <p *ngIf="bp_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                        
                          <b>{{'BP'}}:</b>{{clnt_bp}}
                        </p>
                        <p *ngIf="pulseflag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                       
                          <b>{{'Pulse'}}:</b>{{clnt_pulse}}
                        </p>
                        <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;">
                          <b>{{'Temperature'}}: </b>{{temparature}}
                        </p>
                        <p *ngIf="rrflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'RR'}}:</b>{{clnt_rr}}
                        </p>
                        <p *ngIf="spoflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'Spo2'}}:</b>{{clnt_spo2}}
                        </p>
                        <p *ngIf="cbgflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'CBG'}}:</b>{{clnt_cbg}}
                        </p>                       
                      
                    
                        <p *ngIf="cvsflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'CVS'}}:</b>{{clnt_cvs}}
                        </p>
                        <p *ngIf="rsflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'RS'}}:</b>{{clnt_rs}}
                        </p>
                      
                        <p *ngIf="abdflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'Abdomen'}}:</b>{{clnt_abd}}
                        </p>
                        <p *ngIf="cnsflag" class="refformat" style="margin: 2px !important;">
                          <b>{{'CNS'}}:</b>{{clnt_cns}}
                        </p>
                      <p *ngIf="heartrateflag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                        <b>{{'Heart rate'}}:</b>{{clnt_heartrate}}</p>
                      <p *ngIf="kidney_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                        <b>{{'Kidney'}}:</b>{{clnt_kidney}}</p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;"
                      *ngIf="listProducts.length != 0">
                      <p><strong>Rx</strong></p>
                    </div>
                    <table *ngIf="listProducts.length != 0"
                      style="margin-left: 25px !important;margin-right:25px !important; border: 1px solid black;border-collapse: collapse;">

                      <tr style="text-align: center;">
                        <th [style]="med_style">
                          <p>Medicine</p>
                          <p *ngIf="printLanguageflag"> {{'Medicine'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Mor</p>
                          <p *ngIf="printLanguageflag"> {{'Mor'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Aft</p>
                          <p *ngIf="printLanguageflag">{{'Aft'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Eve</p>
                          <p *ngIf="printLanguageflag">{{'Eve'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Ngt</p>
                          <p *ngIf="printLanguageflag"> {{'Ngt'|translate}}</p>

                        </th>
                        <th [style]="dur_style">
                          <p>Duration</p>
                          <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>
                        </th>
                        <th [style]="dur_style">
                          <p>Route</p>
                          <p *ngIf="printLanguageflag">{{'Route'|translate}}</p>
                        </th>
                        <th [style]="freq_style">
                          {{'Frequency'|translate}} & {{'Notes'|translate}}
                        </th>
                        <!-- <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                       [hidden]="dur_flag"> <strong>{{'Intake'|translate}}</strong></th>
                     <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                       [hidden]="notes_flag">
                       <strong>{{'Note'|translate}}</strong>
                     </th> -->
                      </tr>
                      <tr *ngFor="let product of listProducts">
                        <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                          {{product.med_typetxt}} {{product.drug_name}} <div style="font-size:12px">
                            {{product.genericname}}</div>
                        </td>
                        <td
                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;"
                          colspan="4" *ngIf="product.param_four ==true">
                          {{product.every_six}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.morning1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.afternoon1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.evening1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.night1}}
                        </td>
                        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.days}}
                          {{product.period|translate}}</td>
                        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.intake_mode}}</td>
                        <td
                          style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                          {{product.frequency
                                      |
                                      translate}} {{product.dure_txt_table}}
                          {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                        <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                        </td>
                      <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                        </td> -->
                      </tr>
                    </table>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Instructions'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="instructions"></div>
                        </span>
                      </p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p><strong>{{'Next visit'}}: </strong>{{nextvisit_print}}</p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;"
                      *ngIf="treatflag">
                      <p><strong>{{'Treatment'}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="treatment"></div>
                        </span>
                      </p>
                    </div>

                  </div>

                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0 || labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                    <p><strong>DX</strong></p>
                    <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;"
                      *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0">
                      <div *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 "
                        style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <ng-container *ngFor="let profile of selected_profiles; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ profile.profile_name}}
                          </ng-container>

                          <!-- <ng-container *ngFor="let test of labarray; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <!-- <ng-container *ngFor="let profile of selected_profiles let last = last">
                           <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                         </ng-container> -->
                        </div>

                      </div>
                      <div *ngIf="radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of radioarray; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <ng-container *ngFor="let test of radioarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="selectedpackages.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of selectedpackages; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of selectedpackages let last = last">
                          <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                        </div>
                      </div>
                    </div>

                  </div>
                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 || selectedpackagesnew.length != 0">
                    <p *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                      <strong>Test for next visit:</strong></p>

                    <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                      <div *ngIf="labarraynew.length !=0 || selected_profilesnew.length != 0"
                        style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <ng-container *ngFor="let profile of selected_profilesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ profile.profile_name}}
                          </ng-container>
                        </div>

                      </div>
                      <div *ngIf="radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                          <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                          <ng-container *ngFor="let test of radioarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="selectedpackagesnew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of selectedpackagesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of selectedpackagesnew; let last = last">
                          <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                        </ng-container> -->
                        </div>
                      </div>
                    </div>


                  </div>

                  <div>
                    <p
                      style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 3px;">
                      <strong>{{doct_name}}
                        {{doctor_qualify_data}}</strong>
                    </p>
                    <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                      ({{licence_code}})</p>

                    <br />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <div class=”empty-footer”>&nbsp;</div>
          </tfoot>
        </table>
        <div class=”header”>&nbsp;</div>
        <div class=”footer”>&nbsp;</div>
      </div>
    </div>
    <div #printbannerfullpage id="printbannerfullpage"
      style="background-image: url({{bg_image}}); background-position: center;">

      <div *ngIf="!print_same_page">
        <div *ngIf="listProducts.length != 0 && print_view_flag">
          <table style="margin-left:50px;margin-top:70px;">
            <tr>
              <td style="width: 450px; vertical-align: top;">
                <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                <p style="margin-left: 110px;">{{doct_city}}</p>
                <p style="margin-left: 110px;">{{doct_state}}</p>
              </td>
              <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <br /><br />
          <table style="margin-left: 50px; margin-top:20px;">
            <tr>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                <p><b>{{'Age'|translate}}/{{'Gender'|translate}} :</b> {{client_age}}/{{client_gender}}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                <p *ngIf="disease_flag"><b>Diagnosis : </b>{{disease_name}}</p>

              </td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <div class="d-flex align-items-center justify-content-center">
                  <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                    [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                    [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                    [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                    [bc-margin-right]="marginRight"></ngx-barcode>
                </div>
                <!-- <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                      <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                         &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                         </b>{{clnt_pulse}}</p>
                      <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                            *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                            {{weight}}</span></p>
                      <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                      <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                         &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                         {{clnt_rs}}</p>
                      <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p> -->
              </td>
            </tr>
          </table>
          <div style="display: flex;margin-left: 50px;" class="rowformat">
            <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;"><b>{{'Referred by'|translate}}:
              </b>{{refer_txt}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'BP'|translate}}:
              </b>{{clnt_bp}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'Pulse'|translate}}:
              </b>{{clnt_pulse}}</p>
            <p *ngIf="height_flag" class="refformat" style="margin: 2px !important;"><b>{{'Height'|translate}}:
              </b>{{height}} </p>

            <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;"><b>{{'Weight'|translate}}:
              </b>
              {{weight}}</p>
            <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;"><b>
                {{'Temperature'|translate}}: </b>{{temparature}} </p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'CVS'|translate}}:
              </b>{{clnt_cvs}}</p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'RS'|translate}}: </b>
              {{clnt_rs}}</p>

            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b> {{'Kidney'|translate}}:
              </b>{{clnt_kidney}}</p>
          </div>
          <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
            <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
          </div>
          <br />
          <table *ngIf="listProducts.length != 0"
            style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
            <tr>
              <th [style]="med_style">
                <b>{{'Medicine'|translate}}</b>
              </th>
              <th [style]="dosage_style">
                <b>{{'Dosage'|translate}}</b>
              </th>
              <th [style]="dur_style">
                <b>{{'Duration'|translate}}</b>
              </th>
              <th [style]="freq_style">
                <b>{{'Frequency'|translate}}</b>
              </th>
              <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                [hidden]="dur_flag"> <b>{{'Intake'|translate}}</b></th>
              <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                [hidden]="notes_flag">
                <b>{{'Note'|translate}}</b>
              </th>
            </tr>
            <tr *ngFor="let product of listProducts">
              <td style="border: 1px solid black;border-collapse: collapse;width: 220px;">
                {{product.med_typetxt}} {{product.drug_name}} <span
                  style="font-size:12px">({{product.genericname}})</span>
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.every_six}}
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                {{product.period|translate}}</td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.frequency
                      |
                      translate}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                [hidden]="dur_flag">{{product.dure_txt_table}}
                {{product.drug_intake|translate}} {{product.mixval}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;"
                [hidden]="notes_flag">{{product.remarks}}</td>
            </tr>
          </table>

          <div style="margin-left: 50px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_instruction"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="complaints!=undefined"><b>{{'Complaints' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_complaints"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="dis_txt!=undefined"><b>{{'Diagnosis' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_disease"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;" *ngIf="treatflag">
            <p><b>{{'Treatment' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="treatment"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;">
            <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
          </div>
          <br />
          <div>
            <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
            </p>
            <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
            <!-- <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                </p>
                <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                </p> -->
            <br />
          </div>
        </div>
        <div *ngIf="print_diag">
          <div class="page-break" *ngIf="listProducts.length != 0"></div>
          <table style="margin-left:50px;margin-top:70px;">
            <tr>
              <td style="width: 450px; vertical-align: top;">
                <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                <p style="margin-left: 110px;">{{doct_city}}</p>
                <p style="margin-left: 110px;">{{doct_state}}</p>
              </td>
              <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <br /><br />
          <table style="margin-left: 50px; margin-top:20px;">
            <tr>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                <p><b>{{'Age'|translate}}/{{'Gender'|translate}} :</b> {{client_age}}/{{client_gender}}</p>
                <!-- <p><b>:</b>  </p> -->
              </td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <div class="d-flex align-items-center justify-content-center">
                  <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                    [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                    [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                    [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                    [bc-margin-right]="marginRight"></ngx-barcode>
                </div>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <div style="margin-left: 50px;" *ngIf="print_diag">
            <p><b>Diagnostic Test</b></p>
            <div *ngIf="pritn_blood_flag">
              <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{blood_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_scan_flag">
              <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{scan_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_us_flag">
              <p id="print_blood_head"><b>{{us_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{us_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_xray_flag">
              <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{xray_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_bio_flag">
              <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{bio_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_ut_flag">
              <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{ut_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_ft_flag">
              <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{ft_test_content}}
              </p>
            </div>
            <div *ngIf="pritn_culture_flag">
              <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
              <p style="margin-left: 20px;margin-top:0;">
                {{culture_test_content}}
              </p>
            </div>
            <!-- <div style="margin-left: 50px;">
                   <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                </div> -->
            <br />
            <div style="margin-left: 50px;">
              <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                  {{qualification}}</strong>
              </p>
              <p style="font-size: 13px">({{licence_code}})</p>
              <!-- <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                   </p>
                   <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                   </p> -->
              <br />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="print_same_page">
        <table>
          <thead>
            <tr>
              <td>
                
                <div class=“empty-header“ [style]="preslogo_style"> </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class=“content“>

                  <div>
                    <div
                      style="margin-left: 25px !important;margin-right:25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                  
                      <div>
                        <div style="display: flex;vertical-align: top;">
                          <table style="width: 450px;vertical-align: top;">
                            <!-- <tr>
                           <td><strong>{{'MR No'}}</strong></td>
                           <td>: {{mrNumber}}</td>
                         </tr> -->
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Name'|translate}}</strong></td>
                              <td>: {{doct_clientname}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Age'|translate}}/{{'Gender'|translate}}</strong></td>
                              <td>: {{client_age}}/{{client_gender}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Mobile'}}</strong></td>
                              <td>: {{mobile}}</td>
                            </tr>


                          </table>
                          <table style="width: 350px;vertical-align: top;">
                            <tr style="vertical-align: top;">
                              <td><strong>{{'Date'|translate}}</strong></td>
                              <td>: {{Appoint_Date}} {{printtime}}</td>
                            </tr>
                            <tr style="vertical-align: top;">
                              <td>
                                <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                                  [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                  [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                  [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                  [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                  [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                  [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>

                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Complaints' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="complaints"></div>
                        </span>
                      </p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Diagnosis' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="dis_txt"></div>
                        </span>
                      </p>
                    </div>
                    <div
                      style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                      class="rowformat">
                      <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                      <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'BP'|translate}}:</b>{{clnt_bp}}</p>
                      <p *ngIf="pulseflag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Pulse'|translate}}:</b>{{clnt_pulse}}</p>
                      <p *ngIf="height_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Height'|translate}}:</b>{{height}} </p>

                      <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Weight'|translate}}:</b>{{weight}}</p>
                      <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Temperature'|translate}}: </b>{{temparature}} </p>
                      <p *ngIf="cvsflag" class="refformat" style="margin: 2px !important;">
                        <b>{{'CVS'|translate}}:</b>{{clnt_cvs}}</p>
                      <p *ngIf="rsflag" class="refformat" style="margin: 2px !important;">
                        <b>{{'RS'|translate}}:</b>{{clnt_rs}}</p>

                      <p *ngIf="kidney_flag" class="refformat" style="margin: 2px !important;">
                        <b>{{'Kidney'|translate}}:</b>{{clnt_kidney}}</p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;"
                      *ngIf="listProducts.length != 0">
                      <p><strong>Rx</strong></p>
                    </div>
                    <table *ngIf="listProducts.length != 0"
                      style="margin-left: 25px !important;margin-right:25px !important;; border: 1px solid black;border-collapse: collapse;">

                      <tr style="text-align: center;">
                        <th [style]="med_style">
                          {{'Medicine'|translate}}
                        </th>
                        <th [style]="dosage_style">
                          <p>Mor</p>
                          <p *ngIf="printLanguageflag"> {{'Mor'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Aft</p>
                          <p *ngIf="printLanguageflag">{{'Aft'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Eve</p>
                          <p *ngIf="printLanguageflag">{{'Eve'|translate}}</p>
                        </th>
                        <th [style]="dosage_style">
                          <p>Ngt</p>
                          <p *ngIf="printLanguageflag"> {{'Ngt'|translate}}</p>

                        </th>
                        <th [style]="dur_style">
                          <p>Duration</p>
                          <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>

                        </th>
                        <th [style]="freq_style">
                          {{'Frequency'|translate}} & {{'Notes'|translate}}
                        </th>
                        <!-- <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                     [hidden]="dur_flag"> <strong>{{'Intake'|translate}}</strong></th>
                   <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                     [hidden]="notes_flag">
                     <strong>{{'Note'|translate}}</strong>
                   </th> -->
                      </tr>
                      <tr *ngFor="let product of listProducts">
                        <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                          {{product.med_typetxt}} {{product.drug_name}} <div style="font-size:12px">
                            {{product.genericname}}</div>
                        </td>
                        <td style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;"
                          colspan="4" *ngIf="product.param_four ==true">
                          {{product.every_six}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.morning1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.afternoon1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.evening1}}
                        </td>
                        <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                          *ngIf="product.param_four ==false">
                          {{product.night1}}
                        </td>
                        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.days}}
                          {{product.period|translate}}</td>
                        <td
                          style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                          {{product.frequency
                                    |
                                    translate}} {{product.dure_txt_table}}
                          {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                        <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                      </td>
                    <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                      </td> -->
                      </tr>
                    </table>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p style="margin:0px"><strong>{{'Instructions' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="instructions"></div>
                        </span>
                      </p>
                    </div>

                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                      <p><strong>{{'Next visit' |translate}}: </strong>{{nextvisit_print}}</p>
                    </div>
                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;"
                      *ngIf="treatflag">
                      <p><strong>{{'Treatment' |translate}}: </strong>
                        <span>
                          <div class="matlabel" [innerHtml]="treatment"></div>
                        </span>
                      </p>
                    </div>

                  </div>

                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0 || labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                    <p><strong>DX</strong></p>
                    <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;"
                      *ngIf="labarray.length!=0 || selected_profiles.length!=0 || radioarray.length!=0 || selectedpackages.length!=0">
                      <div *ngIf="labarray.length !=0 || selected_profilesnew.length!=0 "
                        style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <ng-container *ngFor="let test of selected_profiles; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.profile_name}}
                          </ng-container>

                          <!-- <ng-container *ngFor="let profile of selected_profiles let last = last">
                         <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                       </ng-container> -->
                        </div>

                      </div>
                      <div *ngIf="radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of radioarray; let last = last">
                        <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                      </ng-container> -->
                          <ng-container *ngFor="let test of radioarray; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="selectedpackages.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <!-- <ng-container *ngFor="let test of selectedpackages let last = last">
                        <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                      </ng-container> -->
                          <ng-container *ngFor="let test of selectedpackages; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                    *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 || selectedpackagesnew.elngth != 0">
                    <p *ngIf="labarraynew.length!=0 || selected_profilesnew.length!=0 || radioarraynew.length!=0 ">
                      <strong>Test for next visit:</strong></p>

                    <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                      <div *ngIf="labarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Lab Tests:</strong></div>
                        <div class="test-names-container">
                          <!-- <p></p> -->
                          <ng-container *ngFor="let test of labarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of labarraynew; let last = last">
                        <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                      </ng-container> -->
                          <ng-container *ngFor="let test of selected_profilesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.profile_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let profile of selected_profilesnew let last = last">
                         <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                       </ng-container> -->
                        </div>

                      </div>
                      <div *ngIf="radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Radiology Tests:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of radioarraynew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                        <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                      </ng-container> -->
                        </div>
                      </div>
                      <div *ngIf="selectedpackagesnew.length !=0" style="width: 89%;margin-bottom: 10px;">
                        <div class="heading"><strong>Packages:</strong></div>
                        <div class="test-names-container">
                          <ng-container *ngFor="let test of selectedpackagesnew; let first = first">
                            <ng-container *ngIf="!first">, </ng-container>{{ test.package_name}}
                          </ng-container>
                          <!-- <ng-container *ngFor="let test of selectedpackagesnew; let last = last">
                        <span class="test-name">{{ test.package_name }}</span>{{ !last ? ',' : '' }}
                      </ng-container> -->
                        </div>
                      </div>
                    </div>


                  </div>

                  <div>
                    <p
                      style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 3px;">
                      <strong>{{"Dr."+doct_name}}
                        {{qualification}}</strong>
                    </p>
                    <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                      ({{licence_code}})</p>

                    <br />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <div class=”empty-footer”>&nbsp;</div>
          </tfoot>
        </table>
        <div class=”header”>&nbsp;</div>
        <div class=”footer”>&nbsp;</div>
      </div>
      <div *ngIf="!print_view_flag">
        <div *ngIf="listProducts.length != 0">
          <table style="margin-left:50px;margin-top:70px;">
            <tr>
              <td style="width: 450px; vertical-align: top;">
                <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                <p style="margin-left: 110px;">{{doct_city}}</p>
                <p style="margin-left: 110px;">{{doct_state}}</p>
              </td>
              <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
              </td>
            </tr>
          </table>
          <br /><br />
          <table style="margin-left: 50px; margin-top:20px;">
            <tr>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                <p><b>{{'Age'|translate}}/{{'Gender'|translate}} :</b> {{client_age}}/{{client_gender}}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                <p *ngIf="disease_flag"><b>Diagnosis : </b>{{disease_name}}</p>

              </td>
              <td style="width: 300px;vertical-align: top;">
                <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                <div class="d-flex align-items-center justify-content-center">
                  <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                    [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                    [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                    [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                    [bc-margin-right]="marginRight"></ngx-barcode>
                </div>
                <!-- <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                      <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                         &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                         </b>{{clnt_pulse}}</p>
                      <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                            *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                            {{weight}}</span></p>
                      <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                      <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                         &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                         {{clnt_rs}}</p>
                      <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p> -->
              </td>
            </tr>
          </table>
          <div style="display: flex;margin-left: 50px;" class="rowformat">
            <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;"><b>{{'Referred by'|translate}}:
              </b>{{refer_txt}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'BP'|translate}}:
              </b>{{clnt_bp}}</p>
            <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;"><b>{{'Pulse'|translate}}:
              </b>{{clnt_pulse}}</p>
            <p *ngIf="height_flag" class="refformat" style="margin: 2px !important;"><b>{{'Height'|translate}}:
              </b>{{height}} </p>

            <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;"><b>{{'Weight'|translate}}:
              </b>
              {{weight}}</p>
            <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;"><b>
                {{'Temperature'|translate}}: </b>{{temparature}} </p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'CVS'|translate}}:
              </b>{{clnt_cvs}}</p>
            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b>{{'RS'|translate}}: </b>
              {{clnt_rs}}</p>

            <p *ngIf="ortho_flag" class="refformat" style="margin: 2px !important;"><b> {{'Kidney'|translate}}:
              </b>{{clnt_kidney}}</p>
          </div>
          <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
            <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
          </div>
          <br />
          <table *ngIf="listProducts.length != 0"
            style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
            <tr>
              <th [style]="med_style">
                <b>{{'Medicine'|translate}}</b>
              </th>
              <th [style]="dosage_style">
                <b>{{'Dosage'|translate}}</b>
              </th>
              <th [style]="dur_style">
                <b>{{'Duration'|translate}}</b>
              </th>
              <th [style]="freq_style">
                <b>{{'Frequency'|translate}}</b>
              </th>
              <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                [hidden]="dur_flag"> <b>{{'Intake'|translate}}</b></th>
              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;min-width: 250px !important;"
                [hidden]="notes_flag">
                <b>{{'Note'|translate}}</b>
              </th>
            </tr>
            <tr *ngFor="let product of listProducts">
              <td style="border: 1px solid black;border-collapse: collapse;width: 220px;">
                {{product.med_typetxt}} {{product.drug_name}} <span
                  style="font-size:12px">({{product.genericname}})</span>
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.every_six}}
              </td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                {{product.period|translate}}</td>
              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                {{product.frequency
                      |
                      translate}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                {{product.drug_intake|translate}}</td>
              <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
            </tr>
          </table>

          <div style="margin-left: 50px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_instruction"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="complaints!=undefined"><b>{{'Complaints' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_complaints"></div>
              </span>
            </p>
          </div>
          <div style="margin-left: 50px;">
            <p *ngIf="dis_txt!=undefined"><b>{{'Diagnosis' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="print_disease"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;" *ngIf="treatflag">
            <p><b>{{'Treatment' |translate}}: </b>
              <span>
                <div class="matlabel" [innerHtml]="treatment"></div>
              </span>
            </p>
          </div>

          <div style="margin-left: 50px;">
            <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
          </div>
          <br />
          <div>
            <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
            </p>
            <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>

            <br />
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="highRiskPresent">
    <div #printconcernform id="printconcernform">
      <div  style="background-image: url({{bgImage}}); background-position: center;">

        <table style="border:'0';width:100%">
          <thead>
            <tr>
              <th style="width:100%;">
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <div style="width: 90%; height: 278px;">
                    <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                    <div style="height: 160px;"></div>

                    <table
                      style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px; margin-bottom: 5px; height: 95px;">
                      <tbody>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                          <td>: {{doct_clientname}}</td>
                          <td style="padding-left: 5px;"><strong>Age / Gender</strong></td>
                          <td>: {{client_age}}/{{client_gender}}</td>
                        </tr>

                        <tr>
                          <td style="padding-left: 5px;"><strong>Address</strong></td>
                          <td>: {{addressdetails}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Mobile</strong></td>
                          <td> : {{mobile}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Doctor name</strong></td>
                          <td>: {{doct_name}}</td>

                        </tr>
                        <!-- <tr *ngIf="diag_report_type != 'radiology'">
                            
                         </tr> -->
                        <tr>
                          <td style="padding-left: 5px;"><strong>Date & Time</strong></td>
                          <td> : {{Appoint_Date}} {{printtime}}</td>
                          <td *ngIf="barcode_flag"><img
                              style="width: 130px; height: 35px; margin-left: -12px;         margin-top: -24px;"
                              src={{barcode_url}} /></td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <div style="margin: 0px !important;" *ngIf="diag_report_type == 'pathology'">
                         <div style="display: flex;">
                            <div style="margin-bottom: 0px !important; width: 270px;">Test name</div>
                            <div style="margin-bottom: 0px !important; width: 153px;">Reading</div>
                            <div style="margin-bottom: 0px !important; width: 118px;">Unit</div>
                            <div>Reference value</div>
                          
                         </div>
                      </div> -->
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td width="100%">
                <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                  <table class=paging style="width:90%;">
                    <!-- <thead>
                         <tr>
                            <td style="width: 100%;">
                               <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                            </td>
                         </tr>
                      </thead> -->
                    <tbody>
                      <tr>
                        <td>
                          <div class="main"
                            style="display: flex; flex-direction: column; margin-top: 10px; ">
                            <div><h2>Consent form</h2></div>
                            <p style="font-size: 12px;">I do hereby give my concent for any
                              investigation,operation,anesthesia and treatment consider necessary in the patient
                            </p>

                            <div style="display: flex;vertical-align: top;">
                              <table style="vertical-align: top;">
                                <tr style="vertical-align: top;">
                                  <td><span style="font-weight: 550;">Consultant</span></td>
                                  <td>: {{consultantanme}}</td>
                                </tr>
                                <tr style="vertical-align: top;" *ngIf="Inpatientflag">
                                  <td><span style="font-weight: 550;">Room No</span></td>
                                  <td>: {{ward_bed}}</td>
                                </tr>



                                <tr style="vertical-align: top;">
                                  <td><span style="font-weight: 550;">Allergic to</span></td>
                                  <td>: {{allergy}}</td>
                                </tr>
                                <tr style="vertical-align: top;">
                                  <td><span style="font-weight: 550;">Drug allergy</span ></td>
                                  <td>: {{drug_details
                                    }}</td>
                                </tr>
                                <tr style="vertical-align: top;">
                                  <td><span style="font-weight: 550;">Env allergy</span ></td>
                                  <td>: {{env_details
                                    }}</td>
                                </tr>
                                <tr style="vertical-align: top;">
                                  <td><span style="font-weight: 550;">Food allergy</span ></td>
                                  <td>: {{food_details
                                    }}</td>
                                </tr>
                                <tr style="vertical-align: top;">
                                  <td><span style="font-weight: 550;">Blood Group</span></td>
                                  <td>: {{blood_group}}</td>
                                </tr>

                              </table>
                            </div>
                            <table class="table" style="margin-bottom: 30px;">
                              <tr>
                                <td class="align_left">
                                  <br>
                                  <div>
                                    <p style="font-size: 11px;">1.{{ hospitalname }} சட்ட திட்டங்களுக்கும்,
                                      விதிகளுக்கும் ஒப்புக்கொண்டு டாக்டர் ஆலோசனைப்படி நடக்கவும் சம்மதிக்கிறேன்.</p>
                                    <p style="font-size: 11px;">2.மேற்படி ஆஸ்பத்திரியில் பரிசோதனை செய்வதற்கும்.
                                      சிகிச்சை செய்வதற்கும், மயக்க மருந்து கொடுப்பதற்கும், ஆபரேஷன்' செய்வதற்கும்
                                      நோயாளியின் நலனுக்காக என்பதை அறிந்து நான் முழுமனதுடன் ஒப்புதல் அளிக்கிறேன்.</p>
                                    <p style="font-size: 11px;">3.எங்களுடைய உடைமைகள் மற்றும் பொருட்களை நாங்கள் எங்கள்
                                      பொறுப்பில் பார்த்துக் கொள்ள சம்மதிக்கிறேன்.</p>
                                    <p style="font-size: 11px;">4.எங்களுடைய உடைமைகள் மற்றும் பொருட்களுக்கு
                                      நிர்வாகஸ்தாரோ, உரிமையாளரோ பொறுப்பல்ல என்பதை இதன் மூலம் அறிகிறேன்.</p>
                                    <p style="font-size: 11px;">5.எங்களுக்கு ஏற்படும் நஷ்டத்திற்கு நாங்களே பொறுப்பு
                                      என்பதை அறிந்து இங்கு அட்மிஷன் செய்ய சம்மதிக்கிறேன்.</p>
                                    <p style="font-size: 11px;">6.போலீஸ் /கோர்ட் சம்பந்தப்பட்ட (M.L.C.) கேஸ் அல்ல
                                      என்று உறுதியளித்து அட்மிஷன் செய்கிறேன்.சகல நஷ்டத்திற்கு நானே பொறுப்பு என்பதை
                                      இதன் மூலம் உறுதியளிக்கிறேன்.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <div class="empty-footer" style="text-align: center; margin-top: 10px;">
                              <!-- <h4>------ End of Report ------</h4> -->
                            </div>

                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 90px;">
                                <div class="container-fluid">
                                  <div class="row d-flex flex-row justify-content-center">
                                    <table style="width: 100%;">
                                      <tr>
                                        <td style="width: 90%;text-align: center;">
                                          <div class="row">
                                            <div class="d-flex flex-row justify-content-center align-items-end">
                                              <div  class="col-4">
                                                <div style="text-align: center;">
                                                  <!-- <img *ngIf="lab_tech_sign" style="width: 55px;"
                                                    src={{lab_tech_sign}} />
                                                  <p style="margin-bottom: 3px !important;">
                                                    {{createdBy}}</p> -->
                                                  <p><strong>Medical Officer Signature and date</strong>
                                                  </p>
                                                </div>
                                                <!-- <mat-label class="matlabel"><strong>Lab
                                                                technician
                                                                :</strong> {{createdBy}}
                                                                  </mat-label> -->
                                              </div>
                                              <div class="col-4">
                                                <!-- <div style="text-align: center;"> -->
                                                  <!-- <img *ngIf="approver_sign" style="width: 55px;"
                                                    src={{approver_sign}} />
                                                  <p *ngIf="approver_name == undefined"
                                                    style="margin-bottom: 3px !important;">
                                                    &nbsp;</p> -->
                                                  <!-- <p 
                                                    style="margin-bottom: 3px !important;">
                                                    {{approver_name}}</p> -->
                                                  <!-- <p><strong>Medical Officer Signature and date</strong>
                                                  </p>
                                                </div> -->
                                                <!-- <mat-label class="matlabel"><strong>Approver
                                                                name:
                                                                </strong> {{approver_name}}
                                                                </mat-label> -->
                                              </div>
                                              <div class="col-4" >
                                                <div style="text-align: center;">
                                                  <!-- <img *ngIf="quality_head_sign" style="width: 55px;"
                                                    src={{quality_head_sign}} /> -->
                                                  <!-- <p style="margin-bottom: 3px !important;">
                                                    {{qualityHead_name}}</p> -->
                                                  <p><strong>Parent or Guardian Signature and date</strong></p>
                                                </div>
                                                <!-- <mat-label class="matlabel"><strong>Quality Head
                                                                :</strong> {{qualityHead_name}}
                                                                </mat-label> -->
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                    </tfoot>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>

        </table>

        <footer style="width:100%;">
          <table style="border:'0';width:100%; margin-bottom: 0;">
            <tr>
              <td style="width:100%;height: 100px;">&nbsp;
                <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
              </td>
            </tr>
          </table>
        </footer>
      </div>
    </div>
    
    <div #printconcernformbanner id="printbanner"
    style="background-image: url({{bgImage}}); background-position: center;">
    <table style="border:'0';width:100%;">
       <thead>
          <tr>
             <th style="width:100%;">
                <div class="main"
                   style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                   <div style="width: 90%; height: 278px;">
                      <img alt="image" src={{hospitalLogo}} width="100%" height="160px">

                      <table
                      style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px; margin-bottom: 5px; height: 95px;">
                      <tbody>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                          <td>: {{doct_clientname}}</td>
                          <td style="padding-left: 5px;"><strong>Age / Gender</strong></td>
                          <td>: {{client_age}}/{{client_gender}}</td>
                        </tr>

                        <tr>
                          <td style="padding-left: 5px;"><strong>Address</strong></td>
                          <td>: {{addressdetails}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Mobile</strong></td>
                          <td> : {{mobile}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Doctor name</strong></td>
                          <td>: {{doct_name}}</td>

                        </tr>
                        <!-- <tr *ngIf="diag_report_type != 'radiology'">
                            
                         </tr> -->
                        <tr>
                          <td style="padding-left: 5px;"><strong>Date & Time</strong></td>
                          <td> : {{Appoint_Date}} {{printtime}}</td>
                          <td *ngIf="barcode_flag"><img
                              style="width: 130px; height: 35px; margin-left: -12px;         margin-top: -24px;"
                              src={{barcode_url}} /></td>
                        </tr>
                      </tbody>
                    </table>
                   </div>

                </div>
             </th>
          </tr>
       </thead>

       <tbody>
          <tr>
             <td width="100%">
                <div
                   style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                   <table class=paging style="width:90%;">
                      <!-- <thead>
                         <tr>
                            <td style="width: 100%;">
                               <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                            </td>
                         </tr>
                      </thead> -->
                      <tbody>
                         <tr>
                            <td>
                              <div class="main"
                              style="display: flex; flex-direction: column; margin-top: 10px; ">
  
                              <p style="font-size: 12px;">I do hereby give my concent for any
                                investigation,operation,anesthesia and treatment consider necessary in the patient
                              </p>
  
                              <div style="display: flex;vertical-align: top;">
                                <table style="vertical-align: top;">
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Consultant</span></td>
                                    <td>: {{consultantanme}}</td>
                                  </tr>
                                  <tr style="vertical-align: top;" *ngIf="Inpatientflag">
                                    <td><span style="font-weight: 550;">Room No</span></td>
                                    <td>: {{ward_bed}}</td>
                                  </tr>
  
  
  
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Allergic to</span></td>
                                    <td>: {{allergy}}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Drug allergy</span ></td>
                                    <td>: {{drug_details
                                      }}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Env allergy</span ></td>
                                    <td>: {{env_details
                                      }}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Food allergy</span ></td>
                                    <td>: {{food_details
                                      }}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Blood Group</span></td>
                                    <td>: {{blood_group}}</td>
                                  </tr>
  
                                </table>
                              </div>
                              <table class="table" style="margin-bottom: 30px;">
                                <tr>
                                  <td class="align_left">
                                    <br>
                                    <div>
                                      <p style="font-size: 11px;">1.{{ hospitalname }} சட்ட திட்டங்களுக்கும்,
                                        விதிகளுக்கும் ஒப்புக்கொண்டு டாக்டர் ஆலோசனைப்படி நடக்கவும் சம்மதிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">2.மேற்படி ஆஸ்பத்திரியில் பரிசோதனை செய்வதற்கும்.
                                        சிகிச்சை செய்வதற்கும், மயக்க மருந்து கொடுப்பதற்கும், ஆபரேஷன்' செய்வதற்கும்
                                        நோயாளியின் நலனுக்காக என்பதை அறிந்து நான் முழுமனதுடன் ஒப்புதல் அளிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">3.எங்களுடைய உடைமைகள் மற்றும் பொருட்களை நாங்கள் எங்கள்
                                        பொறுப்பில் பார்த்துக் கொள்ள சம்மதிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">4.எங்களுடைய உடைமைகள் மற்றும் பொருட்களுக்கு
                                        நிர்வாகஸ்தாரோ, உரிமையாளரோ பொறுப்பல்ல என்பதை இதன் மூலம் அறிகிறேன்.</p>
                                      <p style="font-size: 11px;">5.எங்களுக்கு ஏற்படும் நஷ்டத்திற்கு நாங்களே பொறுப்பு
                                        என்பதை அறிந்து இங்கு அட்மிஷன் செய்ய சம்மதிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">6.போலீஸ் /கோர்ட் சம்பந்தப்பட்ட (M.L.C.) கேஸ் அல்ல
                                        என்று உறுதியளித்து அட்மிஷன் செய்கிறேன்.சகல நஷ்டத்திற்கு நானே பொறுப்பு என்பதை
                                        இதன் மூலம் உறுதியளிக்கிறேன்.</p>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                              <div class="empty-footer" style="text-align: center; margin-top: 10px;">
                                <!-- <h4>------ End of Report ------</h4> -->
                              </div>
  
                            </div>
                            </td>
                         </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td width="100%">
                            <table style="border:'0';width:100%">
                              <tr>
                                <td style="width:100%;height: 90px;">
                                  <div class="container-fluid">
                                    <div class="row d-flex flex-row justify-content-center">
                                      <table style="width: 100%;">
                                        <tr>
                                          <td style="width: 90%;text-align: center;">
                                            <div class="row">
                                              <div class="d-flex flex-row justify-content-center align-items-end">
                                                <div  class="col-4">
                                                  <div style="text-align: center;">
                                                    <!-- <img *ngIf="lab_tech_sign" style="width: 55px;"
                                                      src={{lab_tech_sign}} />
                                                    <p style="margin-bottom: 3px !important;">
                                                      {{createdBy}}</p> -->
                                                    <p><strong>Medical Officer Signature and date</strong>
                                                    </p>
                                                  </div>
                                                  <!-- <mat-label class="matlabel"><strong>Lab
                                                                  technician
                                                                  :</strong> {{createdBy}}
                                                                    </mat-label> -->
                                                </div>
                                                <div class="col-4">
                                                  <!-- <div style="text-align: center;"> -->
                                                    <!-- <img *ngIf="approver_sign" style="width: 55px;"
                                                      src={{approver_sign}} />
                                                    <p *ngIf="approver_name == undefined"
                                                      style="margin-bottom: 3px !important;">
                                                      &nbsp;</p> -->
                                                    <!-- <p 
                                                      style="margin-bottom: 3px !important;">
                                                      {{approver_name}}</p> -->
                                                    <!-- <p><strong>Medical Officer Signature and date</strong>
                                                    </p>
                                                  </div> -->
                                                  <!-- <mat-label class="matlabel"><strong>Approver
                                                                  name:
                                                                  </strong> {{approver_name}}
                                                                  </mat-label> -->
                                                </div>
                                                <div class="col-4" >
                                                  <div style="text-align: center;">
                                                    <!-- <img *ngIf="quality_head_sign" style="width: 55px;"
                                                      src={{quality_head_sign}} /> -->
                                                    <!-- <p style="margin-bottom: 3px !important;">
                                                      {{qualityHead_name}}</p> -->
                                                    <p><strong>Parent or Guardian Signature and date</strong></p>
                                                  </div>
                                                  <!-- <mat-label class="matlabel"><strong>Quality Head
                                                                  :</strong> {{qualityHead_name}}
                                                                  </mat-label> -->
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                      </tfoot>
                   </table>
                </div>
             </td>
          </tr>
       </tbody>

    </table>

    <footer style="width:100%;">
       <table style="border:'0';width:100%; margin-bottom: 0;">
          <tr>
             <td style="width:100%;height: 100px;">&nbsp;
                <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
             </td>
          </tr>
       </table>
    </footer>
 </div>
    </div>
  </div>
</div>
